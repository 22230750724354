import ControlledInputText, {
  ControlledInputTextCommonProps,
} from '../ControlledInputText/ControlledInputText';

interface Props<TField> extends ControlledInputTextCommonProps<TField> {
  maxLength?: number;
  isFloat?: boolean;
}
export const numberInputMask = (value: string): string => {
  return value.replace(/[^\d\/]/g, '');
};

const InputNumber = <TField,>(props: Props<TField>): JSX.Element => {
  return (
    <ControlledInputText
      {...props}
      fieldType={props.isFloat ? 'number' : undefined}
      inputMask={props.isFloat ? undefined : numberInputMask}
    />
  );
};

export default InputNumber;
