import React from 'react';
import clsx from 'clsx';
import Loader from '../../Loader/Loader';
import UploadIcon from '../../Icons/UploadIcon/UploadIcon';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';

const LOADER_HEIGHT = 21;
const LOADER_WIDTH = 21;

interface Props {
  onClick: () => void;
  isLoading: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  documentType?: string;
  italicLabelTranslationId?: string;
}

const UploadFileButton: React.FC<Props> = ({
  onClick,
  isLoading,
  hasError,
  isDisabled = false,
  documentType,
  italicLabelTranslationId,
}) => {
  return (
    <button
      className={clsx(
        'py-2.5 px-4 flex relative w-full justify-center',
        'h-12 rounded-lg',
        hasError
          ? 'bg-fieldError border border-solid border-error text-error'
          : 'bg-lightGrey border border-solid border-input'
      )}
      onClick={onClick}
      disabled={isDisabled}
      type="button"
    >
      <UploadIcon fill={hasError ? 'fill-error' : 'fill-darkGrey'} />
      <p
        className={clsx(
          'ml-2 font-bold text-formInput',
          isLoading && 'opacity-0'
        )}
      >
        <TranslatedMarkupText id={documentType} />
        {italicLabelTranslationId && (
          <span className="italic">
            {' '}
            <TranslatedMarkupText id={italicLabelTranslationId} />
          </span>
        )}
      </p>

      {isLoading && (
        <span className="absolute">
          <Loader color="white" height={LOADER_HEIGHT} width={LOADER_WIDTH} />
        </span>
      )}
    </button>
  );
};

export default UploadFileButton;
