import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route } from 'react-router';
import { PATH } from './constants/path.const';
import Account from './pages/Account/Account';
import Favorites from './pages/Favorites/Favorites';
import LegalOwnerForm from './pages/LegalOwnerForm/LegalOwnerForm';
import Login from './pages/Login/Login';
import Wallet from './pages/Wallet/Wallet';
import ProfileForm from './pages/ProfileForm/ProfileForm';
import ProjectForm from './pages/ProjectForm/ProjectForm';
import Projects from './pages/Projects';
import ProjectDetails from './pages/Projects/ProjectDetails';
import Register from './pages/Register/Register';
import InvestmentPortfolio from './pages/InvestmentPortfolio/InvestmentPortfolio';
import { getInternationalizedPath } from './services/helpers/getInternationalizedPath';
import KYCDocuments from './pages/KYCDocuments/KYCDocuments';

const Routes: React.FC = () => {
  const intl = useIntl();

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path={getInternationalizedPath(PATH.KYC_DOCUMENTS, intl)}>
          <KYCDocuments />
        </Route>
        <Route exact path={getInternationalizedPath(PATH.WALLET, intl)}>
          <Wallet />
        </Route>
        <Route
          exact
          path={getInternationalizedPath(PATH.LEGAL_OWNER_FORM, intl)}
        >
          <LegalOwnerForm />
        </Route>
        <Route
          exact
          path={getInternationalizedPath(PATH.INVESTMENT_PORTFOLIO, intl)}
        >
          <InvestmentPortfolio />
        </Route>
        <Route exact path={getInternationalizedPath(PATH.ACCOUNT, intl)}>
          <Account />
        </Route>
        <Route exact path={getInternationalizedPath(PATH.PROFILE_FORM, intl)}>
          <ProfileForm />
        </Route>
        <Route exact path={getInternationalizedPath(PATH.PROJECT_FORM, intl)}>
          <ProjectForm />
        </Route>
        <Route exact path={getInternationalizedPath(PATH.REGISTER, intl)}>
          <Register />
        </Route>
        <Route exact path={getInternationalizedPath(PATH.LOGIN, intl)}>
          <Login />
        </Route>
        <Route exact path={getInternationalizedPath(PATH.PROJECTS, intl)}>
          <Projects />
        </Route>
        <Route
          exact
          path={getInternationalizedPath(PATH.PROJECT_DETAILS, intl, '/:id')}
          component={ProjectDetails}
        ></Route>
        <Route exact path={getInternationalizedPath(PATH.FAVORITES, intl)}>
          <Favorites />
        </Route>
        <Route exact path={PATH.ROOT}>
          <Redirect to={getInternationalizedPath(PATH.PROJECTS, intl)} />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default Routes;
