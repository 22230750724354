import clsx from 'clsx';
import React from 'react';
import greenStarIcon from '../../assets/icons/green-star.svg';
import BasicButton from '../../components/BasicButton/BasicButton';

interface Props {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  ratings?: number;
  totalRating?: number;
  buttonTranslationId: string;
  onClick?: () => void;
  isFixed?: boolean;
  isDisabled?: boolean;
}

const BottomAction: React.FC<Props> = ({
  title,
  subtitle,
  ratings,
  totalRating,
  buttonTranslationId,
  onClick,
  isFixed = false,
  isDisabled = false
}) => {
  return (
    <div
      className={clsx(
        isFixed && 'fixed z-10 bottom-0 left-0 max-w-full',
        'border-t bg-white w-full flex justify-between p-6'
      )}
    >
      <span className="inline-block mr-2">
        <div>
          <span className="text-base">{title}</span>
          <span className="block text-gray-400 text-sm">{subtitle}</span>
        </div>{' '}
        <div className="inline-block">
          <img className="inline" src={greenStarIcon} alt="" />
          <span className="font-semibold">
            {ratings}
            <span className="text-gray-400"> {`(${totalRating})`}</span>
          </span>
        </div>
      </span>
      <BasicButton
        className="rounded flex items-center text-base font-semibold"
        translationId={buttonTranslationId}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    </div>
  );
};
export default BottomAction;
