import { flatten } from 'lodash';
import { useInfiniteQuery } from 'react-query';
import { Project, ProjectStatus } from '../../models/Project';
import { getProjects } from '../networking/apiClient';

export const useInfiniteFetchProjects = (
  limit: number,
  projectStatus?: ProjectStatus
) => {
  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<Project[]>(
    [
      `${projectStatus === ProjectStatus.ACTIVE ? 'active' : 'future'}Projects`,
      limit,
      projectStatus,
    ],
    async ({ pageParam = { page: 0 } }): Promise<Project[]> => {
      const projects = await getProjects(
        limit,
        pageParam.page * limit,
        projectStatus
      );

      return [...projects];
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: (
        lastPage: Project[],
        allPages: Project[][]
      ): { page: number } | undefined => {
        if (lastPage.length) {
          return { page: allPages.length };
        }

        return undefined;
      },
    }
  );
  const flattenedProjects = flatten(data?.pages);

  return {
    data: flattenedProjects,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};
