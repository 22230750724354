import { IntlShape } from 'react-intl';

export const getInternationalizedPath = (
  path: string,
  intl: IntlShape,
  additionalPath?: string
): string => {
  return `${intl.formatMessage({ id: 'routes.prefix' })}${path}${
    additionalPath ?? ''
  }`;
};
