import { IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { isPlatform } from '@ionic/core';
import clsx from 'clsx';
import AlgaeLogoBlue from '../../assets/images/logo.png';
import UserIcon from '../../assets/icons/user.svg';
import BurgerIcon from '../../assets/icons/burger.svg';
import SidebarMenu from '../SidebarMenu/SidebarMenu';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';
import { PATH } from '../../constants/path.const';

const Header: React.FC = () => {
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);
  const router = useIntlRouter();
  const goToProfil = () => {
    router.push(PATH.ACCOUNT);
  };

  // console.log('isIOS', isPlatform('iphone'));

  return (
    <>
      <SidebarMenu
        isOpen={isSidebarMenuOpen}
        onClose={(): void => setIsSidebarMenuOpen(false)}
      />
      <IonHeader
        className={clsx(
          isPlatform('iphone') || isPlatform('ipad') ? 'h-auto' : 'h-auto'
        )}
        translucent={true}
        mode="ios"
      >
        <IonToolbar className="h-full max-w-full" mode="ios">
          <IonButtons slot="start" className="ml-2">
            <img
              className="m-2"
              onClick={(): void => setIsSidebarMenuOpen(true)}
              src={BurgerIcon}
              alt="menu"
            />
          </IonButtons>
          <IonTitle color="primary" className="text-center">
            <div className="relative flex justify-center items-center">
              <a href="/" rel="noreferrer">
                <img className="h-auto w-20" src={AlgaeLogoBlue} alt="" />
              </a>
            </div>
          </IonTitle>
          {/* <IonButtons slot="end" onClick={goToProfil}>
            <img className="m-2" src={UserIcon} alt="profile" />
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>
    </>
  );
};
export default Header;
