import React from 'react';
import asModal from '../../services/hoc/asModal';
import ModalLayout from '../ModalLayout/ModalLayout';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel?: () => void;
  titleTranslationId: string;
  messageTranslationId: string;
  submitTranslationId: string;
  cancelTranslationId: string;
  messageValues?: { [field: string]: string };
}

const BasicActionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  titleTranslationId,
  messageTranslationId,
  messageValues,
  submitTranslationId,
  cancelTranslationId,
}) => {
  return (
    <ModalLayout
      isOpen={isOpen}
      containerClassname="bg-white w-9/12 rounded-xl p-3"
    >
      <div className="mt-3 text-center sm:mt-5">
        <TranslatedMarkupText id={titleTranslationId} />
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            <TranslatedMarkupText
              id={messageTranslationId}
              values={messageValues}
            />
          </p>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
          onClick={() => onSubmit()}
        >
          <TranslatedMarkupText id={submitTranslationId} />
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => onClose()}
        >
          <TranslatedMarkupText id={cancelTranslationId} />
        </button>
      </div>
    </ModalLayout>
  );
};

export default asModal(BasicActionModal);
