import React from 'react';
import clsx from 'clsx';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import CrossIcon from '../../assets/icons/cross.svg';

export enum CLOSABLE_WARNING_STYLES {
  ERROR,
  BASIC,
}

interface Props {
  messages: { icon: string; translationId: string }[];
  onClose: () => void;
  isOpen: boolean;
  style?: CLOSABLE_WARNING_STYLES;
  isFixed?: boolean;
}

const ClosableWarning: React.FC<Props> = ({
  messages,
  isOpen,
  onClose,
  style = CLOSABLE_WARNING_STYLES.BASIC,
  isFixed = false,
}) => {
  const containerStyles = {
    [CLOSABLE_WARNING_STYLES.BASIC]: 'bg-lightGrey',
    [CLOSABLE_WARNING_STYLES.ERROR]: 'bg-fieldError',
  };

  const itemStyles = {
    [CLOSABLE_WARNING_STYLES.BASIC]: 'text-darkGrey',
    [CLOSABLE_WARNING_STYLES.ERROR]: 'text-error',
  };

  return (
    <div className={clsx(isFixed && 'fixed top-0 right-0 left-0')}>
      <div
        className={clsx(
          isOpen
            ? `visible pb-4 pt-6 md:px-4 pr-4 w-full relative flex flex-col ${containerStyles[style]}`
            : 'invisible h-0'
        )}
      >
        {messages.map((message) => (
          <div
            key={message.translationId}
            className={clsx(
              'w-full flex flex-row items-center text-sm',
              itemStyles[style]
            )}
          >
            <span className="w-6 h-6 min-w-6 min-h-6 m-5">
              <img className="w-6 h-6" src={message.icon} alt="" />
            </span>
            <span className="w-full">
              <TranslatedMarkupText id={message.translationId} />
            </span>
          </div>
        ))}
        <img
          className="absolute top-1 right-2"
          src={CrossIcon}
          alt="cross"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default ClosableWarning;
