import axios, { AxiosResponse } from 'axios';

const downloadFileFromUrl = async (
  url?: string
): Promise<AxiosResponse<Blob> | undefined> => {
  if (!url) return;

  return axios.get(url, {
    responseType: 'blob',
  });
};

export default downloadFileFromUrl;
