import React, { RefObject, useEffect } from 'react';

export const useIntersectionObserver = ({
  root,
  target,
  onIntersect,
  threshold = 0.7,
  rootMargin = '0px',
  enabled = false,
}: {
  target: RefObject<HTMLElement | undefined>;
  onIntersect: () => void;
  root?: RefObject<HTMLElement | undefined>;
  threshold?: number;
  rootMargin?: string;
  enabled: boolean;
}): void => {
  useEffect(() => {
    if (!enabled) return;

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root?.current,
        rootMargin,
        threshold,
      }
    );

    const el = target.current;

    if (!el) return;

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [onIntersect, root, rootMargin, target, threshold, enabled]);
};
