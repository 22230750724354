import React, { useState } from 'react';
import AddRatingModal from '../AddRatingModal/AddRatingModal';
import LightButton from '../LightButton/LightButton';

interface Props {
  projectId: number;
  name: string;
  onUpdate?: () => void;
}

const AddRatingModalButton: React.FC<Props> = ({
  projectId,
  name,
  onUpdate,
}) => {
  const [isAddRatingModalOpen, setIsAddRatingModalOpen] = useState(false);

  return (
    <>
      <AddRatingModal
        isOpen={isAddRatingModalOpen}
        onClose={() => setIsAddRatingModalOpen(false)}
        projectId={projectId}
        name={name}
        onUpdate={onUpdate}
      />
      <LightButton
        onClick={() => setIsAddRatingModalOpen(true)}
        translationId="project-details.add-rating"
      />
    </>
  );
};

export default AddRatingModalButton;
