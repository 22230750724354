import { IntlShape } from 'react-intl';

export const getFormattedDateFromNumberOfDays = (
  numberOfDays: number,
  intl: IntlShape
): string => {
  const years = numberOfDays / 365;
  const months = (years % 1) * 12;
  const days = (months % 1) * (365 / 12);

  return `${
    Math.floor(years) > 0
      ? intl.formatMessage(
          { id: Math.floor(years) > 1 ? 'date.years' : 'date.year' },
          { years: Math.floor(years) }
        )
      : ''
  } ${
    Math.floor(months) > 0
      ? intl.formatMessage(
          { id: Math.floor(months) > 1 ? 'date.months' : 'date.month' },
          { months: Math.floor(months) }
        )
      : ''
  } ${
    Math.floor(days) > 0
      ? intl.formatMessage(
          { id: Math.floor(days) > 1 ? 'date.days' : 'date.day' },
          { days: Math.floor(days) }
        )
      : ''
  }`;
};
