import React from 'react';
import { useIntl } from 'react-intl';
import WarningIcon from '../../../assets/icons/warning.svg';
import CrossIcon from '../../../assets/icons/cross.svg';

interface Props {
  failedUploadedFiles: string[];
  setFailedUploadedFiles: (fileNames: string[]) => void;
}

const FileUploadErrorBar: React.FC<Props> = ({
  failedUploadedFiles,
  setFailedUploadedFiles,
}) => {
  const intl = useIntl();

  return (
    <>
      {failedUploadedFiles.map((filename, index) => (
        <div
          key={filename}
          className={
            'flex flex-row h-10 items-center text-xs font-medium bg-fieldError text-error mb-2'
          }
        >
          <img className={'p-2.5'} src={WarningIcon} alt="warning" />
          {intl.formatMessage({ id: 'fileUploadError' })}
          {`${filename}`}
          <img
            className={'p-2.5 ml-auto cursor-pointer'}
            src={CrossIcon}
            alt="cross"
            onClick={() => {
              const files = failedUploadedFiles;
              files.splice(index, 1);
              setFailedUploadedFiles([...files]);
            }}
          />
        </div>
      ))}
    </>
  );
};

export default FileUploadErrorBar;
