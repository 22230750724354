import { User } from '../../models/User';

export const isUserNotified = (
  user: User | undefined,
  projectId: number
): boolean => {
  return Boolean(
    user?.notifications?.find((notification) => notification.projectId === projectId)
  );
};
