import React from 'react';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  titleTranslationId: string;
  descriptionTranslationId: string;
}

const ContentHeader: React.FC<Props> = ({
  titleTranslationId,
  descriptionTranslationId,
}) => {
  return (
    <div className="pl-5 pr-5">
      <p className="text-black text-2xl font-bold mt-5 xl:text-center">
        <TranslatedMarkupText id={titleTranslationId} />
      </p>
      <p className="text-black text-base mt-2 mb-5 xl:text-center">
        <TranslatedMarkupText id={descriptionTranslationId} />
      </p>
    </div>
  );
};

export default ContentHeader;
