import { FormattedMessage } from 'react-intl';
import { Props } from 'react-intl/src/components/message';

const TranslatedMarkupText: React.FC<Props> = (props) => {

  return (
    <FormattedMessage
      {...props}
      values={{
        ...props.values,
        h3: (titleText: string) => <h3 className="text-lg">{titleText}</h3>,
        b: (boldText: string) => <span className="font-bold">{boldText}</span>,
        m: (mediumText: string) => (
          <span className="font-medium">{mediumText}</span>
        ),
        br: <br />,
      }}
    />
  );
};

export default TranslatedMarkupText;
