import React from 'react';
import asModal from '../../../services/hoc/asModal';
import Loader from '../../Loader/Loader';
import ModalLayout from '../../ModalLayout/ModalLayout';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';

const LOADER_HEIGHT = 42;
const LOADER_WIDTH = 42;

interface Props {
  isOpen: boolean;
  messageTranslationId: string;
}

const LoadingModal: React.FC<Props> = ({ isOpen, messageTranslationId }) => {
  return (
    <ModalLayout
      isOpen={isOpen}
      containerClassname="bg-white w-9/12 rounded-xl p-3"
    >
      <div className="mt-3 text-center sm:mt-5">
        <Loader color="blue" height={LOADER_HEIGHT} width={LOADER_WIDTH} />
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            <TranslatedMarkupText id={messageTranslationId} />
          </p>
        </div>
      </div>
    </ModalLayout>
  );
};

export default asModal(LoadingModal);
