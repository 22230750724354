import { useContext, useState } from 'react';
import BasicButton from '../BasicButton/BasicButton';
import { CheckableButton } from '../CheckableButton/CheckableButton';
import SwipeableModalLayout from '../SwipeableModalLayout/SwipeableModalLayout';
import {
  defaultContextValue,
  FilterContext,
} from '../../services/context/projectFilterContext';
import { ProjectStatus } from '../../models/Project';
import { filterLocations } from '../../constants/filterLocations.const';

interface Props {
  isOpen: boolean;
  onSubmit?: () => void;
  onClose: () => void;
}
export const ProjectFilterModal: React.FC<Props> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [isSelectPending, setIsSelectPending] = useState(false);
  const [isSelectWaiting, setIsSelectWaiting] = useState(false);

  const { filterContext, setFilterContext } = useContext(FilterContext);

  const [locationList, setLocationList] = useState(filterLocations);

  const onCheckButtonLocation = (index: number) => {
    const locations = locationList;
    locations[index].checked = !locations[index].checked;
    setLocationList([...locations]);
    if (locations[index].checked)
      filterContext.city.push(locations[index].city);
    else
      filterContext.city.splice(
        filterContext.city.indexOf(locations[index].city),
        1
      );
    setFilterContext({ ...filterContext });
  };

  const addFilterStatus = (status: ProjectStatus) => {
    filterContext.status.push(status);
    setFilterContext({ ...filterContext });
  };

  const removeFilterStatus = (status: ProjectStatus) => {
    filterContext.status.splice(filterContext.status.indexOf(status), 1);
    setFilterContext({ ...filterContext });
  };

  return (
    <SwipeableModalLayout isOpen={isOpen} onClose={onClose}>
      <div className="bg-white w-full h-full rounded-t-xl p-10">
        <p className="text-gray-500 ml-1">Status du projet</p>

        <div className="flex justify-between w-full mt-2 mb-2">
          <CheckableButton
            checked={isSelectPending}
            onCheck={() => {
              setIsSelectPending(!isSelectPending);
              if (!isSelectPending)
                addFilterStatus(ProjectStatus.PENDING_LAUNCH);
              else removeFilterStatus(ProjectStatus.PENDING_LAUNCH);
            }}
            translationId="project-filter.pending"
          />
          <CheckableButton
            checked={isSelectWaiting}
            onCheck={() => {
              setIsSelectWaiting(!isSelectWaiting);
              if (!isSelectWaiting) addFilterStatus(ProjectStatus.ACTIVE);
              else removeFilterStatus(ProjectStatus.ACTIVE);
            }}
            translationId="project-filter.waiting"
          />
        </div>
        <p className="text-gray-500 ml-1">Localisation</p>
        <div className="block mt-2 p-1 w-full overflow-y-scroll max-h-96">
          <div className="block items-center items-start mb-4">
            {locationList.map((location, index) => {
              return (
                <CheckableButton
                  key={index}
                  checked={location.checked}
                  onCheck={() => onCheckButtonLocation(index)}
                  label={location.city}
                />
              );
            })}
          </div>
        </div>

        <div className="absolute bottom-0 p-5 w-full left-0 text-center">
          <BasicButton
            translationId="search"
            onClick={onSubmit}
            className="w-full rounded-md"
          />
        </div>
      </div>
    </SwipeableModalLayout>
  );
};
