import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { FormikErrors, getIn } from 'formik';
import EyeIcon from '../../../assets/icons/eye.svg';
import EyeCrossedIcon from '../../../assets/icons/eye-crossed.svg';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';

export interface ControlledInputTextCommonProps<TFields> {
  name: string;
  errors?: FormikErrors<TFields>;
  labelTranslationId?: string;
  italicLabelTranslationId?: string;
  placeholderTranslationId?: string;
  placeholderTranslationValues?: Record<string, string>;
  value: string | number | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocusOut?: () => void;
  isDisabled?: boolean;
  hasFloatingLabel?: boolean;
}

interface Props<TFields> extends ControlledInputTextCommonProps<TFields> {
  fieldType?: string;
  maxLength?: number;
  inputMask?: (value: string) => string;
}

const ControlledInputText = <TFields,>({
  labelTranslationId,
  onFocusOut,
  placeholderTranslationId,
  placeholderTranslationValues,
  value,
  onChange,
  name,
  inputMask,
  fieldType,
  italicLabelTranslationId,
  errors,
  maxLength,
  isDisabled = false,
  hasFloatingLabel = false,
}: Props<TFields>): JSX.Element => {
  const intl = useIntl();
  const error = useMemo(() => getIn(errors, name), [errors, name]);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (error && !isDisabled) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error, isDisabled]);

  const [inputType, setInputType] = useState(fieldType);

  const toggleInputVisibility = () => {
    setInputType(inputType === 'text' ? 'password' : 'text');
  };

  return (
    <>
      {labelTranslationId && !hasFloatingLabel && (
        <h3
          className={clsx(
            'text-primary text-l font-normal mb-1',
            isDisabled && 'text-mediumGrey'
          )}
        >
          <TranslatedMarkupText id={labelTranslationId} />
          {italicLabelTranslationId && (
            <>
              {' '}
              <span className="italic">
                <TranslatedMarkupText id={italicLabelTranslationId} />
              </span>
            </>
          )}
        </h3>
      )}
      <div className="relative bg-lightGrey flex">
        <input
          type={
            fieldType
              ? fieldType === 'password' || fieldType === 'number'
                ? inputType
                : 'text'
              : 'text'
          }
          name={name}
          className={clsx(
            hasFloatingLabel
              ? 'appearance-none focus:outline-none bg-transparent pt-3.5 px-4'
              : 'bg-white p-2.5',
            'w-full h-14 block border rounded-lg outline-none text-mediumGrey',
            hasError && error
              ? 'text-error bg-fieldError border-error placeholder-error'
              : 'focus:border-primary border-input text-darkGrey',
            isDisabled && 'border-mediumGrey bg-lightGrey'
          )}
          placeholder={
            hasFloatingLabel
              ? ' ' // We need a placeholder to check if input has value for floating label
              : placeholderTranslationId &&
                intl.formatMessage(
                  { id: placeholderTranslationId },
                  placeholderTranslationValues
                )
          }
          value={value}
          spellCheck={false}
          maxLength={maxLength}
          step="any"
          //@ts-expect-error blur doesn't exit on type event.target despite the fact that it's working
          // We followed https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
          onWheel={(event) => event.target.blur()}
          onChange={(event) => {
            setHasError(false);
            if (inputMask) {
              event.target.value = inputMask(event.target.value);
            }

            return onChange(event);
          }}
          onBlur={onFocusOut}
          disabled={isDisabled}
        />
        {hasFloatingLabel && (
          <label
            htmlFor={name}
            className={clsx(
              'absolute top-1/3 left-4 duration-300 origin-0 text-formInput',
              hasError && error ? 'text-error' : 'text-floatingLabel'
            )}
          >
            <TranslatedMarkupText id={placeholderTranslationId} />
          </label>
        )}
        {fieldType === 'password' && (
          <img
            src={inputType === 'password' ? EyeIcon : EyeCrossedIcon}
            alt="Montrer/cacher le texte"
            className="absolute h-6 w-6 right-2.5 self-center cursor-pointer"
            onClick={() => toggleInputVisibility()}
          />
        )}
      </div>
      {error && hasError && (
        <p className="text-error text-sm mt-1">
          <TranslatedMarkupText id={`formValidation.${error as string}`} />
        </p>
      )}
    </>
  );
};

export default ControlledInputText;
