import clsx from 'clsx';
import { FormikErrors, getIn } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import CrossIcon from '../../../assets/icons/cross.svg';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  placeholderTranslationId: string;
  onDelete?: () => void;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLElement>;
  value?: string;
  errors?: FormikErrors<{ [field: string]: unknown }>;
  name: string;
  isDisabled?: boolean;
}

const InputDatePicker: React.FC<Props> = ({
  placeholderTranslationId,
  value,
  onChange,
  onClick,
  onDelete,
  errors,
  name,
  isDisabled = false,
}) => {
  const error = useMemo(() => getIn(errors, name), [errors, name]);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error]);

  useEffect(() => {
    if (value) {
      setHasError(false);
    }
  }, [value]);

  return (
    <div>
      <div
        className={clsx(
          'relative flex rounded-lg border',
          hasError && error
            ? 'border-error bg-fieldError placeholder-error text-error'
            : 'bg-lightGrey border-input text-darkGrey'
        )}
      >
        <button
          type="button"
          className={clsx(
            'w-full h-14 rounded-lg flex relative',
            hasError && error
              ? ' bg-fieldError'
              : 'text-darkGrey focus:border-primary'
          )}
          onClick={onClick}
        >
          <input
            id="input-date-picker"
            type={'text'}
            className={clsx(
              'appearance-none focus:outline-none bg-transparent pt-3.5 px-4',
              'w-full h-14 block rounded-lg outline-none text-darkGrey overflow-ellipsis'
            )}
            value={value}
            spellCheck={false}
            placeholder={' '} // We need a placeholder for the floating label to reset
            //@ts-expect-error blur doesn't exit on type event.target despite the fact that it's working
            // We followed https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
            onWheel={(event) => event.target.blur()}
            onChange={(event) => {
              onChange?.(event);
            }}
            disabled={isDisabled}
          />
          <label
            htmlFor="input-date-picker"
            className={clsx(
              'absolute top-1/3 left-4 duration-300 origin-0  text-formInput',
              hasError && error ? 'text-error' : 'text-floatingLabel'
            )}
          >
            <TranslatedMarkupText id={placeholderTranslationId} />
          </label>
          {onDelete && (
            <div className="flex justify-center h-full items-center mr-2">
              {value && (
                <img
                  src={CrossIcon}
                  alt="CrossIcon"
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete();
                  }}
                />
              )}
            </div>
          )}
        </button>
      </div>
      {error && hasError && (
        <p className="text-error text-sm mt-1">
          <TranslatedMarkupText id={`formValidation.${error as string}`} />
        </p>
      )}
    </div>
  );
};

export default InputDatePicker;
