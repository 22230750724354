import clsx from 'clsx';
import React from 'react';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  onClick?: () => void;
  translationId: string;
}

const LightButton: React.FC<Props> = ({ onClick, translationId }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        'py-2.5 px-4 flex relative w-full justify-center h-12',
        'bg-lightGrey rounded-lg',
        'font-bold text-formInput'
      )}
    >
      <TranslatedMarkupText id={translationId} />
    </button>
  );
};

export default LightButton;
