import React from 'react';
import clsx from 'clsx';
import FillableStarIcon from '../../Icons/FillableStarIcon/FillableStarIcon';

interface Props {
  onChange?: (value: number) => void;
  value: number;
  isInteractive?: boolean;
  buttonClassname?: string;
}

const InputRating: React.FC<Props> = ({
  onChange,
  value,
  isInteractive = true,
  buttonClassname = '',
}) => {
  return (
    <div>
      <div className="flex items-center">
        {[1, 2, 3, 4, 5].map((rating) => (
          <button
            type="button"
            key={rating}
            className={clsx(
              `flex-shrink-0 ${buttonClassname ? buttonClassname : 'h-5 w-5'}`
            )}
            onClick={() => onChange?.(rating)}
            disabled={!isInteractive}
          >
            <FillableStarIcon
              fillPercentage={
                value >= rating
                  ? 1
                  : value + 1 >= rating
                  ? Math.round((value % 1) * 100) / 100
                  : 0
              }
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default InputRating;
