import { Address } from '../../models/Address';
import { CountryIso } from '../../models/LegalOwner';
import {
  getGooglePlaceDetailsFieldValue,
  GOOGLE_PLACE_DETAILS_FIELD_KEY,
} from './getGooglePlaceDetailsFieldValue';

export const getAddressFromGooglePlaceId = (
  placeId: string,
  divRef: HTMLDivElement | null,
  lat: number,
  lng: number
): Promise<Address> | null => {
  if (!divRef) return null;
  const googleService = new google.maps.places.PlacesService(divRef);

  const request = {
    placeId: placeId,
    fields: ['address_component'],
  };

  return new Promise((resolve) => {
    googleService.getDetails(
      request,
      (result: google.maps.places.PlaceResult | null) => {
        if (result) {
          const zipCode = getGooglePlaceDetailsFieldValue(
            result,
            'postal_code',
            GOOGLE_PLACE_DETAILS_FIELD_KEY.LONG_NAME
          );
          const streetNumber = getGooglePlaceDetailsFieldValue(
            result,
            'street_number',
            GOOGLE_PLACE_DETAILS_FIELD_KEY.LONG_NAME
          );
          const route = getGooglePlaceDetailsFieldValue(
            result,
            'route',
            GOOGLE_PLACE_DETAILS_FIELD_KEY.LONG_NAME
          );
          const country = getGooglePlaceDetailsFieldValue(
            result,
            'country',
            GOOGLE_PLACE_DETAILS_FIELD_KEY.LONG_NAME
          );
          const city = getGooglePlaceDetailsFieldValue(
            result,
            'locality',
            GOOGLE_PLACE_DETAILS_FIELD_KEY.LONG_NAME
          );
          const countryIso = getGooglePlaceDetailsFieldValue(
            result,
            'country',
            GOOGLE_PLACE_DETAILS_FIELD_KEY.SHORT_NAME
          );

          resolve({
            city: city,
            country: country,
            geoLat: lat,
            geoLng: lng,
            streetAddress: `${streetNumber} ${route}`,
            countryIso: countryIso as CountryIso,
            zipCode: zipCode,
          });
        }
      }
    );
  });
};
