import { SetStateAction, useState } from "react";
import { useHistory } from "react-router-dom";
import { Project } from "../../models/Project";
import { User } from "../../models/User";
import { isUserFavorite } from "../../services/helpers/isUserFavorite";
import { isUserNotified } from "../../services/helpers/isUserNotified";
import BasicButton from "../BasicButton/BasicButton";
import BasicToast from "../BasicToast/BasicToast";
import NotificationButton from "../NotificationButton/NotificationButton";
import TranslatedMarkupText from "../TranslatedMarkupText/TranslatedMarkupText";

export interface FinishedProjectModaleProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  setIsCalculatorModaleOpen: React.Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  user?: User;
  project: Project;
  refetchUser: () => void;
}

const FinishedProjectModale: React.FC<FinishedProjectModaleProps> = ({
  isOpen,
  setIsOpen,
  setIsCalculatorModaleOpen,
  user,
  project,
  refetchUser,
}) => {

  return (
    <>
      {isOpen && (
        <div className="w-full h-screen fixed bg-black z-50 bg-opacity-50 backdrop-blur-sm">
          <div className="w-[90%] p-3 left-5 top-40 bg-white shadow-lg rounded-md relative">
            <div className="text-center font-bold text-xl py-5">
              <TranslatedMarkupText id="finished-project-modale.title" />
            </div>

            <div className="flex-col px-5">
              <TranslatedMarkupText id={user ? "finished-project-modale.message" : "finished-project-modale.no-user-message"} />

              <div className="justify-center pt-5">
                {user && (
                  <NotificationButton
                    isAlreadyNotified={isUserNotified(user, project.id)}
                    user={user}
                    projectId={project.id}
                    onUpdate={() => void refetchUser}
                    setIsFinishedProjectModaleOpen={setIsOpen}
                    setIsCalculatorModaleOpen={setIsCalculatorModaleOpen}
                  />
                )}

                <button
                  className="text-link p-2 block w-10/12 mx-auto text-sm"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <TranslatedMarkupText id={user ? "finished-project-modale.refuse" : "alts.close"} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FinishedProjectModale;
