import { useQuery } from 'react-query';

import { getProjectDetails } from '../networking/apiClient';

export const useFetchProjectDetails = (id: string) => {
  const { data, isError, error, isLoading, refetch } = useQuery(
    'getProjectDetails',
    () => getProjectDetails(id),
    {
      retry: false,
    }
  );

  return { data, isError, error, isLoading, refetch };
};
