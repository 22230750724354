export const filterLocations = [
  { city: 'Versailles', checked: false },
  { city: 'Bordeaux', checked: false },
  { city: 'Paris', checked: false },
  { city: 'Nantes', checked: false },
  { city: 'Lille', checked: false },
  { city: 'Starsbourg', checked: false },
  { city: 'Lyon', checked: false },
  { city: 'Amsterdam', checked: false },
];
