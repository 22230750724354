import { useQuery } from 'react-query';
import { getCurrentUserFavorites } from '../networking/apiClient';

export const useFetchCurrentUserFavorites = () => {
  const { data, isError, error, isLoading, refetch } = useQuery(
    'getCurrentUserFavorites',
    getCurrentUserFavorites,
    {
      retry: false,
    }
  );

  const userFavoritesProjects = data?.flatMap((favorite) => favorite.project);

  return { data: userFavoritesProjects, isError, error, isLoading, refetch };
};
