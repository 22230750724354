import { IonRouterLink, useIonRouter } from '@ionic/react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import BasicButton from '../../components/BasicButton/BasicButton';
import ClosableWarning, {
  CLOSABLE_WARNING_STYLES,
} from '../../components/ClosableWarning/ClosableWarning';
import ControlledInputText from '../../components/Forms/ControlledInputText/ControlledInputText';
import Layout from '../../components/Layout/Layout';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import { PATH } from '../../constants/path.const';
import { User } from '../../models/User';
import {
  POST_SUCCESS_STATUS,
  postUser,
  SUCCESS_STATUS,
} from '../../services/networking/apiClient';
import {
  deleteFirebaseCurrentUser,
  firebaseRegister,
} from '../../services/networking/firebase';
import WarningIcon from '../../assets/icons/warning.svg';
import { getInternationalizedPath } from '../../services/helpers/getInternationalizedPath';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';
import AlgaeLogo from '../../assets/images/logo.png';
import LanguageSelect from '../../components/LanguageSelect/LanguageSelect';

const PASSWORD_MIN_LENGTH = 6;

type RegisterFormData = {
  email: string;
  password: string;
  user: Partial<User>;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email('invalid-email').required('invalid-email'),
  password: Yup.string()
    .required('invalid-password')
    .min(PASSWORD_MIN_LENGTH, 'password-min-length'),
});

const Register: React.FC = () => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isErrorRegistering, setIsErrorRegistering] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const router = useIonRouter();
  const intl = useIntl();

  enum error {
    ALREADY_EXISTS = 'api.errors.register-error',
    API_ERROR = 'api.errors.error-occurred-please-try-again',
  }

  return (
    <Layout isScreenHeight>
      <div className="flex flex-col h-screen pt-10">
        <div className="flex-shrink-0 flex-col w-full items-center text-center px-4 justify-center">
          <a href="/" className="block lg:w-1/2 lg:mx-auto">
            <img
              className="h-auto w-1/2 mx-auto"
              src={AlgaeLogo}
              alt={intl.formatMessage({ id: 'alts.algae' })}
            />
          </a>
          <div className="block">
            <LanguageSelect />
          </div>
        </div>
        <ClosableWarning
          messages={[
            {
              icon: WarningIcon,
              translationId: !isErrorRegistering
                ? error.API_ERROR
                : error.ALREADY_EXISTS,
            },
          ]}
          isOpen={isSubmitError}
          onClose={() => setIsSubmitError(false)}
          style={CLOSABLE_WARNING_STYLES.ERROR}
          isFixed
        />
        <div className="bg-white flex-grow flex mx-auto items-center w-full max-w-form">
          <Formik
            initialValues={
              {
                email: '',
                password: '',
              } as RegisterFormData
            }
            validationSchema={VALIDATION_SCHEMA}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values, actions) => {
              setIsSubmitLoading(true);
              const statusCode = await firebaseRegister({
                email: values.email,
                password: values.password,
              });

              actions.setSubmitting(false);
              setIsSubmitLoading(false);

              if (statusCode === SUCCESS_STATUS) {
                const response = await postUser({
                  email: values.email,
                } as User);

                if (response) {
                  router.push(PATH.PROFILE_FORM);
                } else {
                  setIsSubmitError(true);
                  await deleteFirebaseCurrentUser();
                }
              } else {
                setIsErrorRegistering(true);
                setIsSubmitError(true);
              }
            }}
          >
            {(props) => (
              <form
                className="w-full"
                onSubmit={props.handleSubmit}
                autoComplete="off"
                action="..."
              >
                <div className="w-full px-4">
                  <div className="text-xl font-bold">
                    <TranslatedMarkupText id="register.title" />
                  </div>
                  <div className="mt-2">
                    <ControlledInputText
                      value={props.values.email}
                      labelTranslationId={'register.email'}
                      placeholderTranslationId={'register.email-address'}
                      name="email"
                      onChange={props.handleChange}
                      errors={props.errors}
                    />
                  </div>
                  <div className="mt-2">
                    <ControlledInputText
                      value={props.values.password}
                      labelTranslationId={'register.password'}
                      placeholderTranslationId={'register.password'}
                      name="password"
                      onChange={props.handleChange}
                      errors={props.errors}
                      fieldType="password"
                    />
                  </div>

                  <div className="mt-4 w-full">
                    <BasicButton
                      className="w-full rounded-lg"
                      type="submit"
                      translationId="register.register"
                      onClick={() => {
                        props.setErrors({});
                      }}
                      isLoading={isSubmitLoading}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="text-center text-md text-darkGrey pb-20">
          <TranslatedMarkupText id="register.already-has-account" />{' '}
          <IonRouterLink
            href={getInternationalizedPath(PATH.LOGIN, intl)}
            className="text-primary text-md font-semibold"
          >
            <TranslatedMarkupText id="register.login" />
          </IonRouterLink>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
