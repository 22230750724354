import React, { useEffect, useState } from 'react';
import { Balance } from '../../models/Balance';
import { Project } from '../../models/Project';
import ProjectCard from '../ProjectCard/ProjectCard';
import ProjectSmallCard from '../ProjectSmallCard/ProjectSmallCard';

export enum PROJECT_LIST_STYLES {
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

interface Props {
  projects: Project[];
  balances?: Balance[];
  style?: PROJECT_LIST_STYLES;
}

const ProjectList: React.FC<Props> = ({
  projects,
  balances,
  style = PROJECT_LIST_STYLES.MEDIUM,
}) => {
  return (
    <>
      {balances?.map((balance, index) => {
        switch (style) {
          case PROJECT_LIST_STYLES.MEDIUM:
            return (
              <ProjectCard key={balance.project.id} project={balance.project} />
            );
          case PROJECT_LIST_STYLES.SMALL:
            return (
              <div key={balance.project.id}>
                <ProjectSmallCard project={balance.project} balance={balance} />
                {index !== balances.length - 1 && <hr className="mx-4" />}
              </div>
            );
        }
      })}
      {!balances &&
        projects.map((project, index) => {
          switch (style) {
            case PROJECT_LIST_STYLES.MEDIUM:
              return <ProjectCard key={project.id} project={project} />;
            case PROJECT_LIST_STYLES.SMALL:
              return (
                <div key={project.id}>
                  <ProjectSmallCard project={project} />
                  {index !== projects.length - 1 && <hr className="mx-4" />}
                </div>
              );
          }
        })}
    </>
  );
};

export default ProjectList;
