import { useQuery } from 'react-query';
import { getCurrentUserWallet } from '../networking/apiClient';

export const useFetchCurrentUserWallet = () => {
  const { data, isError, error, isLoading, refetch, isFetching } = useQuery(
    'getCurrentUserWallet',
    getCurrentUserWallet,
    {
      retry: false,
    }
  );

  return { data, isError, error, isLoading, refetch, isFetching };
};
