export const PATH = {
  ROOT: '',
  LOGIN: '/login',
  REGISTER: '/register',
  PROJECTS: '/projects',
  PROJECT_DETAILS: '/project',
  PROJECT_FORM: '/projectForm',
  FAVORITES: '/favorites',
  ACCOUNT: '/account',
  PROFILE_FORM: '/profileForm',
  WALLET: '/wallet',
  LEGAL_OWNER_FORM: '/legalOwnerForm',
  INVESTMENT_PORTFOLIO: '/investmentPortfolio',
  KYC_DOCUMENTS: '/KYCDocuments',
};
