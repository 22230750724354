import { getFirebaseCurrentUserAccessToken } from './firebase';

const baseUrl = process.env.REACT_APP_PUBLIC_API_BASE_URL;

export type Method = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

export interface HttpClientProps {
  method: Method;
  url: string;
  headers: HeadersInit;
  body?: BodyInit;
}

export const request = ({
  method,
  url,
  headers,
  body,
}: HttpClientProps): Promise<Response> => {
  const requestOptions: RequestInit = {
    method: method,
    headers: headers,
    body: body,
  };

  return fetch(url, requestOptions);
};

interface ApiRequestProps {
  method: Method;
  endpoint: string;
  withBearer?: boolean;
  body?: Record<string, unknown> | Record<string, unknown>[] | FormData | null;
  isMultiPart?: boolean;
}

export const sendApiRequest = async ({
  method,
  endpoint,
  withBearer = true,
  body,
  isMultiPart,
}: ApiRequestProps): Promise<Response> => {
  const headers = new Headers();

  if (withBearer) {
    const bearerToken: string | null =
      await getFirebaseCurrentUserAccessToken();
    headers.append('Authorization', `Bearer ${bearerToken ?? ''}`);
    const customTokenApi = window.sessionStorage.getItem('token') ?? '';
    headers.append('custom-api-token', customTokenApi);
    headers.append(
      'Access-Control-Allow-Methods',
      'GET,POST,PUT,PATCH,DELETE,OPTIONS'
    );
  }
  if (!isMultiPart) {
    headers.append('Content-type', 'application/json');
  }
  const requestBody =
    isMultiPart && body
      ? (body as FormData)
      : body
      ? JSON.stringify(body)
      : undefined;

  return request({
    method: method,
    url: `${baseUrl ?? ''}${endpoint}`,
    headers: headers,
    body: requestBody,
  });
};
