import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Browser } from '@capacitor/browser';
import { Project, ProjectStatus } from '../../models/Project';
import { computeAverageRatingsNote } from '../../services/helpers/computeAverageRatingsNote';
import BottomAction from '../BottomAction/BottomAction';
import SwipeableModalLayout from '../SwipeableModalLayout/SwipeableModalLayout';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import { computeProfitPerMonth } from '../../services/helpers/computeProfitPerMonth';
import { computeRoundedNumber } from '../../services/helpers/computeRoundedNumber';
import {
  postCurrentUserBuyProjectShares,
  postCurrentUserTopUpWallet,
} from '../../services/networking/apiClient';
import ErrorToast from '../ErrorToast/ErrorToast';
import SwipeableModalHeader from '../SwipeableModalHeader/SwipeableModalHeader';
import PaymentMethodChoice, {
  PAYMENT_METHOD_TYPE,
  PaymentMethodChoiceEvent,
} from '../Forms/PaymentMethodChoice/PaymentMethodChoice';
import { useFetchCurrentUserWallet } from '../../services/hooks/useFetchCurrentUserWallet';
import BasicToast from '../BasicToast/BasicToast';
import BasicActionModal from '../BasicActionModal/BasicActionModal';
import WarningIcon from '../../assets/icons/warning.svg';
import LoadingModal from '../Forms/LoadingModal/LoadingModal';
import { until } from '../../services/helpers/until';
import { User } from '../../models/User';
import { isUserDisabled } from '../../services/helpers/isUserDisabled';

const PAYMENT_METHOD_DEFAULT_VALUE = { type: PAYMENT_METHOD_TYPE.WALLET };

interface Props {
  selectedNumberOfShares: number;
  project: Project;
  user?: User;
  isOpen: boolean;
  onClose: (numberOfShares: number) => void;
  ionicPresentingElement?: HTMLIonModalElement;
}

const InvestModal: React.FC<Props> = ({
  selectedNumberOfShares,
  project,
  user,
  isOpen,
  onClose,
  ionicPresentingElement,
}) => {
  // TO-DO remove mock
  project.profitability = 10;

  const profitPerMonth = useMemo(
    () =>
      computeProfitPerMonth(
        project.profitability,
        project.sharePrice,
        selectedNumberOfShares
      ),
    [selectedNumberOfShares, project]
  );

  const averageRatingsNote = useMemo(
    () => project.ratings && computeAverageRatingsNote(project.ratings),
    [project]
  );

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodChoiceEvent>(PAYMENT_METHOD_DEFAULT_VALUE);
  const [isInsufficientFundsToastOpen, setIsInsufficientFundsToastOpen] =
    useState(false);

  const {
    data: userWallet,
    refetch: refetchCurrentUserWallet,
    isFetching: isFetchCurrentUserWalletFetching,
  } = useFetchCurrentUserWallet();

  const userBalanceDifference = useMemo(() => {
    if (!isFetchCurrentUserWalletFetching && userWallet) {
      return (
        selectedNumberOfShares * project.sharePrice -
        parseInt(userWallet.balance)
      );
    }
  }, [
    selectedNumberOfShares,
    project.sharePrice,
    userWallet,
    isFetchCurrentUserWalletFetching,
  ]);

  const payWithCard = async () => {
    if (!userWallet) return;

    setIsSubmitLoading(true);
    if (selectedPaymentMethod.cardId) {
      const topUpResponse = await postCurrentUserTopUpWallet(
        project.sharePrice * selectedNumberOfShares * 100,
        0,
        selectedPaymentMethod.cardId
      );
      if ('statusCode' in topUpResponse) {
        if (topUpResponse.statusCode === 420) {
          await Browser.open({ url: topUpResponse.message });
          if (userBalanceDifference) {
            await until(async () => {
              const refetchWallet = await refetchCurrentUserWallet();
              if (refetchWallet.data) {
                const balanceDifference =
                  selectedNumberOfShares * project.sharePrice -
                  parseInt(refetchWallet.data.balance);

                return balanceDifference <= 0;
              }

              return false;
            }, 1000);
            await Browser.close(); // Only works in iOS
            await paymentSecureModeCallback();
          }
          setIsSubmitLoading(false);
        }
      } else if (topUpResponse.externalPaymentId) {
        await paymentSecureModeCallback();
      } else {
        setIsSubmitErrorToastOpen(true);

        return;
      }
    }

    setIsSubmitLoading(false);
  };

  const payWithWallet = async (balanceDifference: number): Promise<void> => {
    if (balanceDifference > 0) {
      setIsInsufficientFundsToastOpen(true);

      return;
    }
    setIsSubmitLoading(true);
    const paymentResponse = await postCurrentUserBuyProjectShares(
      project.id,
      selectedNumberOfShares
    );

    if ('statusCode' in paymentResponse) {
      setIsSubmitLoading(false);
      setIsSubmitErrorToastOpen(true);

      return;
    }
    setSelectedPaymentMethod(PAYMENT_METHOD_DEFAULT_VALUE);
    onClose(selectedNumberOfShares);
    setIsSubmitLoading(false);
  };

  const paymentSecureModeCallback = useCallback(async () => {
    const refetchWallet = await refetchCurrentUserWallet();
    if (refetchWallet.data) {
      const balanceDifference =
        selectedNumberOfShares * project.sharePrice -
        parseInt(refetchWallet.data.balance);
      if (balanceDifference <= 0) {
        await payWithWallet(balanceDifference);
      }

      return;
    }

    return;
  }, [project.sharePrice, selectedNumberOfShares]);

  const handlePayment = async () => {
    if (!user) return;

    switch (selectedPaymentMethod.type) {
      case PAYMENT_METHOD_TYPE.WALLET: {
        if (userBalanceDifference !== undefined) {
          await payWithWallet(userBalanceDifference);
        }

        break;
      }
      case PAYMENT_METHOD_TYPE.CARD: {
        await payWithCard();

        break;
      }
    }
  };

  const [isSubmitErrorToastOpen, setIsSubmitErrorToastOpen] = useState(false);
  const [isTopUpWalletModalOpen, setIsTopUpWalletModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  return (
    <>
      <LoadingModal
        isOpen={isSubmitLoading}
        messageTranslationId="invest-modal.submit-loading"
      />
      {userWallet && (
        <BasicActionModal
          isOpen={isTopUpWalletModalOpen}
          onClose={() => setIsTopUpWalletModalOpen(false)}
          onSubmit={() => {
            setIsTopUpWalletModalOpen(false);
            payWithCard();
          }}
          titleTranslationId="invest-modal.top-up-modal.title"
          messageTranslationId="invest-modal.top-up-modal.message"
          submitTranslationId="invest-modal.top-up-modal.submit"
          cancelTranslationId="invest-modal.top-up-modal.cancel"
          messageValues={{
            price: `${
              selectedNumberOfShares * project.sharePrice -
              parseInt(userWallet.balance)
            }`,
          }}
        />
      )}
      <BasicToast
        isOpen={isInsufficientFundsToastOpen}
        onClose={() => setIsInsufficientFundsToastOpen(false)}
        messageTranslationId="invest-modal.insufficient-funds-error"
        icon={WarningIcon}
      />
      <ErrorToast
        isOpen={isSubmitErrorToastOpen}
        onClose={() => setIsSubmitErrorToastOpen(false)}
      />
      <SwipeableModalLayout
        isOpen={isOpen}
        ionicPresentingElement={ionicPresentingElement}
        header={
          <SwipeableModalHeader
            translationId="invest-modal.title"
            onClose={() => {
              setSelectedPaymentMethod(PAYMENT_METHOD_DEFAULT_VALUE);
              onClose(0);
            }}
          />
        }
      >
        <div className="relative w-full h-full bg-white rounded-t-2xl flex flex-col">
          <div className="pt-5 px-5 text-black text-l font-semibold text-center">
            {project.name}
          </div>
          <hr className="m-5" />
          <div className="mt-5 px-5 flex flex-row justify-between items-center">
            <div className="w-1/2">
              {selectedNumberOfShares}{' '}
              <TranslatedMarkupText
                id={`${
                  selectedNumberOfShares === 1
                    ? 'invest-modal.share'
                    : 'invest-modal.shares'
                }`}
              />
            </div>
            <div className="w-1/2 flex flex-col text-right">
              <div className="">
                {`${project.sharePrice}`}
                <TranslatedMarkupText id="project-profit-calculator.price-unit" />
                <TranslatedMarkupText id="project-profit-calculator.by-share" />
              </div>
            </div>
          </div>
          <div className="mt-5 px-5 flex flex-row justify-between items-center">
            <div className="w-1/2 text-semibold text-mediumGrey">
              <TranslatedMarkupText id="invest-modal.subtotal" />
            </div>
            <div>
              {`${selectedNumberOfShares * project.sharePrice}`}
              <TranslatedMarkupText id="project-profit-calculator.price-unit" />
            </div>
          </div>
          <div className="mt-5 px-5 pb-32">
            <PaymentMethodChoice
              onChange={(event) => setSelectedPaymentMethod(event)}
              withTitle
              withWallet
            />
          </div>
          <BottomAction
            title={
              <TranslatedMarkupText
                id={
                  selectedNumberOfShares > 1
                    ? 'project-profit-calculator.footer.title-multiple'
                    : 'project-profit-calculator.footer.title-single'
                }
                values={{
                  total: computeRoundedNumber(
                    selectedNumberOfShares * project.sharePrice,
                    2
                  ),
                  shareAmount: selectedNumberOfShares,
                }}
              />
            }
            subtitle={
              <span>
                <TranslatedMarkupText id="project-profit-calculator.profit" />{' '}
                {computeRoundedNumber(profitPerMonth, 2)}
                <TranslatedMarkupText id="project-profit-calculator.price-unit-per-month" />
              </span>
            }
            ratings={averageRatingsNote ?? 0}
            totalRating={project.ratings?.length}
            buttonTranslationId="invest-modal.pay"
            onClick={() => void handlePayment()}
            isFixed={false}
            isDisabled={isUserDisabled(user)}
          />
        </div>
      </SwipeableModalLayout>
    </>
  );
};

export default InvestModal;