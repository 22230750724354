import clsx from 'clsx';
import { FormikErrors, getIn } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';
import CrossIcon from '../../../assets/icons/cross.svg';

interface Props {
  value: string | undefined;
  errors?: FormikErrors<{ [field: string]: unknown }>;
  placeholderTranslationId?: string;
  hasFloatingLabel?: boolean;
  name: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  onDelete?: () => void;
  onClick?: () => void;
}

const InputTextButton: React.FC<Props> = ({
  value,
  name,
  onChange,
  placeholderTranslationId,
  errors,
  hasFloatingLabel = false,
  isDisabled = false,
  onDelete,
  onClick,
}) => {
  const intl = useIntl();
  const error = useMemo(() => getIn(errors, name), [errors, name]);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error]);

  useEffect(() => {
    if (value) {
      setHasError(false);
    }
  }, [value]);

  return (
    <div>
      <div
        className={clsx(
          'relative flex rounded-lg border',
          hasError && error
            ? 'border-error bg-fieldError placeholder-error text-error'
            : 'bg-lightGrey border-input text-darkGrey'
        )}
      >
        <button
          type="button"
          onClick={onClick}
          className={clsx(
            'w-full h-14 rounded-lg flex relative',
            hasFloatingLabel
              ? 'appearance-none focus:outline-none bg-transparent'
              : 'bg-white',
            hasError && error
              ? ' bg-fieldError'
              : 'text-darkGrey focus:border-primary'
          )}
        >
          <input
            className={clsx(
              hasFloatingLabel ? 'px-4 pt-3.5' : 'p-2.5 ',
              'w-full h-14 outline-none rounded-lg bg-transparent overflow-ellipsis',
              isDisabled
                ? hasError && error
                  ? 'border-error bg-fieldError text-error'
                  : 'border-input bg-lightGrey text-darkGrey'
                : ''
            )}
            name={name}
            placeholder={
              hasFloatingLabel
                ? ' ' // We need a placeholder to check if input has value for floating label
                : placeholderTranslationId &&
                  intl.formatMessage({ id: placeholderTranslationId })
            }
            value={value}
            disabled={isDisabled}
            onChange={(event) => {
              setHasError(false);

              return onChange?.(event.target.value);
            }}
          />
          {hasFloatingLabel && (
            <label
              htmlFor={name}
              className={clsx(
                'absolute top-1/3 left-4 duration-300 origin-0 text-formInput',
                hasError && error ? 'text-error' : 'text-floatingLabel'
              )}
            >
              <TranslatedMarkupText id={placeholderTranslationId} />
            </label>
          )}
          {onDelete && (
            <div className="flex justify-center h-full items-center mr-2">
              {value?.length !== 0 && (
                <img
                  src={CrossIcon}
                  alt="CrossIcon"
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete();
                  }}
                />
              )}
            </div>
          )}
        </button>
      </div>
      {error && hasError && (
        <p className="text-error text-sm mt-1">
          <TranslatedMarkupText id={`formValidation.${error as string}`} />
        </p>
      )}
    </div>
  );
};

export default InputTextButton;
