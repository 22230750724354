import React from 'react';
import BasicToast from '../BasicToast/BasicToast';
import WarningIcon from '../../assets/icons/warning.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ErrorToast: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <BasicToast
      isOpen={isOpen}
      onClose={onClose}
      titleTranslationId="api.error-toast.error-occurred"
      messageTranslationId="api.error-toast.please-try-again"
      icon={WarningIcon}
    />
  );
};

export default ErrorToast;
