import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Dialog, Transition } from '@headlessui/react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import SidebarMenuLink from '../SidebarMenuLink/SidebarMenuLink';
import SidebarMenuAvatar from '../SidebarMenuAvatar/SidebarMenuAvatar';
import { useFetchCurrentUser } from '../../services/hooks/useFetchCurrentUser';
import AlgaeLogo from '../../assets/images/logo.png';
import { PATH } from '../../constants/path.const';
import { getInternationalizedPath } from '../../services/helpers/getInternationalizedPath';
import { isCurrentPathname } from '../../services/helpers/isCurrentPathname';
import LoginIcon from '../../assets/icons/person.svg';
import ProjectIcon from '../../assets/icons/suitcase.svg';
import AddProjectIcon from '../../assets/icons/rocket.svg';
import CrossIcon from '../Icons/CrossIcon/CrossIcon';
import PersonIcon from '../../assets/icons/person.svg';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import { signOut } from '../../services/networking/firebase';
import PowerIcon from '../../assets/icons/power.svg';
import MenuLink from '../MenuLink/MenuLink';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';
import KYCDocumentsIcon from '../../assets/icons/shield-checkmark.svg';
import LanguageSelect from '../LanguageSelect/LanguageSelect';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SidebarMenu: React.FC<Props> = ({ isOpen, onClose }) => {
  const intl = useIntl();
  const [navigation, setNavigation] = useState([
    {
      name: '',
      href: '',
      icon: '',
      path: '',
    },
  ]);
  const [isDisconnected, setisDisconnected] = useState(false);

  const navigation_visitor = [
    {
      name: intl.formatMessage({
        id: `sidebar-menu.navigation.${PATH.PROJECTS}`,
      }),
      href: getInternationalizedPath(PATH.PROJECTS, intl),
      icon: ProjectIcon,
      path: PATH.PROJECTS,
    },
    {
      name: intl.formatMessage({
        id: `sidebar-menu.navigation.${PATH.REGISTER}`,
      }),
      href: getInternationalizedPath(PATH.REGISTER, intl),
      icon: PersonIcon,
      path: PATH.REGISTER,
    },
  ];

  const navigation_logged_in = [
    {
      name: intl.formatMessage({
        id: `sidebar-menu.navigation.${PATH.PROJECTS}`,
      }),
      href: getInternationalizedPath(PATH.PROJECTS, intl),
      icon: ProjectIcon,
      path: PATH.PROJECTS,
    },
    {
      name: intl.formatMessage({
        id: `sidebar-menu.navigation.${PATH.ACCOUNT}`,
      }),
      href: getInternationalizedPath(PATH.ACCOUNT, intl),
      icon: PersonIcon,
      path: PATH.ACCOUNT,
    },
    // {
    //   name: intl.formatMessage({
    //     id: `sidebar-menu.navigation.${PATH.PROJECT_FORM}`,
    //   }),
    //   href: getInternationalizedPath(PATH.PROJECT_FORM, intl),
    //   icon: AddProjectIcon,
    //   path: PATH.PROJECT_FORM,
    // },
    // {
    //   name: intl.formatMessage({
    //     id: `sidebar-menu.navigation.${PATH.KYC_DOCUMENTS}`,
    //   }),
    //   href: getInternationalizedPath(PATH.KYC_DOCUMENTS, intl),
    //   icon: KYCDocumentsIcon,
    //   path: PATH.KYC_DOCUMENTS,
    // },
  ];

  const loginMenuLink = {
    name: intl.formatMessage({
      id: `sidebar-menu.navigation.${PATH.LOGIN}`,
    }),
    href: getInternationalizedPath(PATH.LOGIN, intl),
    icon: LoginIcon,
    path: PATH.LOGIN,
  };

  const { data: user, isLoading } = useFetchCurrentUser();

  const router = useIntlRouter();

  useEffect(() => {
    getAuth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setNavigation(navigation_logged_in);
        setisDisconnected(false);
      } else {
        setNavigation(navigation_visitor);
        setisDisconnected(true);
      }
    });
  }, [user]);

  return (
    <div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 " onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-10 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className=" ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={onClose}
                  >
                    <CrossIcon color="#FFFFFF" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-auto w-24 mt-10"
                    src={AlgaeLogo}
                    alt={intl.formatMessage({ id: 'alts.algae' })}
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((menuLink) => (
                    <SidebarMenuLink
                      key={menuLink.name}
                      menuLink={menuLink}
                      isCurrentActiveLink={isCurrentPathname(
                        menuLink.path,
                        router
                      )}
                    />
                  ))}
                  <LanguageSelect />
                </nav>
              </div>
              <div
                className={clsx(
                  'hover:bg-gray-50 hover:text-gray-900 group flex menuLinks-center p-4 text-base font-medium'
                )}
              >
                {!isDisconnected && (
                  <button
                    className="flex flex-row items-center w-full text-left text-darkGrey"
                    onClick={() => {
                      void signOut();
                      router.push(PATH.LOGIN);
                      void onClose();
                    }}
                  >
                    <img
                      className={clsx(
                        'text-gray-400 group-hover:text-gray-500',
                        'mr-4 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                      src={PowerIcon}
                      alt=""
                    />
                    <TranslatedMarkupText id="sidebar-menu.sign-out" />
                  </button>
                )}
                {isDisconnected && <MenuLink menuLink={loginMenuLink} />}
              </div>

              {!isDisconnected && (
                <SidebarMenuAvatar user={user} isLoading={isLoading} />
              )}
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0"
        id="desktop"
      >
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-8 w-auto"
                src={AlgaeLogo}
                alt={intl.formatMessage({ id: 'alts.algae' })}
              />
            </div>
            <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
              {navigation.map((menuLink) => (
                <SidebarMenuLink
                  key={menuLink.name}
                  menuLink={menuLink}
                  isCurrentActiveLink={isCurrentPathname(menuLink.path, router)}
                />
              ))}
            </nav>
          </div>
          {!isDisconnected && (
            <SidebarMenuAvatar user={user} isLoading={isLoading} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
