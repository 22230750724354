import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { FormikErrors, getIn } from 'formik';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  labelTranslationId: string;
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  italicLabelTranslationId?: string;
  placeholderTranslationId?: string;
  maxLength?: number;
  errors?: FormikErrors<{ [field: string]: unknown }>;
  hasFloatingLabel?: boolean;
  minRows?: number;
  maxRows?: number;
}

const INPUT_DEFAULT_MAX_LENGTH = 650;
const TEXT_AREA_LINE_HEIGHT = 24;

const InputTextArea: React.FC<Props> = ({
  labelTranslationId,
  name,
  value,
  onChange,
  italicLabelTranslationId,
  placeholderTranslationId,
  maxLength = INPUT_DEFAULT_MAX_LENGTH,
  errors,
  hasFloatingLabel = false,
  minRows = 5,
  maxRows = 20,
}) => {
  const intl = useIntl();
  const error = useMemo(() => getIn(errors, name), [errors, name]);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error]);

  const [rowNumber, setRowNumber] = useState(minRows);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <>
      {labelTranslationId && !hasFloatingLabel && (
        <h3 className={clsx('text-primary text-l font-normal mb-1')}>
          <TranslatedMarkupText id={labelTranslationId} />
          {italicLabelTranslationId && (
            <>
              <span className="italic">
                <TranslatedMarkupText id={italicLabelTranslationId} />
              </span>
            </>
          )}
        </h3>
      )}
      <div>
        <textarea
          ref={textAreaRef}
          maxLength={maxLength}
          rows={rowNumber}
          name={name}
          value={value}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
            const previousRows = event.target.rows;

            event.target.rows = minRows;

            const currentRows = ~~(
              event.target.scrollHeight / TEXT_AREA_LINE_HEIGHT
            );
            if (currentRows === previousRows) {
              event.target.rows = currentRows;
            }
            if (currentRows >= maxRows) {
              event.target.rows = maxRows;
              event.target.scrollTop = event.target.scrollHeight;
            }

            setRowNumber(currentRows < maxRows ? currentRows : maxRows);

            onChange(event);
            setHasError(false);
          }}
          className={clsx(
            'w-full text-darkGrey p-4 text-formInput min-h-inputTextArea overflow-y-auto leading-6 resize-none focus:outline-none',
            hasError && error
              ? 'bg-fieldError border border-error rounded-lg'
              : 'border border-input rounded-lg bg-lightGrey'
          )}
          placeholder={
            placeholderTranslationId &&
            intl.formatMessage({ id: placeholderTranslationId })
          }
        ></textarea>
        {error && hasError && (
          <p className="text-error text-sm mt-1">
            <TranslatedMarkupText id={`formValidation.${error as string}`} />
          </p>
        )}
      </div>
    </>
  );
};

export default InputTextArea;
