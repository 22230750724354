import { useEffect, useState } from 'react';
import { fetchGooglePlacesAutocomplete } from '../helpers/fetchGooglePlacesAutocomplete';

export const useAddressAutocomplete = (
  queryValue: string,
  divRef: HTMLDivElement | null
): google.maps.places.PlaceResult[] => {
  const [autocompleteResults, setAutocompleteResults] = useState<
    google.maps.places.PlaceResult[]
  >([]);

  useEffect(() => {
    const fetchAddressAutocomplete = async (): Promise<void> => {
      const autocomplete = await fetchGooglePlacesAutocomplete(
        queryValue,
        divRef
      );

      if (
        autocomplete?.status === google.maps.places.PlacesServiceStatus.OK &&
        autocomplete.results
      ) {
        setAutocompleteResults(autocomplete.results);
      }
    };

    void fetchAddressAutocomplete();
  }, [queryValue, divRef]);

  return autocompleteResults;
};
