import React from "react";
import { Listing } from "../../models/Listing";
import TranslatedMarkupText from "../TranslatedMarkupText/TranslatedMarkupText";
import BasicButton from "../BasicButton/BasicButton";

interface Props {
  listing: Listing;
  onInvest: (shareAmount: number) => void;
}

const ListingCard: React.FC<Props> = ({ listing, onInvest }) => {
  return (
    <div className="bg-white p-4 mb-4 rounded-lg shadow-md border border-gray-200">
      <div className="flex justify-between items-center mb-2">
        <span className="text-lg font-semibold">Share Details</span>
        <span
          className={`px-2 py-1 rounded-full text-sm ${
            listing.onSale === 1
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          }`}
        >
          {listing.onSale === 1 ? "On Sale" : "Not on Sale"}
        </span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Amount:</span>
        <span className="font-medium">{listing.shareAmount}</span>
      </div>
      <div className="flex justify-between items-center mt-2">
        <span className="text-gray-600">Price per share:</span>
        <span className="font-medium">{listing.sharePrice}€</span>
      </div>
      <div className="flex justify-between items-center mt-2">
        <span className="text-gray-600">Total price:</span>
        <span className="font-medium">
          {listing.shareAmount * listing.sharePrice}€
        </span>
      </div>
      <div className="flex justify-center mt-4 rounded bg-green-300">
        <BasicButton
          buttonStyle="green"
          className="rounded flex items-center text-white text-base font-semibold"
          translationId="listing-card.invest"
          onClick={() => onInvest(listing.shareAmount)}
          isDisabled={listing.onSale !== 1}
        />
      </div>
    </div>
  );
};

export default ListingCard;
