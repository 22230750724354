export enum GOOGLE_PLACE_DETAILS_FIELD_KEY {
  LONG_NAME = 'LONG_NAME',
  SHORT_NAME = 'SHORT_NAME',
}

export const getGooglePlaceDetailsFieldValue = (
  placeDetails: google.maps.places.PlaceResult,
  fieldName: string,
  fieldKey: GOOGLE_PLACE_DETAILS_FIELD_KEY
): string => {
  switch (fieldKey) {
    case GOOGLE_PLACE_DETAILS_FIELD_KEY.LONG_NAME:
      return (
        placeDetails.address_components?.find((addressComponent) =>
          addressComponent.types.includes(fieldName)
        )?.long_name ?? ''
      );
    case GOOGLE_PLACE_DETAILS_FIELD_KEY.SHORT_NAME:
      return (
        placeDetails.address_components?.find((addressComponent) =>
          addressComponent.types.includes(fieldName)
        )?.short_name ?? ''
      );
  }
};
