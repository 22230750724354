import { IonApp, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { IntlProvider } from 'react-intl';
import { createContext, useEffect, useState } from 'react';
import flattenMessages, {
  FlattenedDictionary,
} from './services/intl/flattenMessages';
import messages_en from './translations/en.json';
import messages_fr from './translations/fr.json';
import { enLocale, frLocale } from './constants/intl.const';
import Routes from './Routes';
import {
  defaultContextValue,
  FilterContext,
} from './services/context/projectFilterContext';
import { IFilterContext } from './models/IFilterContext';

// Ionic v6, we need to call this even without specifying custom config
setupIonicReact({});

export const LanguageContext = createContext({
  applicationLocale: enLocale,
  setApplicationLocale: (value: string) => {
    return;
  },
});

const App: React.FC = () => {
  const [applicationLocale, setApplicationLocale] = useState(
    localStorage.getItem('locale') ?? enLocale
  );
  const [messages, setMessages] = useState<FlattenedDictionary | undefined>();
  const [filterContext, setFilterContext] = useState(defaultContextValue);

  useEffect(() => {
    if (applicationLocale) {
      switch (applicationLocale) {
        case enLocale:
          setMessages(flattenMessages({ ...messages_en }));
          break;
        case frLocale:
          setMessages(flattenMessages({ ...messages_fr }));
          break;
      }
    }
  }, [applicationLocale]);

  return (
    <>
      {applicationLocale && messages && (
        <FilterContext.Provider
          value={{
            filterContext: filterContext,
            setFilterContext: setFilterContext,
          }}
        >
          <LanguageContext.Provider
            value={{
              applicationLocale: applicationLocale,
              setApplicationLocale: setApplicationLocale,
            }}
          >
            <IntlProvider locale={applicationLocale} messages={messages}>
              <IonApp>
                <Routes />
              </IonApp>
            </IntlProvider>
          </LanguageContext.Provider>
        </FilterContext.Provider>
      )}
    </>
  );
};

export default App;
