import clsx from 'clsx';
import React from 'react';
import CrossIcon from '../../assets/icons/cross.svg';

interface Props {
  images: { src: string; fileId?: string }[];
  additionalImageClassName?: string;
  onClickDeleteImage?: (fileId: string) => void;
  isHorizontalScroll?: boolean;
}

const ImageList: React.FC<Props> = ({
  images,
  additionalImageClassName,
  onClickDeleteImage,
  isHorizontalScroll = false,
}) => {
  return (
    <div
      className={clsx(
        isHorizontalScroll
          ? 'overflow-y-scroll overflow-visible whitespace-nowrap mb-2'
          : 'mb-1'
      )}
    >
      {images.map((image) => (
        <div className={clsx('inline-block relative')} key={image.fileId}>
          <img
            className={clsx(
              'relative mr-2 last:mr-0',
              !additionalImageClassName
                ? 'object-scale-down h-52'
                : additionalImageClassName
            )}
            src={image.src}
            alt=""
          />
          {onClickDeleteImage && (
            <img
              className={'absolute top-1 right-2 bg-white rounded-full'}
              src={CrossIcon}
              alt="cross"
              onClick={() => image.fileId && onClickDeleteImage(image.fileId)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ImageList;
