import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Share } from '@capacitor/share';
import {
  POST_SUCCESS_STATUS,
  SUCCESS_STATUS,
} from '../../services/networking/apiClient';
import BasicToast from '../BasicToast/BasicToast';
import ErrorToast from '../ErrorToast/ErrorToast';
import { Project } from '../../models/Project';
import shareIcon from '../../assets/icons/share.svg';

interface Props {
  project: Project;
}

const ShareButton: React.FC<Props> = ({ project }) => {
  const intl = useIntl();
  const [isProjectSharingToastOpen, setIsProjectSharingToastOpen] =
    useState(false);
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);

  const locale = localStorage.getItem('locale');
  const splitLocale = locale?.split('-')[0] as string;

  const isSharing = async (): Promise<void> => {
    const baseUrl = 'https://app.algae-app.com/';
    const urlToShare = `${baseUrl}/${splitLocale}/${project.id}`;

    try {
      await Share.share({
        title: intl.formatMessage({ id: 'sharing-project.title' }),
        dialogTitle: intl.formatMessage({ id: 'sharing-project.title' }),
        text: intl.formatMessage({ id: 'sharing-project.message' }),
        url: urlToShare,
      });

      setIsProjectSharingToastOpen(true);
    } catch (error) {
      console.log('erreur : ', error);
      setIsErrorToastOpen(true);
    }
  };

  return (
    <>
      <BasicToast
        isOpen={isProjectSharingToastOpen}
        onClose={() => setIsProjectSharingToastOpen(false)}
        titleTranslationId="sharing-project.share-toast.title"
        messageTranslationId="sharing-project.share-toast.message"
      />
      <ErrorToast
        isOpen={isErrorToastOpen}
        onClose={() => setIsErrorToastOpen(false)}
      />
      <button
        className="rounded-full bg-gray-100 p-2"
        onClick={() => void isSharing()}
      >
        <img
          src={shareIcon}
          alt={intl.formatMessage({ id: 'alts.share-project' })}
        />
      </button>
    </>
  );
};

export default ShareButton;
