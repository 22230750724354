import React, { useRef, useState } from 'react';
import BasicButton from '../BasicButton/BasicButton';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import { useFetchCurrentUserPaymentCards } from '../../services/hooks/useFetchCurrentUserPaymentCards';
import PaymentCardFormModal from '../Forms/PaymentCardFormModal/PaymentCardFormModal';

const PaymentCardList: React.FC = () => {
  const {
    data: paymentCards,
    isLoading,
    refetch: refetchCurrentUserPaymentCards,
  } = useFetchCurrentUserPaymentCards();
  const [isPaymentCardFormModalOpen, setIsPaymentCardFormModalOpen] =
    useState(false);

  const presentingElement = useRef<HTMLIonModalElement>(
    document.createElement('ion-modal')
  );

  return (
    <>
      <PaymentCardFormModal
        isOpen={isPaymentCardFormModalOpen}
        onClose={() => void setIsPaymentCardFormModalOpen(false)}
        refetchPaymentCards={() => void refetchCurrentUserPaymentCards()}
        ionicPresentingElement={presentingElement.current}
      />
      {!isLoading && (!paymentCards || paymentCards.length === 0) && (
        <div className="p-5">
          <h1 className="text-sm text-center text-mediumGrey">
            <TranslatedMarkupText id="payment-card-list.no-payment-methods" />
          </h1>
        </div>
      )}
      {!isLoading && paymentCards && (
        <div className="divide-y divide-gray-200">
          {paymentCards.map((paymentCard, index) => (
            <div key={index} className="relative flex items-start py-4">
              <label
                htmlFor={`${paymentCard.Id}`}
                className="min-w-0 flex-1 text-sm"
              >
                <label className="font-medium text-gray-700">
                  <div>{paymentCard.Tag}</div>
                  <div>{paymentCard.CardType}</div>
                </label>
                <p
                  id={`account-${paymentCard.Id}-description`}
                  className="text-gray-500"
                >
                  {paymentCard.Alias}
                </p>
              </label>
            </div>
          ))}
        </div>
      )}
      <div className="my-5 w-full">
        <BasicButton
          className="w-full"
          translationId="payment-card-list.add-payment-method"
          onClick={() => setIsPaymentCardFormModalOpen(true)}
        />
      </div>
    </>
  );
};

export default PaymentCardList;
