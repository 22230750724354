import React, { createContext } from 'react';
import { IFilterContext } from '../../models/IFilterContext';

export const defaultContextValue: IFilterContext = {
  city: [],
  status: [],
};
export const FilterContext = createContext({
  filterContext: defaultContextValue,
  setFilterContext: (value: IFilterContext) => {
    return;
  },
});
