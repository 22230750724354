import clsx from 'clsx';
import { useEffect } from 'react';

interface Props {
  isOpen: boolean;
  isScreenHeight?: boolean;
  containerClassname?: string;
}

const ModalLayout: React.FC<Props> = ({
  isOpen,
  children,
  containerClassname,
  isScreenHeight = false,
}) => {
  useEffect(() => {
    // This blocks the body content from scrolling under the modal
    if (isOpen) {
      window.scrollTo({ top: 0, left: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <div
      className={clsx(
        isOpen
          ? 'visible pointer-events-auto'
          : 'invisible pointer-events-none',
        'absolute flex flex-col items-center left-0 top-0 right-0 bottom-0 h-full transition duration-100 ease-linear overflow-hidden bg-frenchGrey bg-opacity-60 z-50'
      )}
    >
      <div
        className={clsx(
          'max-w-modal relative transition duration-400 ease-linear',
          isOpen ? 'top-1/3 transform -translate-y-1/3' : 'top-full',
          isScreenHeight && 'h-screen',
          containerClassname ?? 'w-full px-8 py-10 bg-white'
        )}
      >
        <>{children}</>
      </div>
    </div>
  );
};

export default ModalLayout;
