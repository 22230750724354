import { FormikErrors, getIn } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Select, {
  components,
  PlaceholderProps,
  ValueContainerProps,
} from 'react-select';
import countryList from 'react-select-country-list';
import { CountryIso } from '../../../models/LegalOwner';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';

const { ValueContainer, Placeholder } = components;

type CustomValueContainerProps = ValueContainerProps<{
  label: string;
  value: string;
}> &
  PlaceholderProps<{ label: string; value: string }>;

const CustomValueContainer: React.FC<CustomValueContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child &&
        'type' in (child as React.ReactElement) &&
        (child as React.ReactElement).type !== Placeholder
          ? child
          : null
      )}
    </ValueContainer>
  );
};

interface Props {
  placeholderTranslationId: string;
  onChange: (
    selectedOption: { value: string; label: string } | null,
    index?: number
  ) => void;
  name: string;
  errors?: FormikErrors<unknown>;
}

const CountryInputSelect: React.FC<Props> = ({
  placeholderTranslationId,
  onChange,
  name,
  errors,
}) => {
  const error = useMemo(() => name && getIn(errors, name), [errors, name]);
  const [hasError, setHasError] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error]);

  const options = useMemo(() => countryList().getData(), []);

  return (
    <>
      <Select
        isMulti={false}
        defaultValue={{ value: CountryIso.FR as string, label: 'France' }}
        name={name}
        options={options}
        isClearable
        isSearchable={false}
        components={{
          ValueContainer: CustomValueContainer as typeof ValueContainer,
        }}
        placeholder={intl.formatMessage({
          id: placeholderTranslationId,
        })}
        styles={{
          container: (provided, state) => ({
            ...provided,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: 'visible',
            height: '56px',
            backgroundColor: hasError && error ? '#FFF7FA' : '#F9FAFA',
            position: 'relative',
            paddingLeft: '16px',
            borderRadius: '8px',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            position: 'absolute',
            bottom: '5%',
            fontSize: (state.hasValue || state.selectProps.inputValue) && 17,
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            top: state.hasValue || state.selectProps.inputValue ? '15%' : '30%',
            transition: '0.3s',
            fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 17,
            transformOrigin: '0%',
            color: hasError && error ? '#D42338' : '#505F79',
          }),
          control: (provided, state) => ({
            ...provided,
            backgroundColor: '#F9FAFA',
            border: `1px solid ${hasError && error ? '#D42338' : '#DFE1E6'}`,
            boxShadow: state.isFocused ? 'none' : 'none',
            borderRadius: '8px',
          }),
          indicatorSeparator: (provided, state) => ({
            display: 'none',
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            color: hasError && error ? '#D42338' : provided.color,
          }),
        }}
        onChange={(value) => {
          setHasError(false);
          onChange(value);
        }}
      />
      {error && hasError && (
        <p className="text-error text-sm mt-1">
          <TranslatedMarkupText id={`formValidation.${error as string}`} />
        </p>
      )}
    </>
  );
};

export default CountryInputSelect;
