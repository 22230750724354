import {
  FORBIDDEN,
  PAYMENT_ERROR,
  UNAUTHORIZED,
} from '../networking/apiClient';

export const handleApiResponseStatus = <
  Success,
  Error extends { statusCode: number }
>(
  response: Success | Error
): Success => {
  const isSuccess = (res: Error | Success): res is Success => {
    return !(res as Error).statusCode;
  };

  if (isSuccess(response)) {
    return response;
  }

  switch (response.statusCode) {
    case FORBIDDEN:
      throw new Error('FORBIDDEN');
    case PAYMENT_ERROR:
      throw new Error('PAYMENT_ERROR');
    case UNAUTHORIZED:
      throw new Error('UNAUTHORIZED');
  }

  throw new Error('FETCH_ERROR');
};
