import { IFilterContext } from '../../models/IFilterContext';

export const formatQueryFilter = (context: IFilterContext): string => {
  const criterias: string[] = [];
  let queryString = '';

  //Format criterias
  Object.keys(context).map((filter) => {
    (context as any)[filter].forEach((item: string) => {
      criterias.push(`${filter}=${item}`);
    });
  });
  queryString = '?' + criterias.join('&');

  return queryString;
};
