import React, { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import CrossIcon from '../Icons/CrossIcon/CrossIcon';
import asModal from '../../services/hoc/asModal';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  titleTranslationId?: string;
  messageTranslationId?: string;
  icon?: string;
}

const BasicToast: React.FC<Props> = ({
  isOpen,
  onClose,
  titleTranslationId,
  messageTranslationId,
  icon,
}) => {
  useEffect(() => {
    isOpen &&
      setTimeout(() => {
        onClose();
      }, 5000);
  }, [isOpen, onClose]);

  return (
    <div
      aria-live="assertive"
      className="fixed top-0 right-0 left-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end pointer-events-none">
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                {icon && (
                  <div className="my-auto">
                    <img
                      className="h-8 w-8"
                      aria-hidden="true"
                      src={icon}
                      alt=""
                    />
                  </div>
                )}
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  {titleTranslationId && (
                    <p className="text-sm font-medium text-gray-900">
                      <TranslatedMarkupText id={titleTranslationId} />
                    </p>
                  )}
                  {messageTranslationId && (
                    <p className="mt-1 text-sm text-gray-500">
                      <TranslatedMarkupText id={messageTranslationId} />
                    </p>
                  )}
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={(event) => {
                      onClose();
                      event.stopPropagation();
                    }}
                  >
                    <span className="h-5 w-5">
                      <CrossIcon aria-hidden="true" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default asModal(BasicToast);
