import { useQuery } from 'react-query';
import { MangopayKYCDocumentStatus } from '../../models/KYCDocument';
import { getCurrentUserKYCDocumentsByStatus } from '../networking/apiClient';

export const useFetchCurrentUserKYCDocumentsByStatus = (
  mangopayKYCDocumentStatus: MangopayKYCDocumentStatus
) => {
  const { data, isError, error, isLoading, refetch } = useQuery(
    'getCurrentUserKYCDocuments',
    () => getCurrentUserKYCDocumentsByStatus(mangopayKYCDocumentStatus),
    {
      retry: false,
    }
  );

  return { data, isError, error, isLoading, refetch };
};
