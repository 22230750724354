import { useIntl } from 'react-intl';
import Loader from '../../Loader/Loader';

const LOADER_COLOR = 'blue';
const LOADER_WIDTH = 24;
const LOADER_HEIGHT = 24;

const FileUploadLoadingBar: React.FC = () => {
  const intl = useIntl();

  return (
    <div
      className={
        'flex flex-row h-10 items-center text-xs font-medium mb-2 bg-lightGrey text-mediumGrey'
      }
    >
      <div className={'p-2'}>
        <Loader
          color={LOADER_COLOR}
          width={LOADER_WIDTH}
          height={LOADER_HEIGHT}
        />
      </div>

      {intl.formatMessage({ id: 'fileUploadLoading' })}
    </div>
  );
};

export default FileUploadLoadingBar;
