import { useEffect, useState } from 'react';
import { getAuth, User } from 'firebase/auth';

export const useAuth = (): {
  isSignedIn: boolean;
  pending: boolean;
  user: User | null;
} => {
  const [authState, setAuthState] = useState<{
    isSignedIn: boolean;
    pending: boolean;
    user: User | null;
  }>({
    isSignedIn: false,
    pending: true,
    user: null,
  });
  const firebaseAuth = getAuth();

  useEffect(() => {
    const unregisterAuthObserver = firebaseAuth.onAuthStateChanged(
      (user: User | null) =>
        setAuthState({ user, pending: false, isSignedIn: !!user })
    );

    return () => unregisterAuthObserver();
  }, [firebaseAuth]);

  return { ...authState };
};
