import React from 'react';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  titleTranslationId: string;
  messageTranslationId: string;
  icon: string;
  titleBoldValue?: string;
}

const StackedIconAndText: React.FC<Props> = ({
  titleTranslationId,
  messageTranslationId,
  icon,
  titleBoldValue,
}) => {
  return (
    <div className="flex">
      <div className="flex-shrink-0">
        <img className="h-5 w-5" src={icon} alt="" />
      </div>
      <div className="ml-3">
        <h3 className="text-l">
          <TranslatedMarkupText
            id={titleTranslationId}
            values={{ titleBoldValue }}
          />
        </h3>
        <div className="mt-2 text-base">
          <p>
            <TranslatedMarkupText id={messageTranslationId} />
          </p>
        </div>
      </div>
    </div>
  );
};
export default StackedIconAndText;
