import { useMutation, useQueryClient } from "react-query";
import { postNewListing } from "../networking/apiClient";
import { Listing } from "../../models/Listing";

export const useCreateListing = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (newListing: Omit<Listing, "id">) => postNewListing(newListing),
    {
      onSuccess: () => {
        // Invalidate and refetch relevant queries
        queryClient.invalidateQueries("projectListings");
        queryClient.invalidateQueries("userListings");
      },
    }
  );
};
