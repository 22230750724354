import { FormikErrors } from 'formik';
import React, { useState } from 'react';
import ModalAddressAutocomplete from '../ModalAddressAutocomplete/ModalAddressAutocomplete';
import { Address } from '../../../models/Address';
import InputTextButton from '../InputTextButton/InputTextButton';

interface Props {
  value: string | undefined;
  errors?: FormikErrors<{ [field: string]: unknown }>;
  placeholderTranslationId?: string;
  hasFloatingLabel?: boolean;
  name: string;
  onChange: (value: Address | null) => void;
  onTextValueChange?: (value: string) => void;
  onDelete?: () => void;
}

const InputAddressAutocomplete: React.FC<Props> = ({
  value,
  name,
  onChange,
  onTextValueChange,
  placeholderTranslationId,
  errors,
  hasFloatingLabel = false,
  onDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ModalAddressAutocomplete
        onChange={onChange}
        isOpen={isModalOpen}
        onClose={(): void => setIsModalOpen(false)}
        placeholderTranslationId={placeholderTranslationId}
      />
      <InputTextButton
        placeholderTranslationId={placeholderTranslationId}
        name={name}
        value={value}
        onDelete={onDelete}
        errors={errors}
        hasFloatingLabel={hasFloatingLabel}
        onChange={onTextValueChange}
        onClick={(): void => setIsModalOpen(true)}
        isDisabled
      />
    </>
  );
};

export default InputAddressAutocomplete;
