import React from 'react';
import DangerIcon from '../../assets/icons/danger.svg';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  translationId: string;
}

const AuthWarning: React.FC<Props> = ({ translationId }) => {
  return (
    <div className="flex items-center bg-warningLight w-full p-4">
      <img className="mr-4" src={DangerIcon} alt="" />
      <p className="text-primaryDark text-sm">
        <TranslatedMarkupText id={translationId} />
      </p>
    </div>
  );
};

export default AuthWarning;
