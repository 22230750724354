import React from 'react';
import BasicButton from '../BasicButton/BasicButton';
import ModalLayout from '../ModalLayout/ModalLayout';

import GlitterIcon from '../../assets/icons/glitter.svg';
import CrossIcon from '../Icons/CrossIcon/CrossIcon';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import asModal from '../../services/hoc/asModal';

interface Props {
  isOpen: boolean;
  titleTranslationId: string;
  titleValues: { [field: string]: string };
  descriptionTranslationId: string;
  icon: string;
  buttonTranslationId: string;
  onClick?: () => void;
  onClose?: () => void;
}

const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  titleTranslationId,
  descriptionTranslationId,
  icon,
  buttonTranslationId,
  onClick,
  onClose,
  titleValues,
}) => {
  return (
    <ModalLayout
      isOpen={isOpen}
      containerClassname="bg-white w-9/12 rounded-xl p-3"
    >
      <div>
        <div className="flex w-full justify-end mr-2">
          <div aria-hidden="true" onClick={onClose}>
            <CrossIcon />
          </div>
        </div>
        <div className="flex w-full h-full justify-center">
          <img
            src={GlitterIcon}
            alt="glitter-effect"
            className="absolute top-20 left-24"
          />
          <div className="flex justify-center items-center mt-5 w-24 h-24 bg-lightGreen rounded-full">
            <img src={icon} alt="house-certification" />
          </div>
        </div>
        <div className="mt-8 text-center font-semibold text-2xl p-3">
          <p>
            <TranslatedMarkupText
              id={titleTranslationId}
              values={titleValues}
            />
          </p>
        </div>
        <div className="mt-1 p-3 text-center text-gray-500">
          <p>
            <TranslatedMarkupText id={descriptionTranslationId} />
          </p>
        </div>
        <BasicButton
          className="mt-10 mb-1 w-full text-center rounded-md text-black"
          translationId={buttonTranslationId}
          onClick={onClick}
        />
      </div>
    </ModalLayout>
  );
};

export default asModal(ConfirmationModal);
