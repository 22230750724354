import React from 'react';
import clsx from 'clsx';
import Loader from '../Loader/Loader';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

const LOADER_HEIGHT = 21;
const LOADER_WIDTH = 21;

interface Props {
  onClick?: () => void;
  translationId: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  iconPosition?: 'left' | 'right';
  iconUrl?: string;
  iconAlt?: string;
  buttonStyle?: string;
  type?: 'submit' | 'button';
  className?: string;
}

const BasicButton: React.FC<Props> = ({
  onClick,
  translationId,
  iconUrl,
  iconPosition,
  iconAlt,
  isLoading = false,
  isDisabled = false,
  buttonStyle = 'primary',
  type = 'button',
  className,
}) => {

  return (
    <button
      className={clsx(
        `disabled:text-sm font-medium ${
          isDisabled ? 'bg-gray-400 opacity-50' : ''
        }`,
        'py-2.5 px-6 flex justify-center',
        buttonStyle === 'green'
          ? 'bg-green text-white border-green'
          : 'bg-primary rounded-md text-black text-md font-semibold p-1 border border-primary',
        className
      )}
      onClick={onClick}
      disabled={isDisabled}
      type={type}
    >
      {iconPosition === 'left' && (
        <img
          src={iconUrl}
          className={clsx('mr-2', isLoading && 'opacity-0')}
          alt={iconAlt}
        />
      )}
      <p
        className={clsx(
          iconPosition === 'right' && 'mr-2',
          isLoading && 'opacity-0'
        )}
      >
        <TranslatedMarkupText id={translationId} />
      </p>
      {iconPosition === 'right' && (
        <img
          src={iconUrl}
          className={clsx(isLoading && 'opacity-0')}
          alt={iconAlt}
        />
      )}
      {isLoading && (
        <span className="absolute">
          <Loader color="white" height={LOADER_HEIGHT} width={LOADER_WIDTH} />
        </span>
      )}
    </button>
  );
};

export default BasicButton;
