import { IonContent, IonHeader, IonModal } from '@ionic/react';
import React, { useContext } from 'react';
import { PageContext } from '../Layout/Layout';

export interface SwipeableModalProps {
  isOpen: boolean;
  onClose?: () => void;
  ionicPresentingElement?: HTMLIonModalElement;
}
interface Props extends SwipeableModalProps {
  header?: React.ReactNode;
}

const SwipeableModalLayout: React.FC<Props> = ({
  isOpen,
  children,
  ionicPresentingElement,
  header,
}) => {
  const pageRefContext = useContext(PageContext);

  return (
    <IonModal
      isOpen={isOpen}
      canDismiss={true}
      // Presenting element is giving IonModal the element from where it was created
      // Allows IonModal page superposition animation
      // We can pass a custom presentingElement to stack up modals or let pageRefContext pass pageRef from Layout
      presentingElement={ionicPresentingElement ?? pageRefContext.pageRef}
    >
      <IonHeader>{header}</IonHeader>
      <IonContent className="relative rounded-t-2xl bg-transparent h-full">
        {children}
      </IonContent>
    </IonModal>
  );
};

export default SwipeableModalLayout;
