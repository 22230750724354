import { IonRouterLink, useIonRouter } from '@ionic/react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import BasicButton from '../../components/BasicButton/BasicButton';
import AuthWarning from '../../components/AuthWarning/AuthWarning';
import ControlledInputText from '../../components/Forms/ControlledInputText/ControlledInputText';
import Layout from '../../components/Layout/Layout';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import { PATH } from '../../constants/path.const';
import { SUCCESS_STATUS } from '../../services/networking/apiClient';
import { firebaseLogin } from '../../services/networking/firebase';
import { getInternationalizedPath } from '../../services/helpers/getInternationalizedPath';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';
import AlgaeLogo from '../../assets/images/logo.png';
import LanguageSelect from '../../components/LanguageSelect/LanguageSelect';

interface LoginFormData {
  email: string;
  password: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email('invalid-email').required('invalid-email'),
  password: Yup.string().required('invalid-password'),
});

const Login: React.FC = () => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(0);
  const router = useIonRouter();
  const intl = useIntl();

  return (
    <Layout isScreenHeight>
      <div className="flex flex-col h-screen pt-10">
        <div className="flex-shrink-0 flex-col w-full items-center text-center px-4 justify-center">
          <a href="/" className="block lg:w-1/2 lg:mx-auto">
            <img
              className="h-auto w-1/2 mx-auto"
              src={AlgaeLogo}
              alt={intl.formatMessage({ id: 'alts.algae' })}
            />
          </a>
          <div className="block">
            <LanguageSelect />
          </div>
        </div>
        <div className="bg-white flex-grow flex mx-auto items-center w-full max-w-form">
          <Formik
            initialValues={
              {
                email: '',
                password: '',
              } as LoginFormData
            }
            validationSchema={VALIDATION_SCHEMA}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values, actions) => {
              setIsSubmitLoading(true);
              const statusCode = await firebaseLogin({
                email: values.email,
                password: values.password,
              });
              actions.setSubmitting(false);
              setIsSubmitLoading(false);
              console.log(statusCode, statusCode === SUCCESS_STATUS);
              // router.
              if (statusCode === SUCCESS_STATUS) {
                router.push(PATH.PROJECTS);
              } else {
                setErrorStatus(statusCode);
              }
            }}
          >
            {(props) => (
              <form
                className="w-full"
                onSubmit={props.handleSubmit}
                action="..."
              >
                <div className="w-full px-4">
                  <div className="text-xl font-bold">
                    <TranslatedMarkupText id="login.title" />
                  </div>
                  {errorStatus > 0 && (
                    <div className="my-1">
                      <AuthWarning
                        translationId={`login.errors.${errorStatus}`}
                      />
                    </div>
                  )}
                  <div className="mt-2">
                    <ControlledInputText
                      value={props.values.email}
                      labelTranslationId={'login.email'}
                      placeholderTranslationId={'login.email-address'}
                      name="email"
                      onChange={props.handleChange}
                    />
                  </div>
                  <div className="mt-2">
                    <ControlledInputText
                      value={props.values.password}
                      labelTranslationId={'login.password'}
                      placeholderTranslationId={'login.password'}
                      name="password"
                      fieldType="password"
                      onChange={props.handleChange}
                    />
                  </div>

                  <div className="mt-4 flex flex-row-reverse justify-center mb-6">
                    <BasicButton
                      type="submit"
                      translationId="login.login"
                      onClick={() => {
                        props.setErrors({});
                        props.handleSubmit();
                      }}
                      isLoading={isSubmitLoading}
                      isDisabled={
                        props.values.email.length === 0 ||
                        props.values.password.length === 0
                      }
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="text-center text-md text-darkGrey pb-20">
          <div>
            <TranslatedMarkupText id="login.no-account" />{' '}
            <IonRouterLink
              href={getInternationalizedPath(PATH.REGISTER, intl)}
              className="text-primary font-semibold"
            >
              <TranslatedMarkupText id="login.register" />
            </IonRouterLink>
          </div>
          <IonRouterLink
            href={getInternationalizedPath(PATH.PROJECTS, intl)}
            className="block text-primary font-semibold mt-4"
          >
            <TranslatedMarkupText id="login.continue-without-account" />
          </IonRouterLink>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
