import { useQuery } from 'react-query';
import { getCurrentUser } from '../networking/apiClient';

export const useFetchCurrentUser = () => {
  const { data, isError, error, isLoading, refetch } = useQuery(
    'getCurrentUser',
    getCurrentUser,
    {
      retry: false,
    }
  );

  return { data, isError, error, isLoading, refetch };
};
