import React, { useMemo, useState } from 'react';
import BasicButton from '../BasicButton/BasicButton';
import ModalLayout from '../ModalLayout/ModalLayout';
import Warning from '../../assets/icons/warning.svg';

import GlitterIcon from '../../assets/icons/glitter.svg';
import CrossIcon from '../Icons/CrossIcon/CrossIcon';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import asModal from '../../services/hoc/asModal';
import { PATH } from '../../constants/path.const';
import { useDeleteCurrentUser } from '../../services/hooks/useDeleteCurrentUser';
import {
  deleteFirebaseCurrentUser,
  getFirebaseCurrentUserAccessToken,
} from '../../services/networking/firebase';
import ConfirmationModal from './ConfirmationModal';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';

interface Props {
  isOpen: boolean;
  //   titleTranslationId: string;
  //   titleValues: { [field: string]: string };
  //   descriptionTranslationId: string;
  //   icon: string;
  //   buttonTranslationId: string;
  //   onClick?: () => void;
  //   onClose?: () => void;
}

const DeleteAccountModal: React.FC<Props> = ({ isOpen }) => {
  const {
    mutate: deleteUser,
    isLoading: isLoadingForDeletingCurrentUser,
    isError: deletingUserIsError,
    error: deletingUserErrorMessage,
  } = useDeleteCurrentUser();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const router = useIntlRouter();

  const [isError, setIsError] = useState({
    errorTriggered: false,
    message: '',
    success: false,
  });

  useMemo(() => {
    setIsConfirmationModalOpen(isOpen);
  }, [isOpen]);

  const handleDeleteUserAccount = async () => {
    try {
      await deleteFirebaseCurrentUser();
      console.log('userDeleted');

      deleteUser();
      setIsError({ errorTriggered: false, message: '', success: true });

      const userAccessToken = await getFirebaseCurrentUserAccessToken();

      if (!isLoadingForDeletingCurrentUser) {
        if (deletingUserIsError || userAccessToken) {
          throw new Error();
        }

        setTimeout(() => {
          setIsConfirmationModalOpen(false);
          router.push(PATH.PROJECTS);
        }, 2000);
      }
    } catch (err: any) {
      setIsError({ errorTriggered: true, message: err, success: false });
    }
  };

  return (
    <ConfirmationModal
      isOpen={isConfirmationModalOpen}
      titleTranslationId={
        !isError.errorTriggered
          ? 'delete-account-modal.delete-account-title'
          : 'delete-account-modal.delete-account-title-error'
      }
      titleValues={isError.errorTriggered ? { error: isError.message } : {}}
      descriptionTranslationId={
        isError.errorTriggered
          ? 'delete-account-modal.delete-account-error'
          : isError.success
          ? 'delete-account-modal.delete-account-success'
          : 'delete-account-modal.delete-account-message'
      }
      icon={Warning}
      buttonTranslationId={
        !isError.success
          ? 'delete-account-modal.delete-account-button'
          : 'delete-account-modal.delete-account-button-success'
      }
      onClose={() => void setIsConfirmationModalOpen(false)}
      onClick={() => void handleDeleteUserAccount()}
    />
  );
};

export default asModal(DeleteAccountModal);
