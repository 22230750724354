import { groupBy } from 'lodash';
import { Rating } from '../../models/Rating';

export const groupRatingsByValue = (
  ratings: Rating[]
): { [note: number]: Rating[] } => {
  const defaultRatings: { [note: number]: Rating[] } = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  };

  const groupedRatingsByValue = groupBy(ratings, 'note');

  return {
    ...defaultRatings,
    ...groupedRatingsByValue,
  };
};
