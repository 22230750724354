const MONTHS_IN_A_YEAR = 12;

export const computeProfitPerMonth = (
  profitabilityByYear: number,
  sharePrice: number,
  shareAmount: number
): number => {
  return (
    ((profitabilityByYear / 100) * (shareAmount * sharePrice)) /
    MONTHS_IN_A_YEAR
  );
};
