import React from 'react';
import ReactDOM from 'react-dom';

const asModal = <P,>(Component: React.ComponentType<P>): React.FC<P> => {
  return (props) => {
    return ReactDOM.createPortal(
      <Component {...props} />,
      document.querySelector('#modal') as Element
    );
  };
};

export default asModal;
