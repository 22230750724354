const DAYS_IN_A_YEAR = 365.25;

export const computeProfitPerDay = (
  profitabilityByYear: number,
  shareAmount: number,
  sharePrice: number
): number => {
  return (
    ((profitabilityByYear / 100) * (shareAmount * sharePrice)) / DAYS_IN_A_YEAR
  );
};
