import clsx from 'clsx';
import { FormikErrors, getIn } from 'formik';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

const DEFAULT_CREDIT_CARD_MAX_LENGTH = 16;

interface Props {
  value: string | undefined;
  errors?: FormikErrors<{ [field: string]: unknown }>;
  placeholderTranslationId?: string;
  name: string;
  additionalClassname?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  isDate?: boolean;
}

const InputPaymentCard: React.FC<Props> = ({
  value,
  name,
  onChange,
  placeholderTranslationId,
  errors,
  additionalClassname,
  maxLength = DEFAULT_CREDIT_CARD_MAX_LENGTH,
  isDate = false,
}) => {
  const intl = useIntl();
  const error = useMemo(() => getIn(errors, name), [errors, name]);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error]);

  return (
    <input
      maxLength={maxLength}
      type="text"
      value={value}
      onChange={(event) => {
        setHasError(false);

        // Allow only numeric characters
        event.target.value = event.target.value.replace(/[^\d]/, '');

        if (isDate && event.target.value.length > 2) {
          event.target.value = event.target.value.replace(/(\d{2})/, '$1/');
        }

        return onChange(event);
      }}
      name={name}
      className={clsx(
        hasError && error
          ? 'form-input border-error focus:border-error placeholder-error'
          : 'form-input border-gray-300 focus:border-gray-300',
        'focus:ring-0 relative block w-full rounded-none bg-transparent sm:text-sm',
        additionalClassname
      )}
      placeholder={intl.formatMessage({
        id: placeholderTranslationId,
      })}
    />
  );
};

export default InputPaymentCard;
