import React, { useMemo } from 'react';
import { Rating } from '../../models/Rating';
import { computeAverageRatingsNote } from '../../services/helpers/computeAverageRatingsNote';
import { groupRatingsByValue } from '../../services/helpers/groupRatingsByValue';
import InputRating from '../Forms/InputRating/InputRating';
import FillableStarIcon from '../Icons/FillableStarIcon/FillableStarIcon';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  ratings: Rating[];
}

const NOTE_VALUES = [5, 4, 3, 2, 1];

const RatingsDistribution: React.FC<Props> = ({ ratings }) => {
  const ratingsByValues: { [note: number]: Rating[] } = useMemo(() => {
    return groupRatingsByValue(ratings);
  }, [ratings]);

  const averageRatingsNote = useMemo(() => {
    return computeAverageRatingsNote(ratings);
  }, [ratings]);

  return (
    <div>
      <div className="flex justify-between">
        <h3 className="text-pageTitle font-bold my-2">
          <span className="text-black">
            {!isNaN(averageRatingsNote) ? `${averageRatingsNote}` : `0`}
          </span>
          <span className="text-mediumGrey">
            {'/'}
            <TranslatedMarkupText id="ratings.5" />
          </span>
        </h3>
        <span className="flex items-center">
          <InputRating value={averageRatingsNote} isInteractive={false} />
        </span>
      </div>
      <dl className="space-y-2">
        {NOTE_VALUES.map((noteValue: number) => (
          <div key={noteValue} className="flex items-center text-sm">
            <dt className="flex-1 flex items-center">
              <p className="w-3 font-medium text-darkGrey">{noteValue}</p>
              <div aria-hidden="true" className="ml-1 flex-1 flex items-center">
                <FillableStarIcon
                  fillPercentage={ratingsByValues[noteValue].length > 0 ? 1 : 0}
                />
                <div className="ml-3 relative flex-1">
                  <div className="h-1 bg-gray-100 rounded-full" />
                  {ratings.length > 0 ? (
                    <div
                      className="absolute inset-y-0 bg-secondary border border-secondary rounded-full"
                      style={{
                        width: `calc(${ratingsByValues[noteValue].length} / ${ratings.length} * 100%)`,
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </dt>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default RatingsDistribution;
