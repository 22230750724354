import React from 'react';
import { useIntl } from 'react-intl';

import { FaMapMarkerAlt } from 'react-icons/fa';

interface Props {
  title: string;
  location: string;
  price: number;
}

const ActiveProjectBody: React.FC<Props> = ({ title, location, price }) => {
  const intl = useIntl();

  return (
    <div className="xl:rounded-sm xl:border xl:border-gray-400 xl:w-full">
      <div className="bg-white p-4 flex flex-col justify-between leading-normal pb-6 ">
        <div className="mb-2">
          <div className="text-gray-900 font-bold text-xl mb-1">{title}</div>
        </div>
        <div className="flex flex-row justify-between items-center">
          <div className="text-sm">
            <p className="text-gray-500 leading-none">
              <FaMapMarkerAlt color="text-gray-500" className="inline mr-2" />{' '}
              {location}
            </p>
          </div>
          <div className="text-sm">
            <p className="text-gray-500 leading-none">
              <b className="text-lg text-gray-800">{price}€</b>
              <span>/{intl.formatMessage({ id: 'part' })}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveProjectBody;
