import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { join } from 'lodash';
import { LanguageContext } from '../../App';
import { enLocale, frLocale } from '../../constants/intl.const';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';

const LanguageSelect: React.FC = () => {
  const intl = useIntl();
  const router = useIntlRouter();
  const { applicationLocale, setApplicationLocale } =
    useContext(LanguageContext);
  const [currentPathname, setCurrentPathname] = useState('');

  const LANGUAGE_SELECT_OPTIONS = [
    {
      value: frLocale,
      icon: '🇫🇷',
      label: intl.formatMessage({ id: `locales.${frLocale}` }),
    },
    {
      value: enLocale,
      icon: '🇺🇸',
      label: intl.formatMessage({ id: `locales.${enLocale}` }),
    },
  ];

  useEffect(() => {
    currentPathname && router.push(currentPathname);
  }, [intl.messages]);

  return (
    <select
      value={applicationLocale}
      onChange={(event) => {
        const paths = router.routeInfo.pathname.split('/');
        setCurrentPathname(`/${join(paths.slice(2, paths.length), '/')}`);
        localStorage.setItem('locale', event.target.value);
        setApplicationLocale(event.target.value);
      }}
    >
      {LANGUAGE_SELECT_OPTIONS.map((selectOption) => (
        <option key={selectOption.value} value={selectOption.value}>
          {selectOption.label} {selectOption.icon}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelect;
