import { useIonRouter } from '@ionic/react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { getInternationalizedPath } from '../helpers/getInternationalizedPath';

export const useIntlRouter = () => {
  const intl = useIntl();
  const router = useIonRouter();
  const history = useHistory();

  return {
    ...router,
    push: (
      path: string,
      additionalPath?: string,
      state?: { [field: string]: unknown }
    ) =>
      history.push(getInternationalizedPath(path, intl, additionalPath), state),
  };
};
