import { IonContent, IonPage } from '@ionic/react';
import clsx from 'clsx';
import { isPlatform } from '@ionic/react';
import React, { createContext, useRef } from 'react';

interface Props {
  header?: React.ReactNode;
  isScreenHeight?: boolean;
}

export const PageContext = createContext({
  pageRef: document.createElement('ion-router'),
});

const Layout: React.FC<Props> = ({
  isScreenHeight = false,
  header,
  children,
}) => {
  const pageRef = useRef<HTMLIonRouterElement>(
    document.createElement('ion-router')
  );

  return (
    <IonPage
      className={clsx(isScreenHeight && 'h-screen bg-white max-w-screen')}
      ref={pageRef}
    >
      {Boolean(header) && header}
      <PageContext.Provider value={{ pageRef: pageRef.current }}>
        <IonContent className="bg-white max-w-screen">
          <div
            className={clsx(
              'bg-white text-black h-full max-w-screen',
              Boolean(header) && 'm-0'
            )}
          >
            {children}
          </div>
        </IonContent>
      </PageContext.Provider>
    </IonPage>
  );
};

export default Layout;
