import React, { useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import Loader from '../../components/Loader/Loader';
import { PATH } from '../../constants/path.const';
import { useIntlRouter } from '../hooks/useIntlRouter';

const LOADER_HEIGHT = 42;
const LOADER_WIDTH = 42;

const withAuth = <P,>(Component: React.ComponentType<P>): React.FC<P> => {
  return (props) => {
    const auth = useAuth();
    const router = useIntlRouter();

    useEffect(() => {
      if (auth.pending) return;
      if (!auth.isSignedIn) {
        console.log('user no more signed in !');
      }
    }, [auth.pending, auth.isSignedIn]);

    return auth.pending ? (
      <div className="h-full w-full flex bg-aliceBlue justify-center items-center">
        <Loader color="blue" width={LOADER_WIDTH} height={LOADER_HEIGHT} />
      </div>
    ) : auth.isSignedIn ? (
      <Component {...props} />
    ) : (
      <Component {...props} />
    );
  };
};

export default withAuth;
