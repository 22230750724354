import React from "react";
import { useIntl } from "react-intl";

import { FaStar } from "react-icons/fa";
import { IonAvatar, IonImg } from "@ionic/react";
import { Media } from "../../models/Media";
import TranslatedMarkupText from "../TranslatedMarkupText/TranslatedMarkupText";
import Avatar from "../Avatar/Avatar";

interface Props {
  rating: number;
  totalRatings: number;
  name?: string;
  lastname?: string;
  type: string;
  picture: Media | undefined;
}
const FA_STAR_ICON_SIZE_TOP = 10;
const FA_STAR_ICON_SIZE_BOTTOM = 15;

const PendingProjectHeader: React.FC<Props> = ({
  rating,
  totalRatings,
  name,
  lastname,
  picture,
  type,
}) => {
  const intl = useIntl();

  return (
    <>
      <div className="absolute flex text-white justify-between top-5 left-5 xl:left-2 xl:top-3">
        <div>
          <div className="rounded-full">
            <Avatar
              image={picture?.mediaLink}
              isExpert={true}
              userInitials={`${
                name && name[0]
              } ${lastname && lastname[0]}`}
            />
          </div>
        </div>
        <div className="ml-2 text-white ">
          <p className="font-bold text-lg">
            {name ? name : intl.formatMessage({ id: "none" })}{" "}
            {lastname ? lastname : intl.formatMessage({ id: "none" })}
          </p>
          <p className="text-sm text-left mr-4">{type}</p>
        </div>
      </div>

      <div className="absolute text-white  top-8 right-6 xl:right-2 xl:top-16 xl:mt-1">
        <div className="flex flew-row">
          <div className="inline  flex flex-row">
            <p className="flex font-bold">
              {rating ? rating : intl.formatMessage({ id: "not-applicable" })}
            </p>
            <FaStar className="mt-1 ml-1" size={FA_STAR_ICON_SIZE_BOTTOM} />
            {/* <span className="ml-2">
              {' '}
              {`${totalRatings}`}{' '}
              <TranslatedMarkupText id="project-details.reviews" />
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingProjectHeader;
