import React, { useEffect, useMemo } from 'react';
import SimpleImageSlider from 'react-simple-image-slider';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import { Project, ProjectStatus } from '../../models/Project';
import defaultSliderImage from '../../assets/images/default-slider.png';

interface Props {
  images: { url: string }[];
  project: Project;
}

const DEFAULT_IMAGE = [
  {
    url: defaultSliderImage,
  },
];

const SliderCover: React.FC<Props> = ({ project, images }) => {
  const defaultSliderImages = DEFAULT_IMAGE;
  const [sliderImages, setSliderImages] = React.useState(defaultSliderImages);

  useMemo(() => {
    const updatedSliderImages = project.cover
      ? [{ url: project.cover.mediaLink }]
      : images.length > 0
      ? images
      : DEFAULT_IMAGE;
    // images.length > 0 ? images : DEFAULT_IMAGE;
    setSliderImages(updatedSliderImages);
  }, [project]);

  return (
    <div className="relative w-full">
      <SimpleImageSlider
        width={'100%'}
        height={250}
        images={sliderImages}
        showBullets={true}
        autoPlay={true}
        showNavs={false}
      />
      <div className="absolute top-0 left-0 bg-gradient-to-b from-black w-full h-20">
        <span className="absolute top-5 right-5 text-white bg-gray-400 p-1 px-4 rounded-md bg-opacity-30 backdrop-filter backdrop-blur-lg">
          {project.status === ProjectStatus.ACTIVE ? (
            <b>
              <TranslatedMarkupText id="project" />
            </b>
          ) : (
            <b>
              <TranslatedMarkupText id="project_before_collect" />
            </b>
          )}
        </span>
      </div>
    </div>
  );
};
export default SliderCover;
