import React, { useRef } from 'react';
import { ProjectStatus } from '../../models/Project';
import { useInfiniteFetchProjects } from '../../services/hooks/useInfiniteFetchProjects';
import { useIntersectionObserver } from '../../services/hooks/useIntersectionObserver';
import Loader from '../Loader/Loader';
import ProjectCard from '../ProjectCard/ProjectCard';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  projectStatus: ProjectStatus;
}

const LOADER_HEIGHT = 42;
const LOADER_WIDTH = 42;
const PROJECTS_PER_PAGE = 20;

const ProjectInfiniteList: React.FC<Props> = ({ projectStatus }) => {
  const {
    data: projects,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteFetchProjects(PROJECTS_PER_PAGE, projectStatus);

  const loadMoreRef = useRef<HTMLButtonElement>(null);

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => void fetchNextPage(),
    enabled: Boolean(hasNextPage),
  });

  return (
    <div>
      {projects.length === 0 && (
        <div className="h-16 flex flex-col justify-center items-center text-darkGrey">
          <TranslatedMarkupText id="projects.no-projects" />
        </div>
      )}
      {projects.map((project) => {
        return <ProjectCard key={project.id} project={project} />;
      })}
      {isLoading ||
        (isFetchingNextPage && (
          <div className="h-16 flex flex-col justify-center items-center text-darkGrey">
            <Loader height={LOADER_HEIGHT} width={LOADER_WIDTH} color="blue" />
            <span className="mt-2">
              <TranslatedMarkupText id="projects.loading" />
            </span>
          </div>
        ))}
      <button ref={loadMoreRef} disabled={!hasNextPage || isFetchingNextPage} />
    </div>
  );
};
export default ProjectInfiniteList;
