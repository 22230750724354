import React from 'react';
import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import {
  MangopayKYCDocumentStatus,
  MangopayKYCDocumentType,
} from '../../models/KYCDocument';
import withAuth from '../../services/hoc/withAuth';
import { useFetchCurrentUserKYCDocumentsByStatus } from '../../services/hooks/useFetchCurrentUserKYCDocuments';

const NATURAL_USER_REQUIRED_KYC_DOCUMENTS = [
  MangopayKYCDocumentType.IDENTITY_PROOF,
];

const LEGAL_OWNER_REQUIRED_KYC_DOCUMENTS = [
  MangopayKYCDocumentType.REGISTRATION_PROOF,
  MangopayKYCDocumentType.ARTICLES_OF_ASSOCIATION,
];

const KYCDocuments: React.FC = () => {
  const { data: KYCDocumentsInValidation, isLoading } =
    useFetchCurrentUserKYCDocumentsByStatus(
      MangopayKYCDocumentStatus.VALIDATION_ASKED
    );

  return (
    <Layout header={<Header />}>
      <div className="pt-5 px-5 text-black text-l font-semibold">
        <TranslatedMarkupText id="kyc-documents.natural-user.title" />
      </div>
      {!isLoading &&
        KYCDocumentsInValidation &&
        KYCDocumentsInValidation.map((KYCDocument) => (
          <div className="m-5">
            <div>{KYCDocument.type}</div>
            <div>{KYCDocument.status}</div>
          </div>
        ))}
      <div className="pt-5 px-5 text-black text-l font-semibold">
        <TranslatedMarkupText id="kyc-documents.legal-owner.title" />
      </div>
    </Layout>
  );
};

export default withAuth(KYCDocuments);
