import React, { useState } from 'react';
import clsx from 'clsx';

interface Props {
  options?: { title: string; onSelect: () => void }[];
  icons?: { component: React.ReactNode }[];
}

const TabBar: React.FC<Props> = ({ options, icons }) => {
  const [activeStatus, setActiveStatus] = useState(0);

  return (
    <>
      <div className="w-full z-10 h-12 flex flex-row justify-between bg-white border-b">
        <ul className="relative flex flex-shrink px-5">
          {options?.map((option, index) => {
            return (
              <li
                key={option.title}
                onClick={() => {
                  setActiveStatus(index);
                  option.onSelect();
                }}
                className={clsx(
                  activeStatus === index
                    ? 'text-sm border-primary items-center text-black font-black mr-4 pt-3 rounded-t'
                    : 'text-sm text-gray-600 py-3 flex mr-4 items-center hover:text-black cursor-pointer mt-2'
                )}
              >
                <div className="flex items-center mb-3">
                  <span
                    className={clsx(
                      activeStatus === index
                        ? 'ml-1 font-bold'
                        : 'ml-1 font-normal'
                    )}
                  >
                    {option.title}
                  </span>
                </div>
                {activeStatus === index && (
                  <div className="w-full h-1 bg-primary rounded-t-md" />
                )}
              </li>
            );
          })}
        </ul>
        <ul className="relative flex flex-shrink px-5">
          {icons?.map((icon, index) => {
            return (
              <li
                key={index}
                className="text-sm border-primary text-black font-black pt-3 rounded-t"
              >
                <div className="flex items-center">{icon.component}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default TabBar;
