import { FirebaseError } from 'firebase/app';
import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth';
import { INTERNAL_SERVER_ERROR, SUCCESS_STATUS } from './apiClient';

// Login errors
export const INVALID_CREDENTIALS_ERROR = 400;
// Register errors
export const USER_ALREADY_HAS_ACCOUNT_ERROR = 208;

interface UserCredentials {
  email: string;
  password: string;
}

export const firebaseLogin = async ({
  email,
  password,
}: UserCredentials): Promise<number> => {
  const auth = getAuth();

  await setPersistence(auth, browserLocalPersistence);

  return signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      return SUCCESS_STATUS;
    })
    .catch((error: FirebaseError) => {
      if (
        error.code === 'auth/invalid-email' ||
        error.code === 'auth/wrong-password' ||
        error.code === 'auth/user-not-found'
      ) {
        return INVALID_CREDENTIALS_ERROR;
      }

      return INTERNAL_SERVER_ERROR;
    });
};

export const firebaseRegister = async ({
  email,
  password,
}: UserCredentials): Promise<number> => {
  const auth = getAuth();

  await setPersistence(auth, browserLocalPersistence);

  return createUserWithEmailAndPassword(auth, email, password).then(
    () => {
      return SUCCESS_STATUS;
    },
    (error: FirebaseError) => {
      if (error.code === 'auth/email-already-in-use') {
        return USER_ALREADY_HAS_ACCOUNT_ERROR;
      }

      return INTERNAL_SERVER_ERROR;
    }
  );
};

export const deleteFirebaseCurrentUser = async (): Promise<number | null> => {
  const auth = getAuth();

  const currentUser = auth.currentUser;
  if (!currentUser) {
    return null;
  }

  return currentUser.delete().then(
    () => {
      return SUCCESS_STATUS;
    },
    () => {
      return INTERNAL_SERVER_ERROR;
    }
  );
};

export const getFirebaseCurrentUserUid = (): string | undefined => {
  const auth = getAuth();

  if (auth.currentUser) {
    return auth.currentUser.uid;
  }
};

export const getFirebaseCurrentUserAccessToken = async (): Promise<
  string | null
> => {
  const auth = getAuth();

  if (auth.currentUser) {
    return await auth.currentUser.getIdToken();
  }

  const firebaseAuth = getAuth();

  return new Promise<string | null>((resolve) => {
    const unregisterAuthObserver = firebaseAuth.onAuthStateChanged(
      (user: User | null) => {
        if (user) {
          resolve(user.getIdToken());
        }
        resolve(null);
      }
    );

    return unregisterAuthObserver();
  });
};

export const signOut = async (): Promise<void> => {
  const auth = getAuth();

  await auth.signOut();
};

export const getCurrentUserEmail = (): string => {
  const auth = getAuth();

  const currentUser = auth.currentUser;
  if (!currentUser || !currentUser.email) {
    return '';
  }

  return currentUser.email;
};
