import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';
import Avatar from '../../components/Avatar/Avatar';
import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import { PATH } from '../../constants/path.const';
import { getInternationalizedPath } from '../../services/helpers/getInternationalizedPath';
import { useFetchCurrentUser } from '../../services/hooks/useFetchCurrentUser';
import { useDeleteCurrentUser } from '../../services/hooks/useDeleteCurrentUser';
import FavoritesIcon from '../../assets/icons/heart-outline.svg';
import MenuLink from '../../components/MenuLink/MenuLink';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import InvestmentPortfolioIcon from '../../assets/icons/investment-portfolio.svg';
import withAuth from '../../services/hoc/withAuth';
import WalletIcon from '../../assets/icons/wallet.svg';
import UserIcon from '../../assets/icons/user.svg';
import InvestorIcon from '../../assets/icons/investor.svg';
import {
  deleteFirebaseCurrentUser,
  getFirebaseCurrentUserAccessToken,
  signOut,
} from '../../services/networking/firebase';
import DeleteAccountModal from '../../components/ConfirmationModal/DeleteAccountModal';

const Account: React.FC = () => {
  const { data: user, isLoading } = useFetchCurrentUser();

  const intl = useIntl();

  const navigation = [
    {
      name: intl.formatMessage({
        id: `sidebar-menu.navigation.${PATH.PROFILE_FORM}`,
      }),
      href: getInternationalizedPath(PATH.PROFILE_FORM, intl),
      icon: UserIcon,
      path: PATH.PROFILE_FORM,
    },
    {
      name: intl.formatMessage({
        id: `sidebar-menu.navigation.${PATH.LEGAL_OWNER_FORM}`,
      }),
      href: getInternationalizedPath(PATH.LEGAL_OWNER_FORM, intl),
      icon: InvestorIcon,
      path: PATH.LEGAL_OWNER_FORM,
    },
    {
      name: intl.formatMessage({
        id: `sidebar-menu.navigation.${PATH.FAVORITES}`,
      }),
      href: getInternationalizedPath(PATH.FAVORITES, intl),
      icon: FavoritesIcon,
      path: PATH.FAVORITES,
    },
    {
      name: intl.formatMessage({
        id: `sidebar-menu.navigation.${PATH.INVESTMENT_PORTFOLIO}`,
      }),
      href: getInternationalizedPath(PATH.INVESTMENT_PORTFOLIO, intl),
      icon: InvestmentPortfolioIcon,
      path: PATH.INVESTMENT_PORTFOLIO,
    },
    {
      name: intl.formatMessage({
        id: `sidebar-menu.navigation.${PATH.WALLET}`,
      }),
      href: getInternationalizedPath(PATH.WALLET, intl),
      icon: WalletIcon,
      path: PATH.WALLET,
    },
  ];

  return (
    <Layout header={<Header />} isScreenHeight>
      {!isLoading && user && (
        <div className="flex flex-col justify-center px-5 h-full max-w-4xl md:mx-auto">
          <div className="h-2/5 flex flex-col justify-center items-center">
            {user.firstname && (
              <Avatar
                userInitials={`${user.lastname && user.lastname[0]} ${
                  user.firstname && user.firstname[0]
                }`}
                image={user.picture?.mediaLink}
                isExpert={false}
                additionalImageClassname="w-28 h-28 lg:w-36 lg:h-36"
              />
            )}
            {user.firstname && (
              <div className="text-black font-bold text-l mt-5">{`${user.firstname} ${user.lastname}`}</div>
            )}
          </div>
          <div className="h-3/5">
            <nav className="space-y-1" aria-label="Sidebar">
              {navigation.map((menuLink, index) => (
                <div key={menuLink.name}>
                  <MenuLink menuLink={menuLink} />
                  {index + 1 < navigation.length && <hr />}
                </div>
              ))}
            </nav>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default withAuth(Account);
