import { RouteInfo } from '@ionic/react';
import { join } from 'lodash';

export const isCurrentPathname = <RouterLike extends { routeInfo: RouteInfo }>(
  path: string,
  router: RouterLike
): boolean => {
  const paths = router.routeInfo.pathname.split('/');
  const currentPathname = `/${join(paths.slice(2, paths.length), '/')}`;

  return path === currentPathname;
};
