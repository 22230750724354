/* Color palette is for defining new colors, do not use it directly, add a new property to colorUsage using the colorPalette's properties */
export const colorPalette = {
  green: '#C0E21B',
  nileBlue: '#172B4D',
};

export const colorUsage = {
  primary: colorPalette.green,
  dark: colorPalette.nileBlue,
};
