import { IonRouterLink } from '@ionic/react';
import { Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import AuthWarning from '../../components/AuthWarning/AuthWarning';
import BasicButton from '../../components/BasicButton/BasicButton';
import ErrorToast from '../../components/ErrorToast/ErrorToast';
import CountryInputSelect from '../../components/Forms/CountryInputSelect/CountryInputSelect';
import InputAddressAutocomplete from '../../components/Forms/InputAddressAutocomplete/InputAddressAutocomplete';
import MuiDatePicker from '../../components/Forms/MuiDatePicker/MuiDatePicker';
import Loader from '../../components/Loader/Loader';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import { PATH } from '../../constants/path.const';
import { Address } from '../../models/Address';
import { CountryIso } from '../../models/LegalOwner';
import { getFormattedAddress } from '../../services/helpers/getFormattedAddress';
import { getInternationalizedPath } from '../../services/helpers/getInternationalizedPath';
import { useFetchCurrentUser } from '../../services/hooks/useFetchCurrentUser';
import { useFetchCurrentUserLegalOwner } from '../../services/hooks/useFetchCurrentUserLegalOwner';
import { useFetchCurrentUserWallet } from '../../services/hooks/useFetchCurrentUserWallet';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';
import {
  postCurrentUserLegalOwner,
  postCurrentUserWallet,
} from '../../services/networking/apiClient';

export type LegalOwnerFormData = {
  birthday: Date | null;
  nationality: CountryIso;
  address: Address | null;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  birthday: Yup.date().typeError('required').required('required'),
  nationality: Yup.string().required('required'),
  address: Yup.object().typeError('required').required('required'),
});

const LOADER_HEIGHT = 42;
const LOADER_WIDTH = 42;

const LegalOwnerForm: React.FC = () => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { data: legalOwner, isLoading } = useFetchCurrentUserLegalOwner();
  const { data: user, isLoading: isLoadingUser } = useFetchCurrentUser();

  const [isSubmitError, setIsSubmitError] = useState(false);
  const router = useIntlRouter();
  const intl = useIntl();

  const isUserProfileIncomplete = useMemo(() => {
    return (
      (!isLoadingUser && !user) ||
      user?.firstname === undefined ||
      user.lastname === undefined
    );
  }, [isLoadingUser, user]);

  return (
    <>
      <ErrorToast
        isOpen={isSubmitError}
        onClose={() => setIsSubmitError(false)}
      />
      {isLoading && (
        <div className="flex w-full h-full justify-center items-center">
          <Loader color="blue" height={LOADER_HEIGHT} width={LOADER_WIDTH} />
        </div>
      )}
      {!isLoading && (
        <div className="bg-primary flex-grow flex mx-auto w-full h-full max-w-form">
          <Formik
            initialValues={
              {
                birthday: legalOwner?.birthday ?? null,
                nationality: legalOwner?.nationality ?? CountryIso.FR,
                address: legalOwner?.address ?? null,
              } as LegalOwnerFormData
            }
            validationSchema={VALIDATION_SCHEMA}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values, actions) => {
              setIsSubmitLoading(true);
              try {
                await postCurrentUserLegalOwner(values);
                await postCurrentUserWallet();
                actions.setSubmitting(false);
                router.push(PATH.PROJECTS);
              } catch (error) {
                console.log(error);
                setIsSubmitError(true);
              }

              setIsSubmitLoading(false);
            }}
          >
            {(props) => (
              <form
                className="flex flex-col bg-white justify-center w-full h-full px-4"
                onSubmit={props.handleSubmit}
                autoComplete="off"
              >
                <div className="text-formSubtitle font-bold mt-6 w-full">
                  <TranslatedMarkupText id="legal-owner-form.legal-owner-info" />
                </div>
                <div className="mt-2 w-full">
                  <MuiDatePicker
                    value={props.values.birthday}
                    placeholderTranslationId={'legal-owner-form.birthday'}
                    name="birthday"
                    onSubmit={(date) => props.setFieldValue('birthday', date)}
                    errors={props.errors}
                  />
                </div>
                <div className="mt-2 w-full">
                  <CountryInputSelect
                    placeholderTranslationId="legal-owner-form.nationality"
                    name="nationality"
                    onChange={(selectedOption) =>
                      props.setFieldValue('nationality', selectedOption?.value)
                    }
                    errors={props.errors}
                  />
                </div>
                <div className="mt-2 w-full">
                  <InputAddressAutocomplete
                    value={
                      props.values.address
                        ? getFormattedAddress(props.values.address)
                        : ''
                    }
                    name="address"
                    onChange={(address) =>
                      props.setFieldValue('address', address)
                    }
                    onDelete={() => props.setFieldValue('address', null)}
                    errors={props.errors}
                    placeholderTranslationId="legal-owner-form.address"
                    hasFloatingLabel
                  />
                </div>
                <div className="mt-4 w-full">
                  <BasicButton
                    className="w-full rounded-lg"
                    type="submit"
                    translationId="legal-owner-form.submit"
                    onClick={() => props.setErrors({})}
                    isLoading={isSubmitLoading}
                    isDisabled={
                      isUserProfileIncomplete || (legalOwner ? true : false)
                    }
                  />
                </div>
                {isUserProfileIncomplete && (
                  <div className="my-1 py-2">
                    <AuthWarning
                      translationId={`legal-owner-form.complete-user-profile`}
                    />
                  </div>
                )}
                {(legalOwner ? true : false) && (
                  <div className="my-1 py-2">
                    <AuthWarning
                      translationId={`legal-owner-form.already-have-legal-owner`}
                    />
                  </div>
                )}
                <div className="text-center text-md text-darkGrey py-5">
                  <IonRouterLink
                    href={getInternationalizedPath(PATH.ACCOUNT, intl)}
                    className="text-primary text-md font-semibold"
                  >
                    <BasicButton
                      className="w-full rounded-lg bg-black text-[#b9f51b]"
                      type="button"
                      translationId="legal-owner-form.cancel"
                    ></BasicButton>
                  </IonRouterLink>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
export default LegalOwnerForm;
