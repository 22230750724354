import React from 'react';

interface Props {
  fill?: string;
}

const UploadIcon: React.FC<Props> = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="upload">
      <path
        id="Path 2 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.5C12.2761 17.5 12.5 17.2761 12.5 17V4C12.5 3.72386 12.2761 3.5 12 3.5C11.7239 3.5 11.5 3.72386 11.5 4V17C11.5 17.2761 11.7239 17.5 12 17.5Z"
      />
      <path
        id="Path 3 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.64645 8.35355C6.84171 8.54882 7.15829 8.54882 7.35355 8.35355L12 3.70711L16.6464 8.35355C16.8417 8.54882 17.1583 8.54882 17.3536 8.35355C17.5488 8.15829 17.5488 7.84171 17.3536 7.64645L12 2.29289L6.64645 7.64645C6.45118 7.84171 6.45118 8.15829 6.64645 8.35355Z"
      />
      <path
        id="Path 2 (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 21C3.5 20.7239 3.72386 20.5 4 20.5H20C20.2761 20.5 20.5 20.7239 20.5 21C20.5 21.2761 20.2761 21.5 20 21.5H4C3.72386 21.5 3.5 21.2761 3.5 21Z"
      />
    </g>
  </svg>
);

export default UploadIcon;
