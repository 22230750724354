import React from 'react';
import { FormattedDate } from 'react-intl';
import { Rating } from '../../models/Rating';
import Avatar from '../Avatar/Avatar';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  rating: Rating;
}

const RatingDetail: React.FC<Props> = ({ rating }) => {
  return (
    <div>
      <div className="flex w-full justify-between mb-4">
        <div className="flex">
          <Avatar
            image={rating.user.picture?.mediaLink}
            isExpert={false}
            userInitials={`${rating.user.firstname?.[0]} ${rating.user.lastname?.[0]}`}
          />
          <div className="ml-3 flex flex-col text-base">
            <span className="text-darkGrey font-bold">
              {rating.user.firstname} {rating.user.lastname}
            </span>
            <span className="text-mediumGrey -mt-1">
              <FormattedDate
                value={rating.createdAt}
                day="2-digit"
                month="long"
                year="numeric"
              />
            </span>
          </div>
        </div>
        <div className="font-bold text-black">
          {`${rating.note}/`}
          <TranslatedMarkupText id="ratings.5" />
        </div>
      </div>
      <div className="text-ratingTextArea">{rating.review}</div>
      <hr className="my-3" />
    </div>
  );
};

export default RatingDetail;
