import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { getFile } from '../networking/apiClient';

interface DownloadFileProps {
  apiRequest: (fileUrl: string) => Promise<AxiosResponse<Blob> | undefined>;
  fileName?: string;
  fileExtension: string;
}

const DEFAULT_FILE_NAME_PREFIX = 'aia_software_';

const useDownloadFile = ({
  apiRequest,
  fileName,
  fileExtension,
}: DownloadFileProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean | null>(null);

  const downloadFile = async (fileUrl: string) => {
    setIsDownloading(true);
    const response = await getFile(fileUrl);

    const a = document.createElement('a');
    const urlObject = URL.createObjectURL(
      new Blob(
        response
        // {type: `application/${fileExtension}`}
      )
    );

    document.body.appendChild(a);
    a.download = `${
      fileName ??
      `${DEFAULT_FILE_NAME_PREFIX}${new Date().getTime().toLocaleString()}`
    }.${fileExtension}`;
    a.href = urlObject;
    a.click();
    document.body.removeChild(a);

    setIsDownloading(false);
  };

  useEffect(() => {
    if (isDownloading === true) {
      // toast.loading(t('file.downloading'));
    } else if (isDownloading === false) {
      // toast.dismiss();
      // toast.success(t('file.success'), { autoClose: 2000 });
    }
  }, [isDownloading]);

  return {
    isDownloading,
    downloadFile,
  };
};

export default useDownloadFile;
