import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { IonRouterLink } from '@ionic/react';
import { useIntl } from 'react-intl';
import BasicButton from '../../components/BasicButton/BasicButton';
import ControlledInputText from '../../components/Forms/ControlledInputText/ControlledInputText';
import InputMediaAvatar from '../../components/Forms/InputMediaAvatar/InputMediaAvatar';
import Loader from '../../components/Loader/Loader';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import { PATH } from '../../constants/path.const';
import defaultAvatar from '../../constants/avatar.const';
import { Media } from '../../models/Media';
import { useFetchCurrentUser } from '../../services/hooks/useFetchCurrentUser';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';
import { patchUser } from '../../services/networking/apiClient';
import { getInternationalizedPath } from '../../services/helpers/getInternationalizedPath';
import DeleteAccountModal from '../../components/ConfirmationModal/DeleteAccountModal';

export type UpdateUserFormData = {
  firstname: string;
  lastname: string;
  picture: Media | undefined;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  firstname: Yup.string().required('required'),
  lastname: Yup.string().required('required'),
});

const ProfileForm: React.FC = () => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { data: user, isLoading } = useFetchCurrentUser();
  const router = useIntlRouter();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isLoading && (
        <div className="flex w-full h-full justify-center items-center">
          <Loader color="blue" height={24} width={24} />
        </div>
      )}
      {!isLoading && (
        <div className="bg-primary flex-grow flex mx-auto w-full h-full max-w-form">
          <DeleteAccountModal isOpen={isOpen} />

          <Formik
            initialValues={
              {
                firstname: user?.firstname ?? '',
                lastname: user?.lastname ?? '',
              } as UpdateUserFormData
            }
            validationSchema={VALIDATION_SCHEMA}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values, actions) => {
              setIsSubmitLoading(true);
              const updateUser = {
                lastname: values.lastname,
                firstname: values.firstname,
                picture: values.picture,
              };
              console.log(updateUser);

              const response = await patchUser(updateUser);
              actions.setSubmitting(false);

              if (response) {
                router.push(PATH.ACCOUNT);
              }
              setIsSubmitLoading(false);
            }}
          >
            {(props) => (
              <form
                className="flex flex-col bg-white justify-center w-full h-full px-4"
                onSubmit={props.handleSubmit}
                autoComplete="off"
              >
                {/* <div className="w-full">
                  <InputMediaAvatar
                    errors={props.errors}
                    onChange={(medias: Media[]) => {
                      props.setFieldValue("picture", medias[0]);
                    }}
                    fileTranslationId="profile-form.add-picture"
                    currentMedias={
                      user?.picture ? [user.picture] : [defaultAvatar]
                    }
                    name="picture"
                  />
                </div> */}

                <div className="text-formSubtitle font-bold mt-6 w-full">
                  <TranslatedMarkupText id="profile-form.personal-info" />
                </div>
                <div className="mt-2 w-full">
                  <ControlledInputText
                    value={props.values.firstname}
                    labelTranslationId={'profile-form.firstname'}
                    placeholderTranslationId={'profile-form.firstname'}
                    name="firstname"
                    onChange={props.handleChange}
                    errors={props.errors}
                    hasFloatingLabel
                  />
                </div>
                <div className="mt-2 w-full">
                  <ControlledInputText
                    value={props.values.lastname}
                    labelTranslationId={'profile-form.lastname'}
                    placeholderTranslationId={'profile-form.lastname'}
                    name="lastname"
                    onChange={props.handleChange}
                    errors={props.errors}
                    hasFloatingLabel
                  />
                </div>
                <div className="mt-4 w-full">
                  <BasicButton
                    className="w-full rounded-lg"
                    type="submit"
                    translationId="profile-form.submit"
                    onClick={() => {
                      props.setErrors({});
                      props.handleSubmit();
                    }}
                    isLoading={isSubmitLoading}
                  />
                </div>
                <div className="text-center text-md text-darkGrey py-4">
                  <IonRouterLink
                    href={getInternationalizedPath(PATH.ACCOUNT, intl)}
                    className="text-primary text-md font-semibold"
                  >
                    <BasicButton
                      className="w-full rounded-lg bg-black text-[#b9f51b]"
                      type="button"
                      translationId="legal-owner-form.cancel"
                    ></BasicButton>
                  </IonRouterLink>
                </div>
                <BasicButton
                  className="w-full rounded-lg bg-red-700 text-[#fff]"
                  type="button"
                  onClick={() => setIsOpen(true)}
                  translationId="delete-account-button-text"
                ></BasicButton>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
export default ProfileForm;
