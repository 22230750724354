import React from 'react';

interface Props {
  fillPercentage: number;
}

const FillableStarIcon: React.FC<Props> = ({ fillPercentage }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient id="filled-star-color" opacity={1}>
        <stop
          offset="0%"
          stopColor="#357F9C"
          stopOpacity={1}
          opacity={1}
        ></stop>
        <stop
          offset={`${fillPercentage * 100}%`}
          stopColor="#EBECF0"
          stopOpacity={1}
          opacity={1}
        ></stop>
      </linearGradient>
      <path
        d="M17.7364 6.89552L12.7774 6.17481L10.5606 1.68067C10.5001 1.55762 10.4005 1.45802 10.2774 1.39747C9.96882 1.24512 9.59382 1.37208 9.43952 1.68067L7.22272 6.17481L2.26374 6.89552C2.12702 6.91505 2.00202 6.9795 1.90632 7.07716C1.79062 7.19607 1.72686 7.35606 1.72906 7.52196C1.73126 7.68787 1.79923 7.84611 1.91804 7.96192L5.50593 11.46L4.65827 16.3994C4.63839 16.5143 4.65111 16.6325 4.69497 16.7405C4.73884 16.8486 4.8121 16.9422 4.90645 17.0107C5.0008 17.0792 5.11246 17.1199 5.22878 17.1282C5.34509 17.1365 5.4614 17.1121 5.56452 17.0576L10.0001 14.7256L14.4356 17.0576C14.5567 17.1221 14.6973 17.1436 14.8321 17.1201C15.1719 17.0615 15.4005 16.7393 15.3419 16.3994L14.4942 11.46L18.0821 7.96192C18.1798 7.86622 18.2442 7.74122 18.2637 7.6045C18.3165 7.2627 18.0782 6.9463 17.7364 6.89552Z"
        fill={
          fillPercentage === 1
            ? '#357F9C'
            : fillPercentage === 0
            ? '#EBECF0'
            : `url(#filled-star-color)`
        }
      />
    </svg>
  );
};

export default FillableStarIcon;
