import React from 'react';

interface Props {
  onChange: (value: number) => void;
  value: number;
  maxValue?: number;
  step?: number;
}

const InputRange: React.FC<Props> = ({
  onChange,
  value,
  maxValue = 100,
  step = 1,
}) => {
  return (
    <input
      type="range"
      className="form-range appearance-none w-full h-2 p-0 bg-lightGrey rounded-lg focus:outline-none focus:ring-0 focus:shadow-none"
      value={value}
      onChange={(event) => {
        onChange(parseInt(event.target.value));
      }}
      step={step}
      max={maxValue}
    />
  );
};

export default InputRange;
