import clsx from 'clsx';
import React from 'react';
import { FaStar } from 'react-icons/fa';

interface Props {
  image: string | undefined;
  isExpert: boolean;
  userInitials?: string;
  additionalImageClassname?: string;
}

const FA_STAR_ICON_SIZE = 10;

const Avatar: React.FC<Props> = ({
  image,
  isExpert,
  userInitials,
  additionalImageClassname = 'w-10 h-10',
}) => {
  return (
    <div className="relative flex items-center">
      {image ? (
        <img
          src={image}
          className={clsx(
            'rounded-full ring-white object-cover',
            additionalImageClassname
          )}
          alt=""
        />
      ) : (
        <span
          className={clsx(
            'rounded-full p-2 bg-primary text-white font-bold tracking-stuck flex justify-center items-center',
            additionalImageClassname
          )}
        >
          <p className="-ml-1 uppercase">{userInitials}</p>
        </span>
      )}
      {isExpert && (
        <span className="absolute flex top-6 left-6 rounded-full w-4 h-4 bg-primary ring-2 ring-white justify-center items-center">
          <FaStar className="text-white mt-0" size={FA_STAR_ICON_SIZE} />
        </span>
      )}
    </div>
  );
};
export default Avatar;
