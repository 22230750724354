import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import loaderBlueAnimation from '../../assets/animations/loader-blue.json';
import loaderWhiteAnimation from '../../assets/animations/loader-white.json';

interface Props {
  color: 'white' | 'blue';
  height: number;
  width: number;
}

const Loader: React.FC<Props> = ({ color, height, width }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    src: color === 'white' ? loaderWhiteAnimation : loaderBlueAnimation,
    controls: false,
  };

  return (
    <Player
      {...defaultOptions}
      style={{
        height: height,
        width: width,
      }}
    />
  );
};

export default Loader;
