import clsx from 'clsx';
import { FormikErrors, getIn } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';
import CrossIcon from '../../../assets/icons/cross.svg';

interface Props {
  value: string | undefined;
  errors?: FormikErrors<{ [field: string]: unknown }>;
  placeholderTranslationId?: string;
  hasFloatingLabel?: boolean;
  name: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  onDelete?: () => void;
  onClick?: () => void;
}

const BasicInputText: React.FC<Props> = ({
  value,
  name,
  onChange,
  placeholderTranslationId,
  errors,
  hasFloatingLabel = false,
  isDisabled = false,
  onDelete,
  onClick,
}) => {
  const intl = useIntl();
  const error = useMemo(() => getIn(errors, name), [errors, name]);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (error && !isDisabled) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error, isDisabled]);

  return (
    <div
      className={clsx(
        'relative flex border rounded-lg',
        hasError && error ? 'border-error' : 'border-input'
      )}
    >
      <div
        className={clsx(
          'w-full h-14 rounded-lg flex relative',
          hasFloatingLabel
            ? 'appearance-none focus:outline-none bg-transparent'
            : 'bg-white',
          hasError && error
            ? 'text-error bg-fieldError border-error placeholder-error'
            : 'text-darkGrey bg-lightGrey',
          isDisabled && 'border-mediumGrey bg-lightGrey text-mediumGrey'
        )}
      >
        <input
          autoComplete="off"
          className={clsx(
            hasFloatingLabel ? 'px-4 pt-3.5' : 'p-2.5 ',
            'w-full h-14 outline-none rounded-lg bg-lightGrey'
          )}
          name={name}
          placeholder={
            hasFloatingLabel
              ? ' ' // We need a placeholder to check if input has value for floating label
              : placeholderTranslationId &&
                intl.formatMessage({ id: placeholderTranslationId })
          }
          value={value}
          disabled={isDisabled}
          onChange={(event) => {
            setHasError(false);

            return onChange?.(event.target.value);
          }}
          onClick={onClick}
        />
        {hasFloatingLabel && (
          <label
            htmlFor={name}
            className="absolute top-1/3 left-4 duration-300 origin-0 text-floatingLabel text-formInput"
          >
            <TranslatedMarkupText id={placeholderTranslationId} />
          </label>
        )}
        {onDelete && (
          <div className="flex justify-center items-center mr-2">
            {value?.length !== 0 && (
              <img src={CrossIcon} alt="CrossIcon" onClick={onDelete} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicInputText;
