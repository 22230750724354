import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { getAddressFromGooglePlaceId } from '../../../services/helpers/getAddressFromGooglePlaceId';
import asModal from '../../../services/hoc/asModal';
import { useAddressAutocomplete } from '../../../services/hooks/useAddressAutocomplete';
import ModalLayout from '../../ModalLayout/ModalLayout';
import BasicButton from '../../BasicButton/BasicButton';
import { Address } from '../../../models/Address';
import BasicInputText from '../BasicInputText/BasicInputText';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onChange?: (value: Address | null) => void;
  placeholderTranslationId?: string;
}

const ModalAddressAutocomplete: React.FC<Props> = ({
  isOpen,
  onChange,
  onClose,
  placeholderTranslationId,
}) => {
  const predictionsRef = useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState('');

  const autocompleteResults = useAddressAutocomplete(
    inputValue,
    predictionsRef.current
  );

  return (
    <ModalLayout isOpen={isOpen} isScreenHeight>
      <div className="flex justify-center w-full h-full">
        <div className="relative flex flex-col h-full w-full max-w-form">
          <BasicInputText
            value={inputValue}
            onChange={(value) => setInputValue(value)}
            name="addressAutocomplete"
            placeholderTranslationId={placeholderTranslationId}
            onDelete={() => setInputValue('')}
          />
          <div ref={predictionsRef}></div>
          <div
            className={clsx(
              'overflow-y-scroll text-darkGrey -mt-4 mb-4 text-sm border border-lightGrey rounded-sm',
              autocompleteResults.length > 0 && 'pt-7 pb-4'
            )}
          >
            {autocompleteResults.map((result, index) => (
              <div key={result.place_id}>
                <button
                  className="p-4 w-full border-b-2 border-lightGrey text-left hover:bg-lightGrey focus:bg-lightGrey"
                  onClick={() => {
                    void getAddressFromGooglePlaceId(
                      (result.place_id as string) || '',
                      predictionsRef.current,
                      result.geometry?.location?.lat() ?? 0,
                      result.geometry?.location?.lng() ?? 0
                    )
                      ?.then((address) => {
                        void onChange?.(address);
                        void onClose();
                      })
                      .catch((error) => {
                        console.log(error);
                        void onClose();
                      });
                  }}
                >
                  {result.formatted_address}
                </button>
                {index < autocompleteResults.length - 1 && <hr />}
              </div>
            ))}
          </div>
          <div className="mt-auto w-full">
            <BasicButton
              className="w-full"
              translationId="cancel"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default asModal(ModalAddressAutocomplete);
