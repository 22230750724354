import { useState } from 'react';
import { useFetchCurrentUserPaymentCards } from '../../services/hooks/useFetchCurrentUserPaymentCards';
import { postCurrentUserTopUpWallet } from '../../services/networking/apiClient';
import BasicButton from '../BasicButton/BasicButton';
import ErrorToast from '../ErrorToast/ErrorToast';
import InputNumber from '../Forms/InputNumber/InputNumber';
import PaymentMethodChoice from '../Forms/PaymentMethodChoice/PaymentMethodChoice';
import SwipeableModalHeader from '../SwipeableModalHeader/SwipeableModalHeader';
import SwipeableModalLayout, {
  SwipeableModalProps,
} from '../SwipeableModalLayout/SwipeableModalLayout';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props extends SwipeableModalProps {
  refetchWallet: () => void;
}

const TopUpWalletFormModal: React.FC<Props> = (props) => {
  const { data: paymentCards, isLoading } = useFetchCurrentUserPaymentCards();
  const [selectedCardId, setSelectedCardId] = useState('');
  const [topUpAmount, setTopUpAmount] = useState('');
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);

  return (
    <SwipeableModalLayout
      {...props}
      header={
        <SwipeableModalHeader
          translationId="top-up-wallet-form-modal.title"
          onClose={() => props.onClose?.()}
        />
      }
    >
      <ErrorToast
        isOpen={isErrorToastOpen}
        onClose={() => setIsErrorToastOpen(false)}
      />
      <div>
        <div className="m-5">
          <InputNumber
            name="topUpPrice"
            value={undefined}
            onChange={(event) => {
              setTopUpAmount(event.target.value);
            }}
            isFloat
            hasFloatingLabel
            labelTranslationId="top-up-wallet-form-modal.amount"
            placeholderTranslationId="top-up-wallet-form-modal.amount"
          />
        </div>
        <div className="pt-5 px-5 text-black text-l font-semibold">
          <TranslatedMarkupText id="payment-card-list.title" />
        </div>
        {!isLoading && paymentCards && (
          <div className="px-5 h-full">
            <PaymentMethodChoice
              onChange={(paymentMethod) =>
                paymentMethod.cardId && setSelectedCardId(paymentMethod.cardId)
              }
            />
          </div>
        )}
        <div className="px-5 w-full">
          <BasicButton
            translationId="top-up-wallet-form-modal.submit"
            onClick={() => {
              if (parseFloat(topUpAmount) > 0 && selectedCardId) {
                postCurrentUserTopUpWallet(
                  parseFloat(topUpAmount) * 100,
                  0,
                  selectedCardId
                ).then((payment) => {
                  if ('statusCode' in payment) {
                    setIsErrorToastOpen(true);
                  } else {
                    props.onClose?.();
                    props.refetchWallet();
                  }
                });
              } else {
                setIsErrorToastOpen(true);
              }
            }}
          />
        </div>
      </div>
    </SwipeableModalLayout>
  );
};

export default TopUpWalletFormModal;
