import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import BasicButton from '../../components/BasicButton/BasicButton';
import ClosableWarning, {
  CLOSABLE_WARNING_STYLES,
} from '../../components/ClosableWarning/ClosableWarning';
import ControlledInputText from '../../components/Forms/ControlledInputText/ControlledInputText';
import InputAddressAutocomplete from '../../components/Forms/InputAddressAutocomplete/InputAddressAutocomplete';
import InputMedia from '../../components/Forms/InputMedia/InputMedia';
import InputNumber from '../../components/Forms/InputNumber/InputNumber';
import InputSelect from '../../components/Forms/InputSelect/InputSelect';
import InputTextArea from '../../components/Forms/InputTextArea/InputTextArea';
import MuiDatePicker from '../../components/Forms/MuiDatePicker/MuiDatePicker';
import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import { PATH } from '../../constants/path.const';
import { Address } from '../../models/Address';
import { Media } from '../../models/Media';
import {
  ProfitabilityType,
  ProjectStatus,
  ProjectType,
} from '../../models/Project';
import { getFormattedAddress } from '../../services/helpers/getFormattedAddress';
import withAuth from '../../services/hoc/withAuth';
import { postProject } from '../../services/networking/apiClient';
import WarningIcon from '../../assets/icons/warning.svg';
import PeopleIcon from '../../assets/icons/investor.svg';
import InvestorIcon from '../../assets/icons/shield-star.svg';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';

const PROJECT_STATUS_OPTIONS = [
  {
    value: ProjectStatus.ACTIVE,
    labelTranslationId: `project-form.status.${ProjectStatus.ACTIVE}`,
  },
  {
    value: ProjectStatus.DRAFT,
    labelTranslationId: `project-form.status.${ProjectStatus.DRAFT}`,
  },
  {
    value: ProjectStatus.CLOSED,
    labelTranslationId: `project-form.status.${ProjectStatus.CLOSED}`,
  },
  {
    value: ProjectStatus.PENDING_LAUNCH,
    labelTranslationId: `project-form.status.${ProjectStatus.PENDING_LAUNCH}`,
  },
];

const PROJECT_TYPE_OPTIONS = [
  {
    value: ProjectType.HOME,
    labelTranslationId: `project-form.projectType.${ProjectType.HOME}`,
  },
  {
    value: ProjectType.FLAT,
    labelTranslationId: `project-form.projectType.${ProjectType.FLAT}`,
  },
  {
    value: ProjectType.FARM,
    labelTranslationId: `project-form.projectType.${ProjectType.FARM}`,
  },
];

const PROFITABILITY_TYPE_OPTIONS = [
  {
    value: ProfitabilityType.BUY,
    labelTranslationId: `project-form.profitabilityType.${ProfitabilityType.BUY}`,
  },
  {
    value: ProfitabilityType.RENT,
    labelTranslationId: `project-form.profitabilityType.${ProfitabilityType.RENT}`,
  },
];

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('required'),
  description: Yup.string().required('required'),
  startDate: Yup.date().typeError('required-date').required('required-date'),
  shareAmount: Yup.number().required('required'),
  sharePrice: Yup.number().required('required'),
  status: Yup.string().required('required-select'),
  cover: Yup.object().typeError('required-cover').required('required'),
  plans: Yup.array().min(1, 'required-medias'),
  medias: Yup.array().optional(),
  profitabilityType: Yup.string().required('required-select'),
  projectType: Yup.string().required('required-select'),
  numberOfFloors: Yup.number().required('required'),
  area: Yup.number().required('required'),
  address: Yup.object().typeError('required-address').required('required'),
});

export type ProjectFormData = {
  name: string;
  cover: Media | null;
  medias: Media[];
  description: string;
  startDate: Date | null;
  plans: Media[];
  shareAmount: number | undefined;
  sharePrice: number | undefined;
  status: string;
  profitabilityType: string;
  projectType: string;
  area: number | undefined;
  numberOfFloors: number | undefined;
  address: Address | null;
};

const ProjectForm: React.FC = () => {
  const [isClosableWarningOpen, setIsClosableWarningOpen] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const router = useIntlRouter();

  return (
    <Layout header={<Header />}>
      <div className="max-w-form w-full flex flex-col mx-auto">
        <ClosableWarning
          messages={[
            {
              icon: InvestorIcon,
              translationId: 'project-form.warnings.become-ambassador',
            },
            {
              icon: PeopleIcon,
              translationId: 'project-form.warnings.get-feedbacks',
            },
          ]}
          isOpen={isClosableWarningOpen}
          onClose={() => setIsClosableWarningOpen(false)}
        />
        <ClosableWarning
          messages={[
            {
              icon: WarningIcon,
              translationId:
                'project-form.errors.error-occurred-please-try-again',
            },
          ]}
          isOpen={isSubmitError}
          onClose={() => setIsSubmitError(false)}
          style={CLOSABLE_WARNING_STYLES.ERROR}
          isFixed
        />
        <Formik
          initialValues={
            {
              name: '',
              cover: null,
              medias: [],
              description: '',
              startDate: null,
              plans: [],
              localisation: '',
              shareAmount: undefined,
              sharePrice: undefined,
              status: '',
              projectType: '',
              profitabilityType: '',
              area: undefined,
              numberOfFloors: undefined,
              address: null,
            } as ProjectFormData
          }
          validationSchema={VALIDATION_SCHEMA}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={async (values, actions) => {
            setIsSubmitLoading(true);
            const response = await postProject(values);
            actions.setSubmitting(false);
            setIsSubmitLoading(false);
            if (response) {
              router.push(PATH.PROJECTS);
            } else {
              setIsSubmitError(true);
            }
          }}
        >
          {(props) => (
            <form
              className="w-full px-4"
              onSubmit={props.handleSubmit}
              autoComplete="off"
              action="..."
            >
              <div className="text-formTitle font-bold mt-4">
                <TranslatedMarkupText id="project-form.title" />
              </div>
              <div className="text-formSubtitle font-bold mt-6">
                <TranslatedMarkupText id="project-form.project-cover" />
              </div>
              <div className="mt-2">
                <InputMedia
                  errors={props.errors}
                  onChange={(medias: Media[]) => {
                    props.setFieldValue('cover', medias[0]);
                  }}
                  fileTranslationId="project-form.add-image"
                  name="cover"
                  isSingleMedia
                />
              </div>
              <div className="text-formSubtitle font-bold mt-6">
                <TranslatedMarkupText id="project-form.project-photos" />
              </div>
              <div className="mt-2">
                <InputMedia
                  errors={props.errors}
                  onChange={(medias: Media[]) =>
                    props.setFieldValue('medias', medias)
                  }
                  fileTranslationId="project-form.add-photos"
                  name="medias"
                  italicLabelTranslationId="formValidation.optional"
                />
              </div>
              <div className="text-formSubtitle font-bold mt-6">
                <TranslatedMarkupText id="project-form.project-plans" />
              </div>
              <div className="mt-2">
                <InputMedia
                  errors={props.errors}
                  onChange={(medias: Media[]): void =>
                    props.setFieldValue('plans', medias)
                  }
                  fileTranslationId="project-form.add-medias"
                  name="plans"
                />
              </div>
              <div className="text-formSubtitle font-bold mt-6">
                <TranslatedMarkupText id="project-form.project-info" />
              </div>
              <div className="mt-2">
                <InputSelect
                  options={PROJECT_TYPE_OPTIONS}
                  labelTranslationId={'project-form.projectType.title'}
                  name="projectType"
                  onChange={(selectedOption) => {
                    props.setFieldValue(`projectType`, selectedOption?.value);
                  }}
                  errors={props.errors}
                />
              </div>
              <div className="mt-2">
                <ControlledInputText
                  value={props.values.name}
                  labelTranslationId={'project-form.name'}
                  placeholderTranslationId={'project-form.name-placeholder'}
                  name="name"
                  onChange={props.handleChange}
                  errors={props.errors}
                  hasFloatingLabel
                />
              </div>
              <div className="mt-2">
                <MuiDatePicker
                  value={props.values.startDate}
                  placeholderTranslationId={'project-form.startDate'}
                  onSubmit={(date: Date | null) => {
                    props.setFieldValue(`startDate`, date);
                  }}
                  name="startDate"
                  errors={props.errors}
                />
              </div>
              <div className="mt-2">
                <InputNumber
                  value={props.values.area}
                  labelTranslationId={'project-form.area'}
                  placeholderTranslationId={'project-form.area'}
                  name="area"
                  onChange={props.handleChange}
                  errors={props.errors}
                  hasFloatingLabel
                />
              </div>
              <div className="mt-2">
                <InputNumber
                  value={props.values.numberOfFloors}
                  labelTranslationId={'project-form.numberOfFloors'}
                  placeholderTranslationId={'project-form.numberOfFloors'}
                  name="numberOfFloors"
                  onChange={props.handleChange}
                  errors={props.errors}
                  hasFloatingLabel
                />
              </div>
              <div className="mt-2">
                <InputAddressAutocomplete
                  value={
                    props.values.address
                      ? getFormattedAddress(props.values.address)
                      : ''
                  }
                  placeholderTranslationId={'project-form.localisation'}
                  name="address"
                  errors={props.errors}
                  onChange={(address: Address | null) => {
                    props.setFieldValue(`address`, address);
                  }}
                  hasFloatingLabel
                  onDelete={() => {
                    props.setFieldValue(`address`, null);
                  }}
                />
              </div>
              <div className="mt-2">
                <InputSelect
                  options={PROJECT_STATUS_OPTIONS}
                  onChange={(selectedOption) => {
                    props.setFieldValue('status', selectedOption?.value);
                  }}
                  name="status"
                  labelTranslationId="project-form.status.label"
                  errors={props.errors}
                />
              </div>
              <div className="text-formSubtitle font-bold mt-6">
                <TranslatedMarkupText id="project-form.shares-and-profitability" />
              </div>
              <div className="mt-2">
                <InputNumber
                  value={props.values.shareAmount}
                  labelTranslationId={'project-form.shareAmount'}
                  placeholderTranslationId={'project-form.shareAmount'}
                  name="shareAmount"
                  onChange={props.handleChange}
                  errors={props.errors}
                  hasFloatingLabel
                />
              </div>
              <div className="mt-2">
                <InputNumber
                  value={props.values.sharePrice}
                  labelTranslationId={'project-form.sharePrice'}
                  placeholderTranslationId={'project-form.sharePrice'}
                  name="sharePrice"
                  onChange={props.handleChange}
                  errors={props.errors}
                  hasFloatingLabel
                  isFloat
                />
              </div>
              <div className="mt-2">
                <InputSelect
                  options={PROFITABILITY_TYPE_OPTIONS}
                  labelTranslationId={'project-form.profitabilityType.title'}
                  name="profitabilityType"
                  onChange={(selectedOption) => {
                    props.setFieldValue(
                      `profitabilityType`,
                      selectedOption?.value
                    );
                  }}
                  errors={props.errors}
                />
              </div>
              <div className="text-formSubtitle font-bold mt-6">
                <TranslatedMarkupText id="project-form.description" />
              </div>
              <div className="mt-2 mb-16">
                <InputTextArea
                  value={props.values.description}
                  labelTranslationId={'project-form.description'}
                  placeholderTranslationId={
                    'project-form.description-placeholder'
                  }
                  name="description"
                  onChange={props.handleChange}
                  errors={props.errors}
                  hasFloatingLabel
                />
              </div>
              <div className="p-4 w-full fixed bottom-0 left-0 right-0">
                <BasicButton
                  className="text-darkGrey w-full font-bold border-r-4"
                  onClick={() => {
                    props.setErrors({});
                    props.handleSubmit();
                  }}
                  translationId="project-form.submit"
                  isLoading={isSubmitLoading}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default withAuth(ProjectForm);
