import clsx from 'clsx';
import React from 'react';

interface Props {
  menuLink: {
    name: string;
    href: string;
    icon: string;
  };
}

const MenuLink: React.FC<Props> = ({ menuLink }) => {
  return (
    <a
      key={menuLink.name}
      href={menuLink.href}
      className={clsx(
        'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'group flex menuLinks-center px-2 py-2 text-base font-medium rounded-md'
      )}
    >
      <img
        className={clsx(
          'text-gray-400 group-hover:text-gray-500',
          'mr-4 flex-shrink-0 h-6 w-6'
        )}
        aria-hidden="true"
        src={menuLink.icon}
        alt=""
      />
      {menuLink.name}
    </a>
  );
};

export default MenuLink;
