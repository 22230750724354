import React, { useEffect, useState } from 'react';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  onChange: (value: number) => void;
  value: number;
  maxValue: number;
}

const InputShareAmount: React.FC<Props> = ({ onChange, value, maxValue }) => {
  const [numberOfShares, setNumberOfShares] = useState(value);

  useEffect(() => {
    onChange(numberOfShares);
  }, [numberOfShares, onChange]);

  return (
    <>
      <button
        className="bg-lightGrey text-black text-xl rounded-lg p-4 w-1/3 focus:outline-none"
        onClick={() =>
          numberOfShares > 0 && setNumberOfShares(numberOfShares - 1)
        }
      >
        <TranslatedMarkupText id="project-profit-calculator.minus" />
      </button>
      <div className="w-1/3 flex flex-col text-center my-2">
        <input
          className="h-1/2 font-bold text-center focus:outline-none"
          type="number"
          value={Number(value).toString()}
          onChange={(event) => {
            const valueAsInteger = parseInt(event.target.value);

            const inputValue = !isNaN(valueAsInteger)
              ? valueAsInteger > maxValue
                ? maxValue
                : valueAsInteger
              : 0;
            setNumberOfShares(inputValue);
          }}
        />
        <p>
          <TranslatedMarkupText
            id={
              numberOfShares > 1
                ? 'project-profit-calculator.shares'
                : 'project-profit-calculator.share'
            }
          />
        </p>
      </div>
      <button
        className="bg-lightGrey text-black text-xl rounded-lg p-4 w-1/3 focus:outline-none"
        onClick={() =>
          setNumberOfShares(
            numberOfShares + 1 > maxValue ? maxValue : numberOfShares + 1
          )
        }
      >
        <TranslatedMarkupText id="project-profit-calculator.plus" />
      </button>
    </>
  );
};

export default InputShareAmount;
