import clsx from 'clsx';
import React from 'react';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  menuLink: {
    name: string;
    href: string;
    icon: string;
    path: string;
  };
  isCurrentActiveLink: boolean;
}

const SidebarMenuLink: React.FC<Props> = ({
  menuLink,
  isCurrentActiveLink,
}) => {
  return (
    <a
      key={menuLink.name}
      href={menuLink.href}
      className={clsx(
        isCurrentActiveLink
          ? 'bg-gray-100 text-gray-900'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'group flex menuLinks-center px-2 py-2 text-base font-medium rounded-md'
      )}
    >
      <img
        className={clsx(
          isCurrentActiveLink
            ? 'text-gray-500'
            : 'text-gray-400 group-hover:text-gray-500',
          'mr-4 flex-shrink-0 h-6 w-6'
        )}
        aria-hidden="true"
        src={menuLink.icon}
        alt=""
      />
      <TranslatedMarkupText id={`sidebar-menu.navigation.${menuLink.path}`} />
    </a>
  );
};

export default SidebarMenuLink;
