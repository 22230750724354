import { number } from 'card-validator';
import { Project } from '../../models/Project';
import { computeAverageRatingsNote } from '../../services/helpers/computeAverageRatingsNote';
import { isDateAfterNow } from '../../services/helpers/isDateAfterNow';
import BottomAction from '../BottomAction/BottomAction';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
interface Props {
  project: Project;
  isDisabled:boolean;
  onInvest: () => void;
  onSimulate: () => void;
}

const ProjectBottomAction: React.FC<Props> = ({
  project,
  isDisabled,
  onInvest,
  onSimulate,
}) => {
  // if (isDateAfterNow(project.startDate)) {
  return (
    <BottomAction
      title={<TranslatedMarkupText id="project-details.add-quantity" />}
      ratings={project.ratings ? computeAverageRatingsNote(project.ratings) : 0}
      totalRating={project.ratings?.length}
      buttonTranslationId="project-details.simulate"
      onClick={onSimulate}
      isFixed
      isDisabled={isDisabled}
    />
  );
  // }
  /*
  return (
    <BottomAction
      title="500€ · 50 parts"
      subtitle={
        <span>
          <TranslatedMarkupText id="project-details.renta" /> 3.33€/
          <TranslatedMarkupText id="project-details.month" />
        </span>
      }
      ratings={project.ratings ? computeAverageRatingsNote(project.ratings) : 0}
      totalRating={project.ratings?.length}
      buttonTranslationId="project-details.invest"
      onClick={onSimulate}
      isFixed
    />
  );
  */
};
export default ProjectBottomAction;
