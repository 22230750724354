import React from 'react';
import { PATH } from '../../constants/path.const';
import { User } from '../../models/User';
import { getUserInitials } from '../../services/helpers/getUserInitials';
import { useIntlRouter } from '../../services/hooks/useIntlRouter';
import Avatar from '../Avatar/Avatar';
import Loader from '../Loader/Loader';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  user: User | undefined;
  isLoading: boolean;
}

const LOADER_HEIGHT = 42;
const LOADER_WIDTH = 42;

const SidebarMenuAvatar: React.FC<Props> = ({ user, isLoading }) => {
  const router = useIntlRouter();
  const goToProfil = () => {
    router.push(PATH.ACCOUNT);
  };

  return (
    <>
      {!isLoading && user && (
        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
          <span
            role="button"
            onClick={() => goToProfil()}
            className="flex-shrink-0 group block"
            tabIndex={0}
          >
            <div className="flex items-center">
              <div className="inline-block h-10 w-10 rounded-full">
                {
                  <Avatar
                    image={user.picture ? user.picture.mediaLink : ''}
                    userInitials={
                      user.lastname && user.firstname
                        ? getUserInitials(user)
                        : '?'
                    }
                    isExpert={false}
                  />
                }
              </div>
              <div className="ml-3">
                <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                  {`${user.firstname ?? ''} ${user.lastname ?? ''}`}
                </p>
                <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                  <TranslatedMarkupText id="sidebar-menu.see-profile" />
                </p>
              </div>
            </div>
          </span>
        </div>
      )}
      {isLoading && !user && (
        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
          <div className="flex items-center mx-4">
            <Loader height={LOADER_HEIGHT} width={LOADER_WIDTH} color="blue" />
          </div>
        </div>
      )}
    </>
  );
};

export default SidebarMenuAvatar;
