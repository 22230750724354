import clsx from 'clsx';
import { size } from 'lodash';
import { useMemo, useState } from 'react';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { isPlatform } from '@ionic/react';

import Download from '../../assets/icons/download.svg';
import downloadFileFromUrl from '../../services/helpers/downloadFileFromUrl';
import useDownloadFile from '../../services/hooks/useDownloadFile';
import { getFirebaseCurrentUserAccessToken } from '../../services/networking/firebase';
// import { getFile } from "../../services/networking/apiClient";

interface Props {
  images: { src: string; fileId?: string; name?: string }[];
}

const Attachment: React.FC<{ src: string; fileId?: string; name?: string }> = ({
  src,
  fileId,
  name,
}) => {
  const getFileNameAndExtension = useMemo(() => {
    const file = {
      fileName: '',
      fileExtension: '',
    };
    const splitSrcForFileName = src.split('/');
    file.fileName = splitSrcForFileName[size(splitSrcForFileName) - 1].replace(
      /\.[^/.]+$/,
      ''
    );

    const splitSrcForFileExtension = src.split('.');
    file.fileExtension =
      splitSrcForFileExtension[size(splitSrcForFileExtension) - 1];

    return file;
  }, [src]);

  const { fileName, fileExtension } = getFileNameAndExtension;

  const { downloadFile, isDownloading } = useDownloadFile({
    apiRequest: downloadFileFromUrl,
    fileName: fileName,
    fileExtension: fileExtension,
  });

  //TODO:Try to use this function for download ! To move in
  //TODO:helper folder if everything's fine !

  // `${fileName}.${fileExtension}`
  const baseUrl = process.env.REACT_APP_PUBLIC_API_BASE_URL as string;



  const downloadFileLocally = async (filename:string, ext:string):Promise<void> => {
    const BASE_URL = process.env.REACT_APP_PUBLIC_API_BASE_URL;
    const DEFAULT_FILE_NAME_PREFIX = 'aia_software_';
    const token = await getFirebaseCurrentUserAccessToken();

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function() {

      console.log('on load');

      if (xhr.status === 200) {
        const urlObject = URL.createObjectURL(xhr.response);

        console.log("URL OBJECT ----- ", urlObject);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.download = `${ !filename ?
          `${DEFAULT_FILE_NAME_PREFIX}${new Date().getTime().toLocaleString()}` : filename
        }.${ext}`;
        a.target = "_blank";
        a.href = urlObject;
        a.click();
        document.body.removeChild(a);
      }
    }
    xhr.open('GET', `${BASE_URL}/files/${fileName}.${fileExtension}`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);

    xhr.send();
  }

  return (
    <>
      {/* <a href={src} target="_blank" rel="noreferrer"> */}
      <div
        className={clsx(
          'text-left inline-block relative w-11/12 p-5 rounded-lg drop-shadow-xl bg-white my-5'
        )}
        key={fileId}
        role="button"
        tabIndex={0}
        // onClick={() => void downloadFile(`${fileName}.${fileExtension}`)}
        onClick={() => void downloadFileLocally(fileName, fileExtension)}
        >
        <div className={clsx('flex justify-between')}>
          <p className="truncate w-1/4">{name}</p>
          <p className="truncate w-1/2">{fileName}</p>
          <img src={Download} alt="download" />
        </div>
      </div>
      {/* </a> */}
    </>
  );
};

const AttachmentList: React.FC<Props> = ({ images }) => {
  return (
    <div className="flex-col w-full text-center">
      {images.map((image, index) => (
        <Attachment
          key={index}
          src={image.src}
          fileId={image.fileId}
          name={image.name}
        />
      ))}
    </div>
  );
};

export default AttachmentList;