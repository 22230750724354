import { Rating } from '../../models/Rating';
import { groupRatingsByValue } from './groupRatingsByValue';

export const computeAverageRatingsNote = (ratings: Rating[]): number => {
  const ratingsByNote = groupRatingsByValue(ratings);

  const notesWeightedCount =
    5 * ratingsByNote[5].length +
    4 * ratingsByNote[4].length +
    3 * ratingsByNote[3].length +
    2 * ratingsByNote[2].length +
    1 * ratingsByNote[1].length;
  const totalRatings =
    ratingsByNote[1].length +
    ratingsByNote[2].length +
    ratingsByNote[3].length +
    ratingsByNote[4].length +
    ratingsByNote[5].length;

  const averageNote = Math.round((notesWeightedCount / totalRatings) * 10) / 10;

  return isNaN(averageNote) ? 0 : averageNote;
};
