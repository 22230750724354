import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteCurrentUser } from '../networking/apiClient';

export const useDeleteCurrentUser = () => {
  const queryClient = useQueryClient();

  const { data, isError, error, isLoading, mutate } = useMutation(
    deleteCurrentUser,
    {
      onSuccess() {
        queryClient.invalidateQueries(['users', 'me']);
      },
      retry: false,
    }
  );

  return { data, isError, error, isLoading, mutate };
};
