import clsx from 'clsx';
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  checked: boolean;
  onCheck?: () => void;
  translationId?: string;
  label?: string;
}

export const CheckableButton: React.FC<Props> = ({
  checked,
  onCheck,
  label,
  translationId,
}) => {
  return (
    <div className="block p-1 w-full">
      <div
        className={clsx(
          checked
            ? 'p-3 border-2 border-transparent font-semibold rounded-md bg-primary mb-2 text-center'
            : 'p-3 border-2 border-primary font-semibold text-black rounded-md mb-2 text-center'
        )}
        onClick={onCheck}
        aria-hidden="true"
      >
        <div className="flex items-center justify-center">
          {checked && <FaCheck className="mr-2" />}
          {translationId && !label ? (
            <TranslatedMarkupText id={translationId} />
          ) : (
            <span className="text-black font-semibold">{label}</span>
          )}
        </div>
      </div>
    </div>
  );
};
