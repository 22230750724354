import React, { useEffect, useMemo, useState } from 'react';
import { FormikErrors, getIn } from 'formik';
import UploadFileButton from '../UploadFileButton/UploadFileButton';
import { deleteFile, postFile } from '../../../services/networking/apiClient';
import FileUploadErrorBar from '../FileUploadErrorBar/FileUploadErrorBar';
import FileUploadLoadingBar from '../FileUploadLoadingBar/FileUploadLoadingBar';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';
import ImageList from '../../ImageList/ImageList';
import { Media } from '../../../models/Media';

interface Props {
  fileTranslationId: string;
  labelTranslationId?: string;
  italicLabelTranslationId?: string;
  onChange: (medias: Media[]) => void;
  currentMedias?: Media[];
  errors?: FormikErrors<{ [field: string]: unknown }>;
  name: string;
  resetValue?: boolean;
  setResetValue?: (resetValue: boolean) => void;
  isSingleMedia?: boolean;
}

const ACCEPTED_FILE_EXTENSIONS =
  '.xlsx,.xls,.ods,.docx,.docm,.doc,.csv,.pdf,.txt,.gif,.jpg,.jpeg,.png,.tif,.tiff,.rtf,.bmp,.cgm,.css,.shtml,.html,.htm,.zip,.xml,.ppt,.pptx,.tar,.ez,.ics,.mobi,.msg,.pub,.eps,.odt,.mp3,.m4a,.m4v,.wma,.ogg,.flac,.wav,.aif,.aifc,.aiff,.mp4,.mov,.avi,.mkv,.mpeg,.mpg,.wmv';

const InputMedia: React.FC<Props> = ({
  fileTranslationId,
  labelTranslationId,
  italicLabelTranslationId,
  onChange,
  errors,
  name,
  resetValue,
  setResetValue,
  currentMedias,
  isSingleMedia = false,
}) => {
  const [medias, setMedias] = useState<Media[]>(
    currentMedias ? currentMedias : []
  );
  const [failedUploadedFiles, setFailedUploadedFiles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const error = useMemo(() => getIn(errors, name), [errors, name]);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error]);

  // Reset input value
  useEffect(() => {
    if (resetValue && setResetValue) {
      setMedias([]);
      setFailedUploadedFiles([]);
      setResetValue(false);
    }
  }, [resetValue, setResetValue]);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.value = '';
      hiddenFileInput.current.click();
    }
  };

  const handleDelete = async (index: number) => {
    await deleteFile(medias[index].fileId);
    const newMedias = medias;
    newMedias.splice(index, 1);
    setMedias([...newMedias]);
    onChange([...newMedias]);
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false);
    const file = event.currentTarget.files?.[0];
    if (file) {
      setIsLoading(true);
      let response = null;
      response = await postFile(file);
      setIsLoading(false);
      if (!response?.fileId) {
        setFailedUploadedFiles([...failedUploadedFiles, file.name]);
      } else {
        const newMedias = isSingleMedia ? [response] : [...medias, response];
        setMedias(newMedias);
        onChange(newMedias);
      }
    }
  };

  const imagesList = useMemo(() => {
    return medias.map((media) => {
      return { src: media.mediaLink, fileId: media.fileId };
    });
  }, [medias]);

  return (
    <>
      <ImageList
        images={imagesList}
        additionalImageClassName="object-contain h-fileInputImage w-full"
        onClickDeleteImage={(fileId) => console.log('deleteFile', fileId)}
        isHorizontalScroll
      />
      <FileUploadErrorBar
        failedUploadedFiles={failedUploadedFiles}
        setFailedUploadedFiles={setFailedUploadedFiles}
      />
      {isLoading && <FileUploadLoadingBar />}
      {labelTranslationId && (
        <h3 className="text-primary text-l font-normal mb-1">
          <TranslatedMarkupText id={labelTranslationId} />
          {italicLabelTranslationId && (
            <>
              {' '}
              <span className="italic">
                <TranslatedMarkupText id={italicLabelTranslationId} />
              </span>
            </>
          )}
        </h3>
      )}
      <UploadFileButton
        hasError={hasError}
        onClick={handleClick}
        documentType={fileTranslationId}
        isLoading={false}
        isDisabled={false}
        italicLabelTranslationId={italicLabelTranslationId}
      />
      <div className="relative flex">
        <input
          id={name}
          name="file"
          type="file"
          ref={hiddenFileInput}
          onChange={(e) => void handleChange(e)}
          style={{ display: 'none' }}
          accept={ACCEPTED_FILE_EXTENSIONS}
        />
        {error && hasError && (
          <p className="text-error text-sm mt-1">
            <TranslatedMarkupText id={`formValidation.${error as string}`} />
          </p>
        )}
      </div>
    </>
  );
};

export default InputMedia;
