import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import withAuth from '../../services/hoc/withAuth';
import { useFetchCurrentUserWallet } from '../../services/hooks/useFetchCurrentUserWallet';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import Loader from '../../components/Loader/Loader';
import PaymentCardListModal from '../../components/PaymentCardListModal/PaymentCardListModal';
import BasicButton from '../../components/BasicButton/BasicButton';
import TopUpWalletFormModal from '../../components/TopUpWalletFormModal/TopUpWalletFormModal';

const LOADER_WIDTH = 42;
const LOADER_HEIGHT = 42;

const Wallet: React.FC = () => {
  const {
    data: userWallet,
    isLoading,
    refetch: refetchCurrentUserWallet,
  } = useFetchCurrentUserWallet();
  const [isPaymentCardListModalOpen, setIsPaymentCardListModalOpen] =
    useState(false);
  const [isTopUpWalletFormModalOpen, setIsTopUpWalletFormModalOpen] =
    useState(false);

  return (
    <>
      <Layout header={<Header />} isScreenHeight>
        <PaymentCardListModal
          isOpen={isPaymentCardListModalOpen}
          onClose={() => setIsPaymentCardListModalOpen(false)}
        />
        <TopUpWalletFormModal
          isOpen={isTopUpWalletFormModalOpen}
          onClose={() => setIsTopUpWalletFormModalOpen(false)}
          refetchWallet={() => void refetchCurrentUserWallet()}
        />
        <div className="pt-5 px-5 text-black text-l font-semibold">
          <TranslatedMarkupText id="wallet.title" />
        </div>

        <div className="bg-lightGrey mt-5 p-5 flex flex-col justify-center items-center">
          <h1 className="font-bold text-black text-5xl">
            {userWallet && !isLoading && (
              <TranslatedMarkupText
                id="wallet.price"
                values={{ price: userWallet.balance }}
              />
            )}
            {isLoading && (
              <Loader
                color="blue"
                height={LOADER_HEIGHT}
                width={LOADER_WIDTH}
              />
            )}
          </h1>
        </div>
        <div className="w-full px-5 mt-5 md:w-3/5 md:mx-auto">
          <BasicButton
            className="w-full"
            translationId="wallet.top-up-wallet"
            onClick={() => setIsTopUpWalletFormModalOpen(true)}
          />
        </div>
        <div className="w-full px-5 mt-5 md:w-3/5 md:mx-auto">
          <BasicButton
            className="w-full"
            translationId="wallet.payment-methods"
            onClick={() => setIsPaymentCardListModalOpen(true)}
          />
        </div>
      </Layout>
    </>
  );
};

export default withAuth(Wallet);
