export const fetchGooglePlacesAutocomplete = (
  queryValue: string,
  divRef: HTMLDivElement | null
): Promise<{
  results: google.maps.places.PlaceResult[] | null;
  status: google.maps.places.PlacesServiceStatus;
}> | null => {
  if (!divRef || !queryValue) return null;
  const service = new google.maps.places.PlacesService(divRef);

  const request: google.maps.places.TextSearchRequest = {
    query: queryValue,
    type: 'address',
  };

  return new Promise((resolve, reject) => {
    try {
      service.textSearch(
        request,
        (
          results: google.maps.places.PlaceResult[] | null,
          status: google.maps.places.PlacesServiceStatus
        ) => {
          resolve({ results, status });
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};
