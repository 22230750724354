import React, { useEffect, useMemo, useRef, useState } from 'react';
import BasicButton from '../../BasicButton/BasicButton';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';
import { useFetchCurrentUserPaymentCards } from '../../../services/hooks/useFetchCurrentUserPaymentCards';
import PaymentCardFormModal from '../PaymentCardFormModal/PaymentCardFormModal';
import { useFetchCurrentUserWallet } from '../../../services/hooks/useFetchCurrentUserWallet';

export enum PAYMENT_METHOD_TYPE {
  WALLET = 'WALLET',
  CARD = 'CARD',
}

export interface PaymentMethodChoiceEvent {
  type: PAYMENT_METHOD_TYPE;
  cardId?: string;
}

interface Props {
  onChange: (event: PaymentMethodChoiceEvent) => void;
  withWallet?: boolean;
  withTitle?: boolean;
}

const PaymentMethodChoice: React.FC<Props> = ({
  onChange,
  withWallet = false,
  withTitle = false,
}) => {
  const {
    data: paymentCards,
    isLoading: isFetchUserPaymentCards,
    refetch: refetchCurrentUserPaymentCards,
  } = useFetchCurrentUserPaymentCards();
  const { data: userWallet, isLoading: isFetchUserWalletLoading } =
    useFetchCurrentUserWallet();
  const [isPaymentCardFormModalOpen, setIsPaymentCardFormModalOpen] =
    useState(false);

  const presentingElement = useRef<HTMLIonModalElement>(
    document.createElement('ion-modal')
  );

  const isLoading = useMemo(
    () => isFetchUserPaymentCards || isFetchUserWalletLoading,
    [isFetchUserPaymentCards, isFetchUserWalletLoading]
  );

  return (
    <>
      <PaymentCardFormModal
        isOpen={isPaymentCardFormModalOpen}
        onClose={() => void setIsPaymentCardFormModalOpen(false)}
        refetchPaymentCards={() => void refetchCurrentUserPaymentCards()}
        ionicPresentingElement={presentingElement.current}
      />
      {withTitle && (
        <div className="pt-5 text-black text-l font-semibold">
          <TranslatedMarkupText id="payment-card-list.title" />
        </div>
      )}
      {!isLoading && (!paymentCards || paymentCards.length === 0) && (
        <div className="p-5">
          <h1 className="text-sm text-center text-mediumGrey">
            <TranslatedMarkupText id="payment-card-list.no-payment-methods" />
          </h1>
        </div>
      )}
      {!isLoading && paymentCards && (
        <div className="divide-y divide-gray-200">
          {withWallet && (
            <div className="relative flex items-start py-4">
              <label
                htmlFor="radio-wallet"
                className="min-w-0 flex-1 h-full w-full text-sm font-medium text-gray-700"
              >
                <div>
                  <TranslatedMarkupText id="payment-card-list.my-balance" />{' '}
                  <TranslatedMarkupText
                    id="payment-card-list.price"
                    values={{ price: userWallet?.balance }}
                  />
                </div>
                <div className="text-gray-500">{userWallet?.description}</div>
              </label>
              <div
                className="ml-3 flex items-center h-5"
                // we need this to prevent ion-content adding offset when focusing radio input
                onFocus={(e) => e.stopPropagation()}
              >
                <input
                  onChange={() =>
                    onChange({
                      type: PAYMENT_METHOD_TYPE.WALLET,
                    })
                  }
                  id="radio-wallet"
                  aria-describedby="radio-wallet"
                  name="payment-method"
                  type="radio"
                  defaultChecked={true}
                  className="form-radio focus:ring-0 focus:outline-0 h-4 w-4 text-primary border-gray-300"
                />
              </div>
            </div>
          )}
          {paymentCards.map((paymentCard, index) => (
            <div key={index} className="relative flex items-start py-4">
              <label
                htmlFor={`${paymentCard.Id}`}
                className="min-w-0 flex-1 text-sm h-full w-full"
              >
                <label className="font-medium text-gray-700">
                  <div>{paymentCard.Tag}</div>
                  <div>{paymentCard.CardType}</div>
                </label>
                <p
                  id={`account-${paymentCard.Id}-description`}
                  className="text-gray-500"
                >
                  {paymentCard.Alias}
                </p>
              </label>
              <div
                className="ml-3 flex items-center h-5"
                // we need this to prevent ion-content adding offset when focusing radio input
                onFocus={(e) => e.stopPropagation()}
              >
                <input
                  onChange={(event) =>
                    onChange({
                      type: PAYMENT_METHOD_TYPE.CARD,
                      cardId: event.target.id,
                    })
                  }
                  id={`${paymentCard.Id}`}
                  aria-describedby={`account-${paymentCard.Id}-description`}
                  name="payment-method"
                  type="radio"
                  className="form-radio focus:ring-0 focus:outline-0 h-4 w-4 text-primary border-gray-300"
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="my-5 w-full">
        <BasicButton
          className="w-full"
          translationId="payment-card-list.add-payment-method"
          onClick={() => setIsPaymentCardFormModalOpen(true)}
        />
      </div>
    </>
  );
};

export default PaymentMethodChoice;
