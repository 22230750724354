import React from 'react';
import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import ProjectList, {
  PROJECT_LIST_STYLES,
} from '../../components/ProjectList/ProjectList';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import withAuth from '../../services/hoc/withAuth';
import { useFetchCurrentUserFavorites } from '../../services/hooks/useFetchCurrentUserFavorites';

const LOADER_HEIGHT = 42;
const LOADER_WIDTH = 42;

const Favorites: React.FC = () => {
  const { data: favorites, isLoading } = useFetchCurrentUserFavorites();

  return (
    <Layout header={<Header />}>
      <div className="max-w-5xl md:mx-auto">
        <h1 className="font-bold text-black text-pageTitle p-4">
          <TranslatedMarkupText id="favorites.title" />
        </h1>
        {!isLoading && favorites && (
          <ProjectList
            projects={favorites}
            style={PROJECT_LIST_STYLES.MEDIUM}
          />
        )}
        {isLoading && (
          <div className="flex items-center justify-center w-full">
            <Loader height={LOADER_HEIGHT} width={LOADER_WIDTH} color="blue" />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withAuth(Favorites);
