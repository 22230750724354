import { useQuery } from 'react-query';
import { getCurrentProjectBalances } from '../networking/apiClient';

export const useFetchCurrentProjectBalances = (id:number) => {
  const { data, isError, error, isLoading, refetch } = useQuery(
    'getCurrentProjectBalances',
    () => getCurrentProjectBalances(id),
    {
      retry: false,
    }
  );

  return { data, isError, error, isLoading, refetch };
};
