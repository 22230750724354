import { useFetchCurrentUserPaymentCards } from '../../services/hooks/useFetchCurrentUserPaymentCards';
import PaymentCardList from '../PaymentCardList/PaymentCardList';
import SwipeableModalHeader from '../SwipeableModalHeader/SwipeableModalHeader';
import SwipeableModalLayout, {
  SwipeableModalProps,
} from '../SwipeableModalLayout/SwipeableModalLayout';

const PaymentCardListModal: React.FC<SwipeableModalProps> = (props) => {
  const { data: paymentCards, isLoading } = useFetchCurrentUserPaymentCards();

  return (
    <SwipeableModalLayout
      {...props}
      header={
        <SwipeableModalHeader
          translationId="payment-card-list.title"
          onClose={() => props.onClose?.()}
        />
      }
    >
      {!isLoading && paymentCards && (
        <div className="px-5 h-full">
          <PaymentCardList />
        </div>
      )}
    </SwipeableModalLayout>
  );
};

export default PaymentCardListModal;
