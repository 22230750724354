import React, { useEffect, useMemo } from 'react';
import BasicButton from '../../components/BasicButton/BasicButton';
import Header from '../../components/Header/Header';
import Layout from '../../components/Layout/Layout';
import ProjectList, {
  PROJECT_LIST_STYLES,
} from '../../components/ProjectList/ProjectList';
import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';
import withAuth from '../../services/hoc/withAuth';
import { useFetchCurrentUserBalances } from '../../services/hooks/useFetchCurrentUserBalances';
import ChevronRightIcon from '../../assets/icons/chevron-right.svg';
import CircleChevronUpIcon from '../../assets/icons/circle-chevron-up.svg';
import StatsIcon from '../../assets/icons/stats.svg';
import { computeRoundedNumber } from '../../services/helpers/computeRoundedNumber';
import { computeProfitPerMonth } from '../../services/helpers/computeProfitPerMonth';

const MOCK_WALLET_FUNDS = 1478;

const InvestmentPortfolio: React.FC = () => {
  const { data: balances, isLoading } = useFetchCurrentUserBalances();
  const projects = useMemo(
    () => balances?.flatMap((balance) => balance.project),
    [balances]
  );

  const totalBalance = useMemo(
    () =>
      balances
        ?.flatMap((balance) => balance.project.sharePrice * balance.amount)
        .reduce((acc, curr) => acc + curr, 0),
    [balances]
  );

  const monthlyRevenue = useMemo(
    () =>
      balances
        ?.flatMap((balance) =>
          computeProfitPerMonth(10, balance.project.sharePrice, balance.amount)
        )
        .reduce((acc, curr) => acc + curr, 0),
    [balances]
  );

  const yearlyPercentageRentability = useMemo(() => {
    return (
      monthlyRevenue &&
      totalBalance &&
      computeRoundedNumber(((monthlyRevenue * 12) / totalBalance) * 100, 2)
    );
  }, [monthlyRevenue, totalBalance]);

  return (
    <Layout header={<Header />}>
      {!isLoading && projects && (
        <div>
          <div className="bg-lightGrey p-5 flex flex-col justify-center items-center">
            {/* <div className=" w-full mb-16 flex items-center">
              <span className="text-gray-500 text-md">
                <TranslatedMarkupText id="wallet.my-profile" />
              </span>
              <img
                className="inline"
                src={ChevronRightIcon}
                alt="right-chevron"
              />
              <span className="text-gray-500 text-md">
                <TranslatedMarkupText id="wallet.my-wallet" />
              </span>
            </div> */}
            <h1 className="font-bold text-black text-5xl">
              <TranslatedMarkupText
                id="wallet.price"
                values={{ price: totalBalance }}
              />
            </h1>
            {/* TO-DO should be a component without mock data */}
            <div className="mt-1 flex items-center">
              <img
                className="mr-1"
                src={CircleChevronUpIcon}
                alt="circle-chevron-up"
              />
              {monthlyRevenue && monthlyRevenue > 0 && (
                <p className="text-mediumGrey">
                  {` ${computeRoundedNumber(monthlyRevenue, 2)}€/`}
                  <TranslatedMarkupText id="project-card.month" />
                </p>
              )}
              <span className="mr-8"></span>
              <img className="mr-1" src={StatsIcon} alt="stat-up" />
              <span>{yearlyPercentageRentability}%</span>
            </div>

            <div className="mt-8">
              <BasicButton
                translationId="wallet.reinvest-my-earnings"
                onClick={() => alert('reinvestir mes gains')}
                className="mb-5"
              />
            </div>
          </div>
          <h1 className="font-bold text-black text-pageTitle p-5">
            <TranslatedMarkupText id="wallet.investment-wallet" />
          </h1>
          <ProjectList
            projects={projects}
            balances={balances}
            style={PROJECT_LIST_STYLES.SMALL}
          />
        </div>
      )}
    </Layout>
  );
};

export default withAuth(InvestmentPortfolio);
