import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import CrossIcon from '../../assets/icons/cross.svg';

interface Props {
  translationId: string;
  onClose: () => void;
}

const SwipeableModalHeader: React.FC<Props> = ({ translationId, onClose }) => {
  return (
    <div className="pt-5 px-5 text-black text-l font-semibold flex flex-row justify-between rounded-t-2xl">
      <TranslatedMarkupText id={translationId} />
      <img className="h-6 w-6" src={CrossIcon} alt="cross" onClick={onClose} />
    </div>
  );
};

export default SwipeableModalHeader;
