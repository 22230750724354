import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useIntl } from 'react-intl';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FormikErrors } from 'formik';
import { colorUsage } from '../../../stylesheet';
import InputDatePicker from '../InputDatePicker/InputDatePicker';

interface Props {
  placeholderTranslationId: string;
  onSubmit: (date: Date | null) => void;
  value: Date | null;
  errors?: FormikErrors<{ [field: string]: unknown }>;
  name: string;
}

// LocalizedUtils allows us to customize date picker value format
class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const MATERIAL_DATE_PICKER_THEME = createTheme({
  palette: {
    primary: {
      main: colorUsage.primary,
    },
  },
});

const FR_DATE_FORMAT = 'dd/MM/yyyy';

const renderCustomTextInput: React.FC<{
  placeholderTranslationId: string;
  onDelete: () => void;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  value?: string;
  errors?: FormikErrors<{ [field: string]: unknown }>;
  name: string;
}> = ({
  onChange,
  onClick,
  onDelete,
  value,
  errors,
  placeholderTranslationId,
  name,
}) => {
  return (
    <InputDatePicker
      placeholderTranslationId={placeholderTranslationId}
      onChange={onChange}
      value={value}
      onClick={onClick}
      onDelete={onDelete}
      errors={errors}
      name={name}
      isDisabled
    />
  );
};

const MuiDatePicker: React.FC<Props> = ({
  placeholderTranslationId,
  onSubmit,
  value,
  errors,
  name,
}) => {
  const intl = useIntl();

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={fr}>
      <ThemeProvider theme={MATERIAL_DATE_PICKER_THEME}>
        <DatePicker
          format={FR_DATE_FORMAT}
          value={value}
          onChange={(date: MaterialUiPickersDate) =>
            date && onSubmit(new Date(date.setHours(0, 0, 0, 0)))
          }
          animateYearScrolling
          TextFieldComponent={(props: any) =>
            renderCustomTextInput({
              placeholderTranslationId: placeholderTranslationId,
              value: props.value,
              onClick: props.onClick,
              onChange: props.onChange,
              onDelete: () => onSubmit(null),
              name: name,
              errors: errors,
            })
          }
          cancelLabel={intl.formatMessage({ id: 'datePicker.cancel' })}
          okLabel={intl.formatMessage({ id: 'datePicker.ok' })}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default MuiDatePicker;
