import { IonRouterLink } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PATH } from "../../constants/path.const";
import { Balance } from "../../models/Balance";
import { Project } from "../../models/Project";
import { computeProfitPerMonth } from "../../services/helpers/computeProfitPerMonth";
import { computeRoundedNumber } from "../../services/helpers/computeRoundedNumber";
import { getInternationalizedPath } from "../../services/helpers/getInternationalizedPath";
import TranslatedMarkupText from "../TranslatedMarkupText/TranslatedMarkupText";
import ResellSharesModal from "../ResellSharesModal/ResellSharesModal";

interface Props {
  project: Project;
  balance?: Balance;
}

const ProjectSmallCard: React.FC<Props> = ({ project, balance }) => {
  const intl = useIntl();
  const [renderedProject, setRenderedProject] = useState(project);

  useEffect(() => {
    if (balance) {
      setRenderedProject(balance.project);
    }
  }, [balance]);
  const [isResellModalOpen, setIsResellModalOpen] = useState(false);

  const handleResellClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsResellModalOpen(true);
  };
  const handleCloseResellModal = () => {
    setIsResellModalOpen(false);
  };

  return (
    <>
      <IonRouterLink
        href={getInternationalizedPath(
          PATH.PROJECT_DETAILS,
          intl,
          `/${project.id}`
        )}
        className="focus:opacity-50"
      >
        <div className="flex p-4">
          <div className="mr-4 flex-shrink-0">
            <img
              className="h-20 w-20 bg-white object-cover rounded"
              src={renderedProject.cover?.mediaLink}
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center w-full">
            <h4 className="text-base font-bold text-black">
              {renderedProject.name}
            </h4>
            <div className="mt-1 flex justify-between text-projectCardBase">
              <p className="text-darkGrey">
                {`${balance ? balance.amount : renderedProject.shareAmount} `}
                <TranslatedMarkupText id="project-card.shares" />
              </p>
              {balance && balance.amount > 0 && (
                <p className="text-mediumGrey">
                  <TranslatedMarkupText id="project-card.profitability" />
                  {` ${computeRoundedNumber(
                    computeProfitPerMonth(
                      10,
                      project.sharePrice,
                      balance.amount
                    ),
                    2
                  )}€/`}
                  <TranslatedMarkupText id="project-card.month" />
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse justify-between px-4 pb-4">
          {renderedProject.shareAmount > 0 && (
            <p
              className="text-link text-projectCardBase font-bold"
              onClick={handleResellClick}
            >
              <TranslatedMarkupText id="project-card.sell-my-shares" />
            </p>
          )}
          {isResellModalOpen && balance && (
        <ResellSharesModal
          isOpen={isResellModalOpen}
          onClose={handleCloseResellModal}
          project={project}
          balance={balance}
        />
      )}
        </div>
      </IonRouterLink>
      
    </>
  );
};

export default ProjectSmallCard;
