import { useContext, useState } from 'react';
import { FaSearch, FaSlidersH } from 'react-icons/fa';

import { useIntl } from 'react-intl';
import Layout from '../../components/Layout/Layout';
import Header from '../../components/Header/Header';
import TabBar from '../../components/TabBar/TabBar';

import ProjectInfiniteList from '../../components/ProjectInfiniteList/ProjectInfiniteList';
import { ProjectStatus } from '../../models/Project';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import SearchInputText from '../../components/Forms/SearchInputText/SearchInputText';
import { ProjectFilterModal } from '../../components/ProjectFilterModal/ProjectFilterModal';
import { FilterContext } from '../../services/context/projectFilterContext';
import { formatQueryFilter } from '../../services/helpers/formatFilterRequest';

const Projects: React.FC = () => {
  const intl = useIntl();
  const [tab, setTab] = useState(ProjectStatus.PENDING_LAUNCH);
  const [visibleSearch, setVisibleSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [visibleFilterModal, setVisibleFilterModal] = useState(false);
  const { filterContext, setFilterContext } = useContext(FilterContext);
  const options = [
    {
      title: intl.formatMessage({ id: 'projects.projects' }),
      onSelect: () => setTab(ProjectStatus.PENDING_LAUNCH),
    },
    {
      title: intl.formatMessage({ id: 'projects.goods' }),
      onSelect: () => setTab(ProjectStatus.ACTIVE),
    },
    {
      title: intl.formatMessage({ id: 'projects.closed' }),
      onSelect: () => setTab(ProjectStatus.CLOSED),
    },
  ];

  const icons = [
    {
      component: (
        <FaSearch
          size="18"
          onClick={() => {
            setVisibleSearch(!visibleSearch);
          }}
          className="ml-5 mt-1 font-normal"
        />
      ),
    },
    {
      component: (
        <FaSlidersH
          size="18"
          onClick={() => {
            setVisibleFilterModal(true);
          }}
          className="ml-5 mt-1 font-normal"
        />
      ),
    },
  ];

  const onSubmitFilter = () => {
    const queryStringFilter = formatQueryFilter(filterContext);
  };

  return (
    <Layout header={<Header />}>
      <TabBar options={options} icons={[]} />
      {visibleSearch && (
        <div className="block bg-black z-50 w-full h-12">
          <SearchInputText
            placeholderTranslationId="search"
            value={search}
            name="search"
            onChange={setSearch}
            onDelete={() => {
              setVisibleSearch(false);
              setSearch('');
            }}
          />
        </div>
      )}
      <ProjectFilterModal
        isOpen={visibleFilterModal}
        onSubmit={() => onSubmitFilter()}
        onClose={() => {
          setVisibleFilterModal(false);
        }}
      />
      {tab === ProjectStatus.PENDING_LAUNCH && (
        <div className="md:mx-auto">
          <ContentHeader
            titleTranslationId="projects.head.housing-projects"
            descriptionTranslationId="projects.head.projects-description"
          />
          <ProjectInfiniteList projectStatus={ProjectStatus.PENDING_LAUNCH} />
        </div>
      )}
      {tab === ProjectStatus.ACTIVE && (
        <div className="md:mx-auto">
          <ContentHeader
            titleTranslationId="projects.goods"
            descriptionTranslationId="projects.head.goods-description"
          />
          <ProjectInfiniteList projectStatus={ProjectStatus.ACTIVE} />
        </div>
      )}
      {tab === ProjectStatus.CLOSED && (
        <div className="md:mx-auto">
          <ContentHeader
            titleTranslationId="projects.head.closed-projects"
            descriptionTranslationId="projects.head.closed-description"
          />
          <ProjectInfiniteList projectStatus={ProjectStatus.CLOSED} />
        </div>
      )}
    </Layout>
  );
};

export default Projects;
