export const until = async (
  conditionFunction: () => Promise<boolean>,
  checkInterval?: number
): Promise<void> => {
  const poll = async (resolve: () => void) => {
    if (await conditionFunction()) resolve();
    else {
      await new Promise<void>((timeoutResolve) =>
        setTimeout((_) => timeoutResolve(), checkInterval ?? 500)
      );
      await poll(resolve);
    }
  };

  return new Promise((untilResolver) => {
    poll(untilResolver);
  });
};
