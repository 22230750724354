export enum MangopayKYCDocumentStatus {
  CREATED = 'CREATED',
  VALIDATION_ASKED = 'VALIDATION_ASKED',
  VALIDATED = 'VALIDATED',
  REFUSED = 'REFUSED',
  OUT_OF_DATE = 'OUT_OF_DATE',
}

export enum MangopayKYCDocumentType {
  IDENTITY_PROOF = 'IDENTITY_PROOF',
  REGISTRATION_PROOF = 'REGISTRATION_PROOF',
  ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION',
  SHAREHOLDER_DECLARATION = 'SHAREHOLDER_DECLARATION',
  ADDRESS_PROOF = 'ADDRESS_PROOF',
}

export interface KYCDocument {
  id: string;
  status: MangopayKYCDocumentStatus;
  type: MangopayKYCDocumentType;
  processedDate: Date;
}
