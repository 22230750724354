import { useQuery } from 'react-query';
import { getCurrentUserLegalOwner } from '../networking/apiClient';

export const useFetchCurrentUserLegalOwner = () => {
  const { data, isError, error, isLoading, refetch } = useQuery(
    'getCurrentUserLegalOwner',
    getCurrentUserLegalOwner,
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return { data, isError, error, isLoading, refetch };
};
