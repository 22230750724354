import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import HeartFilled from '../../assets/icons/heart-primary.svg';
import HeartEmpty from '../../assets/icons/heart-outline.svg';
import {
  deleteFavorite,
  POST_SUCCESS_STATUS,
  postFavorite,
  SUCCESS_STATUS,
} from '../../services/networking/apiClient';
import BasicToast from '../BasicToast/BasicToast';
import AddedFavoriteIcon from '../../assets/icons/home-heart.svg';
import ErrorToast from '../ErrorToast/ErrorToast';
import { User } from '../../models/User';
import Warning from '../../assets/icons/warning.svg';


interface Props {
  isFavorite: boolean;
  user?: User;
  projectId: number;
  onUpdate: () => void;
}

const FavoriteButton: React.FC<Props> = ({
  isFavorite,
  user,
  projectId,
  onUpdate,
}) => {
  const intl = useIntl();
  const [isAddFavoriteSuccessToastOpen, setIsAddFavoriteSuccessToastOpen] =
    useState(false);
  const [
    isDeleteFavoriteSuccessToastOpen,
    setIsDeleteFavoriteSuccessToastOpen,
  ] = useState(false);
  const [isUserNotLoggedInToastOpen, setIsUserNotLoggedInToastToastOpen] = useState(false);
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);

  const onClick = async (): Promise<void> => {
    if (!user) {
      setIsUserNotLoggedInToastToastOpen(true);
      
      return;
    }

    if (isFavorite) {
      const response = await deleteFavorite(projectId);
      if (response?.status === SUCCESS_STATUS) {
        setIsDeleteFavoriteSuccessToastOpen(true);
      } else {
        setIsErrorToastOpen(true);
      }
    } else {
      const response = await postFavorite(projectId);
      if (response?.status === POST_SUCCESS_STATUS) {
        setIsAddFavoriteSuccessToastOpen(true);
      } else {
        setIsErrorToastOpen(true);
      }
    }
    onUpdate();
  };

  return (
    <>
      {!user && (
        <BasicToast
        isOpen={isUserNotLoggedInToastOpen}
        onClose={() => setIsUserNotLoggedInToastToastOpen(false)}
        titleTranslationId="notifications.error.title"
        messageTranslationId="notifications.error.message"
        icon={Warning}
      />
      )}

      <BasicToast
        isOpen={isAddFavoriteSuccessToastOpen}
        onClose={() => setIsAddFavoriteSuccessToastOpen(false)}
        titleTranslationId="favorites.add-favorite-toast.title"
        messageTranslationId="favorites.add-favorite-toast.message"
        icon={AddedFavoriteIcon}
      />
      <BasicToast
        isOpen={isDeleteFavoriteSuccessToastOpen}
        onClose={() => setIsDeleteFavoriteSuccessToastOpen(false)}
        titleTranslationId="favorites.delete-favorite-toast.title"
        messageTranslationId="favorites.delete-favorite-toast.message"
        icon={AddedFavoriteIcon}
      />
      <ErrorToast
        isOpen={isErrorToastOpen}
        onClose={() => void setIsErrorToastOpen(false)}
      />
      <button
        className="rounded-full bg-gray-100 p-2"
        onClick={() => void onClick()}
      >
        <img
          src={isFavorite ? HeartFilled : HeartEmpty}
          alt={intl.formatMessage({ id: 'alts.favorite' })}
        />
      </button>
    </>
  );
};

export default FavoriteButton;
