import { useQuery } from 'react-query';
import { getCurrentUserPaymentCards } from '../networking/apiClient';

export const useFetchCurrentUserPaymentCards = () => {
  const { data, isError, error, isLoading, refetch } = useQuery(
    'getCurrentUserPaymentCards',
    getCurrentUserPaymentCards,
    {
      retry: false,
    }
  );

  return { data, isError, error, isLoading, refetch };
};
