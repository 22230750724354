import React from 'react';
import { IonRouterLink } from '@ionic/react';
import { useIntl } from 'react-intl';
import { Project } from '../../models/Project';
import PendingProjectBody from './PendingProjectBody';
import PendingProjectHeader from './PendingProjectHeader';
import ActiveProjectHeader from './ActiveProjectHeader';
import ActiveProjectBody from './ActiveProjectBody';
import { isDateAfterNow } from '../../services/helpers/isDateAfterNow';
import { getInternationalizedPath } from '../../services/helpers/getInternationalizedPath';
import { PATH } from '../../constants/path.const';
import { getShortAddress } from '../../services/helpers/getShortAddress';
import { computeAverageRatingsNote } from '../../services/helpers/computeAverageRatingsNote';
import { Media } from '../../models/Media';

interface Props {
  project: Project;
}

const ProjectCard: React.FC<Props> = ({ project }) => {
  const intl = useIntl();

  return (
    <IonRouterLink
      href={getInternationalizedPath(
        PATH.PROJECT_DETAILS,
        intl,
        `/${project.id}`
      )}
      className="text-primary md:inline-flex md:flex-wrap md:flex-col md:w-1/2 lg:w-1/3"
    >
      <div className="w-full my-4 sm:px-4">
        <div
          className="relative h-60 w-full block bg-cover text-center overflow-hidden"
          style={{ backgroundImage: `url("${project.cover?.mediaLink}")` }}
          title="Mountain"
        >
          {isDateAfterNow(project.startDate) ? (
            <ActiveProjectHeader
              rating={
                project.ratings ? computeAverageRatingsNote(project.ratings) : 0
              }
              avatars={[
                'https://play-lh.googleusercontent.com/aFWiT2lTa9CYBpyPjfgfNHd0r5puwKRGj2rHpdPTNrz2N9LXgN_MbLjePd1OTc0E8Rl1',
                'https://play-lh.googleusercontent.com/aFWiT2lTa9CYBpyPjfgfNHd0r5puwKRGj2rHpdPTNrz2N9LXgN_MbLjePd1OTc0E8Rl1',
              ]}
            />
          ) : (
            <PendingProjectHeader
              totalRatings={project.ratings ? project.ratings.length : 0}
              rating={
                project.ratings ? computeAverageRatingsNote(project.ratings) : 0
              }
              name={project.author.firstname ? project.author.firstname : ''}
              lastname={project.author.lastname ? project.author.lastname : ''}
              type={'job'}
              picture={project.author.picture}
            />
          )}
        </div>
        {isDateAfterNow(project.startDate) ? (
          <ActiveProjectBody
            title={project.name}
            location={getShortAddress(project.address)}
            price={project.sharePrice}
          />
        ) : (
          <PendingProjectBody
            title={project.name}
            location={getShortAddress(project.address)}
          />
        )}
      </div>
    </IonRouterLink>
  );
};

export default ProjectCard;
