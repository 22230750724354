import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import CardValidator from 'card-validator';
import {
  MangopayCardRegistration,
  MangopayPaymentCardDetails,
} from '../../../models/MangopayCard';
import {
  postCurrentUserMangopayCardRegistration,
  postCurrentUserMangopayTokenizedCardDetails,
  postTokenizeMangopayCardDetails,
} from '../../../services/networking/apiClient';
import BasicButton from '../../BasicButton/BasicButton';
import SwipeableModalLayout, {
  SwipeableModalProps,
} from '../../SwipeableModalLayout/SwipeableModalLayout';
import TranslatedMarkupText from '../../TranslatedMarkupText/TranslatedMarkupText';
import InputPaymentCard from '../InputPaymentCard/InputPaymentCard';
import ErrorToast from '../../ErrorToast/ErrorToast';
import SwipeableModalHeader from '../../SwipeableModalHeader/SwipeableModalHeader';

const DEFAULT_CVX_MAX_LENGTH = 3;
const DEFAULT_EXPIRATION_DATE_MAX_LENGTH = 5;
const DEFAULT_NUMBER_MAX_LENGTH = 16;

type paymentCardFormData = {
  cardNumber: string;
  cardExpirationDate: string;
  cardCvc: string;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  cardNumber: Yup.string().test(
    'cardNumber',
    (value) => CardValidator.number(value).isValid
  ),
  cardExpirationDate: Yup.string().test(
    'cardExpirationDate',
    (value) => CardValidator.expirationDate(value).isValid
  ),
  cardCvc: Yup.string().test(
    'cardCvc',
    (value) => CardValidator.cvv(value).isValid
  ),
});

interface Props extends SwipeableModalProps {
  refetchPaymentCards: () => void;
}

const PaymentCardFormModal: React.FC<Props> = ({
  isOpen,
  onClose,
  ionicPresentingElement,
  refetchPaymentCards,
}) => {
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  return (
    <>
      <ErrorToast
        isOpen={isSubmitError}
        onClose={() => setIsSubmitError(false)}
      />
      <SwipeableModalLayout
        isOpen={isOpen}
        header={
          <SwipeableModalHeader
            translationId="payment-card-form.title"
            onClose={() => onClose?.()}
          />
        }
        ionicPresentingElement={ionicPresentingElement}
      >
        <div
          className="bg-white h-full w-full p-5 rounded-t-2xl flex flex-col"
          onFocus={(e) => e.stopPropagation()}
        >
          <Formik
            initialValues={
              {
                cardNumber: '',
                cardExpirationDate: '',
                cardCvc: '',
              } as paymentCardFormData
            }
            validationSchema={VALIDATION_SCHEMA}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values) => {
              setIsSubmitLoading(true);
              const mangopayCardRegistration =
                await postCurrentUserMangopayCardRegistration();
              if (mangopayCardRegistration.PreregistrationData) {
                const mangopayPaymentCardDetails: MangopayPaymentCardDetails = {
                  cardNumber: values.cardNumber,
                  cardCvx: values.cardCvc,
                  // Mangopay accepts a MMYY format for cardExpirationDate
                  cardExpirationDate: values.cardExpirationDate
                    .split('/')
                    .join(''),
                  accessKeyRef: mangopayCardRegistration.AccessKey,
                  data: mangopayCardRegistration.PreregistrationData,
                };

                const tokenizedCardDetails =
                  await postTokenizeMangopayCardDetails(
                    mangopayCardRegistration.CardRegistrationURL,
                    mangopayPaymentCardDetails
                  );

                if (!tokenizedCardDetails) {
                  setIsSubmitLoading(true);
                }
                try {
                  const sendCardDetails: MangopayCardRegistration =
                    await postCurrentUserMangopayTokenizedCardDetails({
                      cardRegistrationId: mangopayCardRegistration.Id,
                      cardRegistrationData: tokenizedCardDetails,
                    });
                  setIsSubmitLoading(false);

                  if (sendCardDetails.CardId) {
                    refetchPaymentCards();
                    onClose?.();
                  }
                } catch (error) {
                  setIsSubmitError(true);
                }
              }
            }}
          >
            {(props) => (
              <form
                className="w-full flex flex-col flex-grow mt-5"
                onSubmit={props.handleSubmit}
              >
                <fieldset>
                  <legend className="block text-sm font-medium text-gray-700">
                    <TranslatedMarkupText id="payment-card-form.card-details" />
                  </legend>
                  <div className="mt-1 bg-white rounded-md shadow-sm -space-y-px">
                    <InputPaymentCard
                      value={props.values.cardNumber}
                      onChange={props.handleChange}
                      name="cardNumber"
                      additionalClassname="rounded-t-md"
                      placeholderTranslationId="payment-card-form.card-number"
                      maxLength={DEFAULT_NUMBER_MAX_LENGTH}
                      errors={props.errors}
                    />

                    <div className="flex -space-x-px">
                      <div className="w-1/2 flex-1 min-w-0">
                        <InputPaymentCard
                          value={props.values.cardExpirationDate}
                          onChange={props.handleChange}
                          name="cardExpirationDate"
                          additionalClassname="rounded-bl-md"
                          placeholderTranslationId="payment-card-form.date-placeholder"
                          maxLength={DEFAULT_EXPIRATION_DATE_MAX_LENGTH}
                          isDate
                          errors={props.errors}
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <InputPaymentCard
                          value={props.values.cardCvc}
                          onChange={props.handleChange}
                          name="cardCvc"
                          additionalClassname="rounded-br-md"
                          placeholderTranslationId="payment-card-form.cvc"
                          maxLength={DEFAULT_CVX_MAX_LENGTH}
                          errors={props.errors}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <BasicButton
                  type="submit"
                  translationId="payment-card-form.submit"
                  className="w-full mt-auto"
                  isLoading={isSubmitLoading}
                />
              </form>
            )}
          </Formik>
        </div>
      </SwipeableModalLayout>
    </>
  );
};

export default PaymentCardFormModal;
