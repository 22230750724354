import { User } from '../../models/User';

export const isUserFavorite = (
  user: User | undefined,
  projectId: number
): boolean => {
  return Boolean(
    user?.favorites?.find((favorite) => favorite.projectId === projectId)
  );
};
