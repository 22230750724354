import { Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import { Dialog, Transition } from '@headlessui/react';
import { postProjectRating } from '../../services/networking/apiClient';
import InputTextArea from '../Forms/InputTextArea/InputTextArea';
import BasicButton from '../BasicButton/BasicButton';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import InputRating from '../Forms/InputRating/InputRating';
import CrossIcon from '../Icons/CrossIcon/CrossIcon';
import BasicToast from '../BasicToast/BasicToast';
import RatingSentIcon from '../../assets/icons/letter-sent.svg';
import ErrorToast from '../ErrorToast/ErrorToast';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  projectId: number;
  name: string;
  onUpdate?: () => void;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  review: Yup.string().optional(),
  note: Yup.number().required('required'),
});

export type AddRatingFormData = {
  note: number;
  review: string;
  projectId: number;
  name: string;
};

const NOTE_DEFAULT_VALUE = 3;

const AddRatingModal: React.FC<Props> = ({
  isOpen,
  onClose,
  projectId,
  name,
  onUpdate,
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isSubmitSuccessToastOpen, setIsSubmitSuccessToastOpen] =
    useState(false);

  return (
    <>
      <BasicToast
        isOpen={isSubmitSuccessToastOpen}
        titleTranslationId="add-rating-modal.success-toast.title"
        messageTranslationId="add-rating-modal.success-toast.message"
        icon={RatingSentIcon}
        onClose={() => setIsSubmitSuccessToastOpen(false)}
      />
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden flex-col items-center justify-center"
          onClose={onClose}
        >
          <ErrorToast
            isOpen={isSubmitError}
            onClose={() => setIsSubmitError(false)}
          />
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute top-0 right-0 mr-2 pt-2">
              <button
                type="button"
                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={onClose}
              >
                <CrossIcon color="#FFFFFF" />
              </button>
            </div>
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="relative w-3/4">
              <Formik
                initialValues={
                  {
                    note: NOTE_DEFAULT_VALUE,
                    review: '',
                    projectId: projectId,
                    name: name,
                  } as AddRatingFormData
                }
                validationSchema={VALIDATION_SCHEMA}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={async (values, actions) => {
                  setIsSubmitLoading(true);
                  const response = await postProjectRating(values);
                  actions.setSubmitting(false);
                  setIsSubmitLoading(false);

                  if (!response?.statusCode) {
                    onUpdate?.();
                    onClose();
                    setIsSubmitSuccessToastOpen(true);
                  } else {
                    setIsSubmitError(true);
                  }
                }}
              >
                {(props) => (
                  <form
                    className="w-full"
                    onSubmit={props.handleSubmit}
                    autoComplete="off"
                    action="..."
                  >
                    <div className="text-formSubtitle text-center text-white">
                      <TranslatedMarkupText id="add-rating-modal.title" />
                    </div>
                    <div className="flex justify-center">
                      <InputRating
                        value={props.values.note}
                        onChange={(val: number) => {
                          props.setFieldValue('note', val);
                        }}
                        buttonClassname="h-8 w-8"
                      />
                    </div>
                    <div className="mt-2">
                      <InputTextArea
                        errors={props.errors}
                        value={props.values.review}
                        name="review"
                        onChange={props.handleChange}
                        labelTranslationId="add-rating-modal.review"
                        placeholderTranslationId="add-rating-modal.review"
                        maxRows={6}
                        hasFloatingLabel
                      />
                    </div>
                    <div className="mt-2 flex justify-center">
                      <div className="w-full">
                        <BasicButton
                          className="w-full rounded-lg"
                          type="submit"
                          isLoading={isSubmitLoading}
                          translationId="add-rating-modal.submit"
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AddRatingModal;
