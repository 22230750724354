import { IonAvatar, IonImg } from '@ionic/react';
import React from 'react';

import { FaStar } from 'react-icons/fa';

interface Props {
  rating: number;
  avatars: string[];
}

const FA_STAR_ICON_SIZE = 15;
const MAX_AVATAR_SHOW = 4;

const ActiveProjectHeader: React.FC<Props> = ({ rating, avatars }) => {
  return (
    <>
      <div className="absolute bottom-5 left-5 xl:bottom-5 xl:left-20">
        <div className="flex flew-row">
          <div className="flex flex-row">
            <p className="flex font-bold text-white">{rating}</p>
            <FaStar className="text-white mt-1 ml-2" size={FA_STAR_ICON_SIZE} />
          </div>
        </div>
      </div>
      <div className="absolute bottom-5 right-5 xl:top-5 xl:left-8">
        <div className="flex -space-x-2">
          {avatars.map((avatar, index) => {
            if (index <= MAX_AVATAR_SHOW) {
              return (
                <div key={index} className="rounded-full">
                  <IonAvatar className="rounded-image inline-block h-8 w-8 rounded-full ring-2 ring-white">
                    <img
                      src={avatar}
                      className="rounded-image inline-block h-8 w-8 rounded-full ring-2"
                      alt=""
                    />
                  </IonAvatar>
                </div>
              );
            }
          })}
          {avatars.length > MAX_AVATAR_SHOW + 1 ? (
            <span className="flex justify-center items-center bg-primary h-8 w-8 rounded-full ring-2 ring-white">
              <b className="text-sm text-white">
                +{avatars.length - (MAX_AVATAR_SHOW + 1)}
              </b>
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ActiveProjectHeader;
