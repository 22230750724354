import { SetStateAction, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  deleteNotification,
  getCurrentUser,
  getCurrentUserNotifications,
  POST_SUCCESS_STATUS,
  postNotification,
  SUCCESS_STATUS,
} from '../../services/networking/apiClient';
import BasicToast from '../BasicToast/BasicToast';
import AddedFavoriteIcon from '../../assets/icons/home-heart.svg';
import ErrorToast from '../ErrorToast/ErrorToast';
import BasicButton from '../BasicButton/BasicButton';
import { User } from '../../models/User';
import { isUserNotified } from '../../services/helpers/isUserNotified';
import Warning from '../../assets/icons/warning.svg';

interface Props {
    isAlreadyNotified:boolean;
    user?: User;
    projectId: number;
    setIsFinishedProjectModaleOpen: React.Dispatch<SetStateAction<boolean>>;
    setIsCalculatorModaleOpen:React.Dispatch<SetStateAction<boolean>>;
    onUpdate: () => void;
}

const NotificationButton: React.FC<Props> = ({
    isAlreadyNotified,
    user,
    projectId,
    setIsFinishedProjectModaleOpen,
    setIsCalculatorModaleOpen,
    onUpdate,
}) => {
    const locale = localStorage.getItem('locale');
  const splittedLocale = locale?.split('-')[0];

  const checkIfIsAlreadyNotified = (): boolean  => {
    onUpdate();

    return isUserNotified(user, projectId);
  };

  const queryClient = useQueryClient();

  const { data:userNotifications, isLoading } = useQuery({ queryKey: ['notification'], queryFn: getCurrentUserNotifications });

  const postMutation = useMutation({
    mutationFn: postNotification,
    onSuccess: async () => {
       // Invalidate and refetch
        await queryClient.invalidateQueries({ queryKey: ['notification'] });

        setIsAddNotificationSuccessToastOpen(true);
    },
    // onMutate:() => {},
    onError:(err) => {
      console.error("error : ", err);
      setIsErrorToastOpen(true);
    }
  });

  const deleteMutation = useMutation({
    mutationFn: deleteNotification,
    onSuccess: async () => {
      // Invalidate and refetch
      await queryClient.invalidateQueries({ queryKey: ['notification'] });

      setIsDeleteNotificationSuccessToastOpen(true);
    },
    // onMutate:() => {},
    onError:(err) => {
      console.error("error : ", err);
      setIsErrorToastOpen(true);
    },
  });

  const [isAddNotificationSuccessToastOpen, setIsAddNotificationSuccessToastOpen] =
    useState(false);

  const [
    isDeleteNotificationSuccessToastOpen,
    setIsDeleteNotificationSuccessToastOpen,
  ] = useState(false);

  const [isUserNotLoggedInToastOpen, setIsUserNotLoggedInToastToastOpen] = useState(false);
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);

  const history = useHistory();

  const redirectUser = ():void => {
    console.log("redirect");

    history.push(`${splittedLocale}/register`);
  };

  const closeModaleAndRedirectIfUserLoggedOut = ():void => {
    if(!user){
      setTimeout(() => {
        redirectUser();
      }, 5000);
      
      setIsUserNotLoggedInToastToastOpen(true);

      setIsFinishedProjectModaleOpen(false);
      setIsCalculatorModaleOpen(false);
      


      return;
    }
  };
  const getUserNotifiedForShares = (): void => {
    if(!userNotifications![0] && !isAlreadyNotified){
        postMutation.mutate(projectId);
    } else {
        deleteMutation.mutate(projectId);
    }
  };

  useEffect(() => {
    checkIfIsAlreadyNotified();
    // console.log("isAlreadyNotified ? ", isAlreadyNotified);
    // console.log("--- notifs in user after onUpdate : ", user?.notifications);
    // console.log("--- notifData after onUpdate : ", userNotifications);
  }, [userNotifications, isAlreadyNotified]);
  

  return (
    <>
        <BasicToast
          isOpen={isUserNotLoggedInToastOpen}
          onClose={() => setIsUserNotLoggedInToastToastOpen(false)}
          titleTranslationId="notifications.error.title"
          messageTranslationId="notifications.error.message"
          icon={Warning}
        />
        <BasicToast
          isOpen={isAddNotificationSuccessToastOpen}
          onClose={() => setIsAddNotificationSuccessToastOpen(false)}
          titleTranslationId="notifications.add-notification-toast.title"
          messageTranslationId="notifications.add-notification-toast.message"
          icon={AddedFavoriteIcon}
        />
        <BasicToast
          isOpen={isDeleteNotificationSuccessToastOpen}
          onClose={() => setIsDeleteNotificationSuccessToastOpen(false)}
          titleTranslationId="notifications.delete-notification-toast.title"
          messageTranslationId="notifications.delete-notification-toast.message"
          icon={AddedFavoriteIcon}
        />
        <ErrorToast
          isOpen={isErrorToastOpen}
          onClose={() => void setIsErrorToastOpen(false)}
      />
    {!user && (
      <>
        <BasicButton
            className={`p-2 block w-11/12 mx-auto rounded-md text-base bg-slate-300 pointer-events-none cursor-pointer`}
            onClick={() => void closeModaleAndRedirectIfUserLoggedOut()}
            translationId={'notifications.confirm'}
        />
      </>
    )}

    {user && !isLoading && (
        <>
      <BasicButton
          className={`p-2 block w-11/12 mx-auto rounded-md text-base ${userNotifications![0] ? "bg-slate-300" : ""}`}
          onClick={() => void getUserNotifiedForShares()}
          translationId={userNotifications![0] ? 'notifications.already-notified' : 'notifications.confirm'}
      />
      </>
      )}
    </>
  );
};

export default NotificationButton;