import TranslatedMarkupText from '../../components/TranslatedMarkupText/TranslatedMarkupText';

interface Props {
  icon?: any;
  label?: React.ReactNode;
  boldLabel?: React.ReactNode;
  rightItem?: React.ReactNode;
}
const TextItem: React.FC<Props> = ({ icon, label, boldLabel, rightItem }) => {
  return (
    <div className="flex items-center justify-between pl-6 pr-6 mt-2">
      <div className="flex items-center flex-row">
        <div className="flex justify-center items-center content-center">
          <img src={icon} alt="" className="w-6 mr-4 object-scale-down" />
        </div>
        <p>{label}</p> <p className="font-semibold ml-1">{boldLabel}</p>
      </div>
      <div className="flex flex-row">{rightItem}</div>
    </div>
  );
};
export default TextItem;
