import React, { useEffect, useState  } from 'react';
import { Project } from '../../models/Project';
import { Balance } from '../../models/Balance';
import SwipeableModalLayout from '../SwipeableModalLayout/SwipeableModalLayout';
import SwipeableModalHeader from '../SwipeableModalHeader/SwipeableModalHeader';
import TranslatedMarkupText from '../TranslatedMarkupText/TranslatedMarkupText';
import BasicButton from '../BasicButton/BasicButton';
import InputShareAmount from '../Forms/InputShareAmount/InputShareAmount';
import { Listing } from '../../models/Listing';
import { useCreateListing } from '../../services/hooks/useCreatelisting';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  project: Project;
  balance: Balance;
}

const ResellSharesModal: React.FC<Props> = ({ isOpen, onClose, project, balance }) => {
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(project.sharePrice);
  const [totalAmount, setTotalAmount] = useState(0);
  const createListingMutation = useCreateListing();
  const isButtonDisabled = quantity <= 0 || quantity > balance.amount || createListingMutation.isLoading;


  useEffect(() => {
    setTotalAmount(quantity * price);
  }, [quantity, price]);

  const handleSubmit = async () => {
    if (quantity <= 0 || quantity > balance.amount) {
      alert('Invalid quantity. Please enter a valid amount.');
      
      return;
    }

    const newListing: Omit<Listing, 'id'> = {
      projectId: project.id,
      sellerId: balance.userId,
      shareAmount: quantity,
      sharePrice: price,
      onSale: 1,
    };

    try {
      await createListingMutation.mutateAsync(newListing);
      console.log('Listing created successfully:', newListing);
      onClose();
    } catch (error) {
      console.error('Failed to create listing:', error);
      alert('Failed to create listing. Please try again.');
    }
  };

  return (
    <SwipeableModalLayout
      isOpen={isOpen}
      header={
        <SwipeableModalHeader
          translationId="resell-shares-modal.title"
          onClose={onClose}
        />
      }
    >
      <div className="p-5">
        <h2 className="text-xl font-bold mb-4">{project.name}</h2>
        <div className="mb-4 flex items-center">
          <TranslatedMarkupText id="resell-shares-modal.available-shares" />
          <span className="font-bold ml-2">{balance.amount}</span>
        </div>
        <div className="w-3/5 flex flex-row mb-4">
          <InputShareAmount
            onChange={setQuantity}
            value={quantity}
            maxValue={balance.amount}
          />
        </div>
        <div className="mb-4">
          <TranslatedMarkupText id="resell-shares-modal.price" />
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
            min="0"
            step="0.01"
            placeholder="Enter price"
          />
        </div>
        <div className="mb-4 flex items-center">
          <TranslatedMarkupText id="resell-shares-modal.total-amount" />
          <span className="font-bold ml-2">{totalAmount.toFixed(2)}</span>
        </div>
        <BasicButton
          translationId="resell-shares-modal.submit"
          onClick={handleSubmit}
          isDisabled={isButtonDisabled}
          className="w-full text-white py-2 rounded transition-colors"
        />
      </div>
    </SwipeableModalLayout>
  );
};

export default ResellSharesModal;