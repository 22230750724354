import React from 'react';

interface Props {
  color?: string;
}

const CrossIcon: React.FC<Props> = ({ color = '#003962' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6574 17.6568L6.34367 6.34309"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M17.6563 6.34309L6.34262 17.6568"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
);

export default CrossIcon;
